import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import moment from "moment";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ITedarikciSahisBilgileri_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ITedarikciSahisBilgileri_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  getGuid: any[];
  getGuid_dummy: any[];
  GenelParametrelerPDF: any[];
  GenelParametrelerPDF_dummy: any[];
  UyelikFormuById: any[];
  UyelikFormuById_dummy: any[];
  SelectCityAll: any[];
  SelectCityAll_dummy: any[];
  SelectDistrictAll: any[];
  SelectDistrictAll_dummy: any[];
  UyelikFormuDuzeltmeNotuByUyeID: any[];
  UyelikFormuDuzeltmeNotuByUyeID_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  DownloadFile: any;
  DownloadFile_dummy: any;
  SaveRecord: number;
  SaveRecord_dummy: number;
  UploadFile: any;
  UploadFile_dummy: any;
  POPSozlesmesiUpdate: number;
  POPSozlesmesiUpdate_dummy: number;
  SetValueOf: any;
  SetValueOf_dummy: any;
  Notify: boolean;
  Notify_dummy: boolean;
  getDownloadGuid: any[];
  getDownloadGuid_dummy: any[];
  POPSozlesmesiById: any[];
  POPSozlesmesiById_dummy: any[];
  ValidasyonKontrol: any[];
  ValidasyonKontrol_dummy: any[];
  KullaniciDetayUserIdUyeIdUpdate: number;
  KullaniciDetayUserIdUyeIdUpdate_dummy: number;
  SendMailSettings: any[];
  SendMailSettings_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  UpdateUserStartingScreen: string;
  UpdateUserStartingScreen_dummy: string;
  PowerUserOnay: number;
  PowerUserOnay_dummy: number;
  isComp54788Visible: "visible" | "hidden";
  isComp809263Visible: "visible" | "hidden";
  isComp70986Visible: "visible" | "hidden";
  isComp366629Visible: "visible" | "hidden";
  isComp937939Visible: "visible" | "hidden";
  isComp576033Visible: "visible" | "hidden";
  isComp221266Visible: "visible" | "hidden";
  isComp365538Visible: "visible" | "hidden";
  isComp228118Enabled: "enabled" | "disabled";
}

export class TedarikciSahisBilgileri_ScreenBase extends React.PureComponent<ITedarikciSahisBilgileri_ScreenProps, any> {
  tedarikcisahisbilgileri_546287_value_kuikaSelectBoxRef: React.RefObject<any>;
  tedarikcisahisbilgileri_100145_value_kuikaSelectBoxRef: React.RefObject<any>;
  tedarikcisahisbilgileri_260290_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "2d4bf187-fea1-4609-a5df-61fbd765c37f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 412428, PropertyName: "label", Value: "Profil" },
        { Id: 668195, PropertyName: "label", Value: "Çıkış Yap" },
        { Id: 619404, PropertyName: "value", Value: "Tedarikçi Şahıs Bilgileri" },
        { Id: 532883, PropertyName: "value", Value: "TC Kimlik Numarası" },
        { Id: 228118, PropertyName: "placeholder", Value: "İmzalı POP Tedarikçi Şahıs Sözleşmesi" },
        { Id: 929728, PropertyName: "value", Value: "İl" },
        { Id: 458210, PropertyName: "value", Value: "İlçe" },
        { Id: 158154, PropertyName: "value", Value: "Adres" },
        { Id: 796351, PropertyName: "value", Value: "Vergi Dairesi" },
        { Id: 944498, PropertyName: "value", Value: "Vergi Kimlik Numarası" },
        { Id: 678021, PropertyName: "value", Value: "Düzeltme Notları" },
        { Id: 526466, PropertyName: "placeholder", Value: "Düzeltme isteği açıklaması giriniz." },
        { Id: 310036, PropertyName: "label", Value: "Açıklama Ekle" },
        { Id: 260290, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 858323, PropertyName: "title", Value: "Düzeltme Notları Tarihçe" },
        { Id: 474367, PropertyName: "value", Value: "[datafield:duzeltmenotu]" },
        { Id: 253431, PropertyName: "value", Value: "[datafield:userinfo]" },
        { Id: 487399, PropertyName: "label", Value: "Taslak Olarak Kaydet" },
        { Id: 887191, PropertyName: "label", Value: "Kaydet & Onaya Gönder" },
        { Id: 719087, PropertyName: "label", Value: "Düzeltme İçin Geri Gönder" },
        { Id: 976323, PropertyName: "label", Value: "Red Et" },
        { Id: 989950, PropertyName: "label", Value: "Onayla" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.tedarikcisahisbilgileri_546287_value_kuikaSelectBoxRef = React.createRef();
    this.tedarikcisahisbilgileri_100145_value_kuikaSelectBoxRef = React.createRef();
    this.tedarikcisahisbilgileri_260290_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      NAVIGATE: "",
      Logout: false,
      goToSignInActivity: "",
      MyContext: [],
      getGuid: [],
      GenelParametrelerPDF: [],
      UyelikFormuById: [],
      SelectCityAll: [],
      SelectDistrictAll: [],
      UyelikFormuDuzeltmeNotuByUyeID: [],
      ChangeEnabledOf: "",
      GoBack: "",
      DownloadFile: "",
      SaveRecord: false,
      UploadFile: "",
      POPSozlesmesiUpdate: 0,
      SetValueOf: "",
      Notify: false,
      getDownloadGuid: [],
      POPSozlesmesiById: [],
      ValidasyonKontrol: [],
      KullaniciDetayUserIdUyeIdUpdate: 0,
      SendMailSettings: [],
      SendMail: false,
      UpdateUserStartingScreen: "",
      PowerUserOnay: 0,
      isComp54788Visible: "hidden",
      isComp809263Visible: "hidden",
      isComp70986Visible: "hidden",
      isComp366629Visible: "hidden",
      isComp937939Visible: "hidden",
      isComp576033Visible: "hidden",
      isComp221266Visible: "hidden",
      isComp365538Visible: "hidden",
      isComp228118Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("tedarikcisahisbilgileri", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.TedarikciSahisBilgileriPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("tedarikcisahisbilgileri", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("tedarikcisahisbilgileri", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.TedarikciSahisBilgileriPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      tedarikcisahisbilgileri_328954_value: this.state.UyelikFormuById?.at?.(0)?.tcKimlikNo ?? undefined,
      tedarikcisahisbilgileri_228118_value: this.state.UyelikFormuById?.at?.(0)?.popSozlesmesiDosyaAd ?? undefined,
      tedarikcisahisbilgileri_546287_value: this.state.UyelikFormuById?.at?.(0)?.cityID ?? undefined,
      tedarikcisahisbilgileri_100145_value: this.state.UyelikFormuById?.at?.(0)?.districtID ?? undefined,
      tedarikcisahisbilgileri_909820_value: this.state.UyelikFormuById?.at?.(0)?.adres ?? undefined,
      tedarikcisahisbilgileri_261797_value: this.state.UyelikFormuById?.at?.(0)?.vergiDairesi ?? undefined,
      tedarikcisahisbilgileri_902011_value: this.state.UyelikFormuById?.at?.(0)?.vergiKimlikNo ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  TedarikciSahisBilgileriPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      prmID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciSahisBilgileri/TedarikciSahisBilgileriPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    formVars.tedarikcisahisbilgileri_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );
    stateVars.isComp54788Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].powerUserOnay
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].powerUserOnay
            : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp70986Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp366629Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp937939Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.getGuid = result?.data.getGuid;
    stateVars.GenelParametrelerPDF = result?.data.genelParametrelerPDF;
    stateVars.UyelikFormuById = result?.data.uyelikFormuById;
    formVars.tedarikcisahisbilgileri_328954_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].tcKimlikNo
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].tcKimlikNo
        : null
    );
    formVars.tedarikcisahisbilgileri_228118_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].popSozlesmesiDosyaAd
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].popSozlesmesiDosyaAd
        : null
    );
    formVars.tedarikcisahisbilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].cityID
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].cityID
        : null;
    formVars.tedarikcisahisbilgileri_546287_options = stateVars.SelectCityAll;
    formVars.tedarikcisahisbilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].districtID
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].districtID
        : null;
    formVars.tedarikcisahisbilgileri_100145_options = stateVars.SelectDistrictAll;
    formVars.tedarikcisahisbilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].adres
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].adres
        : null
    );
    formVars.tedarikcisahisbilgileri_261797_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].vergiDairesi
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].vergiDairesi
        : null
    );
    formVars.tedarikcisahisbilgileri_902011_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].vergiKimlikNo
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].vergiKimlikNo
        : null
    );
    stateVars.isComp576033Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].uyelikDurumKey
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].uyelikDurumKey
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.SelectCityAll = result?.data.selectCityAll;

    formVars.tedarikcisahisbilgileri_546287_value =
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].cityID
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].cityID
        : null;
    formVars.tedarikcisahisbilgileri_546287_options = stateVars.SelectCityAll;
    stateVars.SelectDistrictAll = result?.data.selectDistrictAll;

    formVars.tedarikcisahisbilgileri_100145_value =
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].districtID
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].districtID
        : null;
    formVars.tedarikcisahisbilgileri_100145_options = stateVars.SelectDistrictAll;
    stateVars.UyelikFormuDuzeltmeNotuByUyeID = result?.data.uyelikFormuDuzeltmeNotuByUyeID;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciSahisBilgileriPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciSahisBilgileriPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp228118Enabled", "disabled");
    stateVars.isComp54788Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].powerUserOnay
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].powerUserOnay
            : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.tedarikcisahisbilgileri_172357_value = ReactSystemFunctions.toString(
      this,
      stateVars.MyContext?.length > 0
        ? stateVars.MyContext[0].fullName
        : this.state.MyContext?.length > 0
        ? this.state.MyContext[0].fullName
        : null
    );

    stateVars.isComp809263Visible =
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";
    formVars.tedarikcisahisbilgileri_328954_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].tcKimlikNo
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].tcKimlikNo
        : null
    );

    formVars.tedarikcisahisbilgileri_228118_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].popSozlesmesiDosyaAd
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].popSozlesmesiDosyaAd
        : null
    );

    stateVars.isComp70986Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp366629Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp937939Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].rolName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].rolName
            : null
        ),
        "POWERUSER"
      ) === true
        ? "visible"
        : "hidden";
    formVars.tedarikcisahisbilgileri_546287_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].cityID
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].cityID
        : null
    );

    stateVars.dataSource_546287 = this.state.SelectCityAll;
    stateVars.dataSource_546287 = this.state.SelectCityAll;
    formVars.tedarikcisahisbilgileri_100145_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].districtID
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].districtID
        : null
    );

    stateVars.dataSource_100145 = this.state.SelectDistrictAll;
    stateVars.dataSource_100145 = this.state.SelectDistrictAll;
    formVars.tedarikcisahisbilgileri_909820_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].adres
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].adres
        : null
    );

    formVars.tedarikcisahisbilgileri_261797_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].vergiDairesi
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].vergiDairesi
        : null
    );

    formVars.tedarikcisahisbilgileri_902011_value = ReactSystemFunctions.toString(
      this,
      stateVars.UyelikFormuById?.length > 0
        ? stateVars.UyelikFormuById[0].vergiKimlikNo
        : this.state.UyelikFormuById?.length > 0
        ? this.state.UyelikFormuById[0].vergiKimlikNo
        : null
    );

    stateVars.isComp576033Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].uyelikDurumKey
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].uyelikDurumKey
            : null
        ),
        null
      ) === true
        ? "visible"
        : "hidden";

    stateVars.dataSource_260290 = this.state.UyelikFormuDuzeltmeNotuByUyeID;
    stateVars.isComp221266Visible =
      ReactSystemFunctions.isEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";
    stateVars.isComp365538Visible =
      ReactSystemFunctions.isNotEmpty(this.props.screenInputs.prmID ?? this.props.screenInputs.prmid, null) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  TedarikciSahisBilgileriComponent_54788_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TedarikciSahisBilgileri",
      "LeftMenu",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "46230",
      null,
      "left",
      null,
      "380px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciSahisBilgileriComponent_412428_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TedarikciSahisBilgileri",
      "Profil",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciSahisBilgileriComponent_668195_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciSahisBilgileriComponent_809263_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciSahisBilgileriComponent_70986_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.toString(
        this,
        stateVars.GenelParametrelerPDF?.length > 0
          ? stateVars.GenelParametrelerPDF[0].ornekPOPSertifikaURLTedarikciSahsi
          : this.state.GenelParametrelerPDF?.length > 0
          ? this.state.GenelParametrelerPDF[0].ornekPOPSertifikaURLTedarikciSahsi
          : null
      ),
      "TedarikçiSozlesmesi.pdf"
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciSahisBilgileriComponent_366629_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_328954_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_328954_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikcisahisbilgileri_100145_value",
            "value",
            "SelectDistrictAll",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_261797_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_261797_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_902011_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_902011_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      ),
      TCKimlikNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_328954_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      ),
      DistrictID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikcisahisbilgileri_100145_value",
            "value",
            "SelectDistrictAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Adres_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_261797_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_902011_value", "value", "", "", "")
        ),
        "string"
      ),
      PowerUserOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      UyelikDurumKey_0: ReactSystemFunctions.convertToTypeByName(null, "number")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciSahisBilgileri/TedarikciSahisBilgileriComponent_366629_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciSahisBilgileriComponent_366629_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_366629_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciSahisBilgileriComponent_366629_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_366629_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      POPSozlesmesi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      ),
      POPSozlesmesiDosyaAd_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
        "string"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciSahisBilgileri/TedarikciSahisBilgileriComponent_366629_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.POPSozlesmesiUpdate = result?.data.popSozlesmesiUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciSahisBilgileriComponent_366629_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_366629_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "tedarikcisahisbilgileri_228118_value",
      ReactSystemFunctions.toString(this, this.state.UploadFile?.name),
      null
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "dfa7a3e3_c436_bc05_1e52_e085e4bd3e7b_notify",
        this.defaultML,
        "Belge Yüklendi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciSahisBilgileriComponent_89089_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciSahisBilgileri/TedarikciSahisBilgileriComponent_89089_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciSahisBilgileriComponent_89089_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_89089_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "PDFViewerFormsPOPSozlesme",
      "prmGuid",
      ReactSystemFunctions.value(
        this,
        stateVars.getDownloadGuid?.length > 0
          ? stateVars.getDownloadGuid[0].getDownloadGuid
          : this.state.getDownloadGuid?.length > 0
          ? this.state.getDownloadGuid[0].getDownloadGuid
          : null
      )
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TedarikciSahisBilgileri",
      "PDFViewerFormsPOPSozlesme",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "496406",
      null,
      "left",
      null,
      "45%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciSahisBilgileriComponent_937939_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prmID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      ),
      getGuid_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciSahisBilgileri/TedarikciSahisBilgileriComponent_937939_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.getDownloadGuid = result?.data.getDownloadGuid;
    stateVars.POPSozlesmesiById = result?.data.popSozlesmesiById;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciSahisBilgileriComponent_937939_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_937939_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.DownloadFile = await ReactSystemFunctions.downloadFile(
      ReactSystemFunctions.toString(
        this,
        stateVars.POPSozlesmesiById?.length > 0
          ? stateVars.POPSozlesmesiById[0].popSozlesmesi
          : this.state.POPSozlesmesiById?.length > 0
          ? this.state.POPSozlesmesiById[0].popSozlesmesi
          : null
      ),
      ReactSystemFunctions.toString(
        this,
        stateVars.POPSozlesmesiById?.length > 0
          ? stateVars.POPSozlesmesiById[0].popSozlesmesiDosyaAd
          : this.state.POPSozlesmesiById?.length > 0
          ? this.state.POPSozlesmesiById[0].popSozlesmesiDosyaAd
          : null
      )
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciSahisBilgileriComponent_546287_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CityID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciSahisBilgileri/TedarikciSahisBilgileriComponent_546287_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SelectDistrictAll = result?.data.selectDistrictAll;

    formVars.tedarikcisahisbilgileri_100145_options = stateVars.SelectDistrictAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciSahisBilgileriComponent_546287_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_546287_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_100145 = this.state.SelectDistrictAll;
    stateVars.dataSource_100145 = this.state.SelectDistrictAll;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciSahisBilgileriComponent_310036_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_526466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_526466_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      NotKisiID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      NotTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      ),
      DuzeltmeNotu_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_526466_value", "value", "", "", "")
        ),
        "string"
      ),
      UyeID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciSahisBilgileri/TedarikciSahisBilgileriComponent_310036_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_2;
    stateVars.UyelikFormuDuzeltmeNotuByUyeID = result?.data.uyelikFormuDuzeltmeNotuByUyeID;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciSahisBilgileriComponent_310036_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_310036_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "tedarikcisahisbilgileri_526466_value",
      "",
      null
    );

    stateVars.dataSource_260290 = this.state.UyelikFormuDuzeltmeNotuByUyeID;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciSahisBilgileriComponent_487399_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_228118_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_228118_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_328954_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_328954_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikcisahisbilgileri_100145_value",
            "value",
            "SelectDistrictAll",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_261797_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_261797_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_902011_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_902011_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Prm1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_228118_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm2_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Prm3_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Prm4_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Prm5_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      ),
      TCKimlikNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_328954_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      ),
      DistrictID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikcisahisbilgileri_100145_value",
            "value",
            "SelectDistrictAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Adres_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_261797_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_902011_value", "value", "", "", "")
        ),
        "string"
      ),
      PowerUserOnay_1: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      KUserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      UyelikDurumKey_1: ReactSystemFunctions.convertToTypeByName(null, "number"),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      ),
      KUserId_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciSahisBilgileri/TedarikciSahisBilgileriComponent_487399_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ValidasyonKontrol = result?.data.validasyonKontrol;
    stateVars.SaveRecord = result?.data.saveRecord_3;
    stateVars.KullaniciDetayUserIdUyeIdUpdate = result?.data.kullaniciDetayUserIdUyeIdUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciSahisBilgileriComponent_487399_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_487399_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "24d2b16f_2023_3a8b_c1db_281bc164e01c_notify",
        this.defaultML,
        "Form Taslak Olarak Kayıt Edildi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciSahisBilgileriComponent_887191_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_228118_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_228118_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_328954_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_328954_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_546287_value", "value", "SelectCityAll", "id", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_546287_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikcisahisbilgileri_100145_value",
            "value",
            "SelectDistrictAll",
            "id",
            ""
          )
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_100145_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_909820_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_909820_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_261797_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_261797_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_902011_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "tedarikcisahisbilgileri_902011_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Prm1_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_228118_value", "value", "", "", "")
        ),
        "string"
      ),
      Prm2_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Prm3_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Prm4_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Prm5_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      ),
      TCKimlikNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_328954_value", "value", "", "", "")
        ),
        "string"
      ),
      CityID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_546287_value", "value", "SelectCityAll", "id", "id")
        ),
        "Guid"
      ),
      DistrictID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "tedarikcisahisbilgileri_100145_value",
            "value",
            "SelectDistrictAll",
            "id",
            "id"
          )
        ),
        "Guid"
      ),
      Adres_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_909820_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiDairesi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_261797_value", "value", "", "", "")
        ),
        "string"
      ),
      VergiKimlikNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "tedarikcisahisbilgileri_902011_value", "value", "", "", "")
        ),
        "string"
      ),
      PowerUserOnay_1: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      KUserId_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      UyelikDurumKey_1: ReactSystemFunctions.convertToTypeByName(-1, "number")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciSahisBilgileri/TedarikciSahisBilgileriComponent_887191_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.ValidasyonKontrol = result?.data.validasyonKontrol;
    stateVars.SaveRecord = result?.data.saveRecord_4;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciSahisBilgileriComponent_887191_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_887191_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "0a328453_331f_5a63_7bca_da99c290ab46_notify",
        this.defaultML,
        "Form Kaydı Alındı!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciSahisBilgileriComponent_887191_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_887191_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("poweruseronayinagonder", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].uyelikTipi
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_1: ReactSystemFunctions.convertToTypeByName("9859035e-d0f0-48b5-997e-892a19c42cc1", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametrelerPDF?.length > 0
            ? stateVars.GenelParametrelerPDF[0].powerUserMail
            : this.state.GenelParametrelerPDF?.length > 0
            ? this.state.GenelParametrelerPDF[0].powerUserMail
            : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametrelerPDF?.length > 0
            ? stateVars.GenelParametrelerPDF[0].destekTelNo
            : this.state.GenelParametrelerPDF?.length > 0
            ? this.state.GenelParametrelerPDF[0].destekTelNo
            : null
        ),
        "string"
      ),
      uyekategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyelikTipi
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyelikTipi
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].firstName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].firstName
            : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].lastName
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].lastName
            : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciSahisBilgileri/TedarikciSahisBilgileriComponent_887191_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciSahisBilgileriComponent_887191_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_887191_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.UpdateUserStartingScreen = await ReactSystemFunctions.updateUserStartingScreen(
      ReactSystemFunctions.getMyUsername(),
      "317b40ed-f5d4-4cdd-9771-1b80f2ebabc6"
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciSahisBilgileriComponent_887191_onClick4_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_887191_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      UyeId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.getGuid?.length > 0
            ? stateVars.getGuid[0].getGuid
            : this.state.getGuid?.length > 0
            ? this.state.getGuid[0].getGuid
            : null
        ),
        "Guid"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciSahisBilgileri/TedarikciSahisBilgileriComponent_887191_onClick4_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciDetayUserIdUyeIdUpdate = result?.data.kullaniciDetayUserIdUyeIdUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciSahisBilgileriComponent_887191_onClick5_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_887191_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "TedarikciSahisBilgileri",
      "TedarikciBilgileriKontrol",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciSahisBilgileriComponent_719087_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikOnay_0: ReactSystemFunctions.convertToTypeByName(null, "boolean"),
      UyelikOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UyelikOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      UyelikFormuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "46f137c2_43d1_d7c6_6c5a_52beceacaeb6_confirmation",
        this.defaultML,
        "Üyelik Düzeltme İçin Geri Gönderilecek, Açıklma Girildi ise İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TedarikciSahisBilgileri/TedarikciSahisBilgileriComponent_719087_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.PowerUserOnay = result?.data.powerUserOnay;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TedarikciSahisBilgileriComponent_719087_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_719087_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.UpdateUserStartingScreen = await ReactSystemFunctions.updateUserStartingScreen(
      ReactSystemFunctions.toString(
        this,
        stateVars.UyelikFormuById?.length > 0
          ? stateVars.UyelikFormuById[0].userName
          : this.state.UyelikFormuById?.length > 0
          ? this.state.UyelikFormuById[0].userName
          : null
      ),
      "1071ed67-b27f-4e71-903f-b93884588091"
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "c3aaf14d_e203_1065_19f1_4623715ac126_notify",
        this.defaultML,
        "Üyelik Bilgileri Düzeltme İçin Geri Gönderildi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciSahisBilgileriComponent_719087_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_719087_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("powerusergerigonder", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].uyelikTipi
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_1: ReactSystemFunctions.convertToTypeByName("2f71eae3-f529-4bc4-b700-59e0a52004b6", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].userName
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].userName
            : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametrelerPDF?.length > 0
            ? stateVars.GenelParametrelerPDF[0].destekTelNo
            : this.state.GenelParametrelerPDF?.length > 0
            ? this.state.GenelParametrelerPDF[0].destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].firstName
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].firstName
            : null
        ),
        "string"
      ),
      UyeKategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].uyelikTipi
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].uyelikTipi
            : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].lastName
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].lastName
            : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciSahisBilgileri/TedarikciSahisBilgileriComponent_719087_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciSahisBilgileriComponent_719087_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_719087_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciSahisBilgileriComponent_976323_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikOnay_0: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      UyelikOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UyelikOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      UyelikFormuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "c7f2f90e_dfbd_e685_3d2d_f9de364355d0_confirmation",
        this.defaultML,
        "Üyelik Red Edilecektir; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TedarikciSahisBilgileri/TedarikciSahisBilgileriComponent_976323_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.PowerUserOnay = result?.data.powerUserOnay;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TedarikciSahisBilgileriComponent_976323_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_976323_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "ca70e4e8_232e_b242_f5af_8e5fe55aec7e_notify",
        this.defaultML,
        "Form Red Edildi!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciSahisBilgileriComponent_976323_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_976323_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("poweruserred", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].uyelikTipi
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_1: ReactSystemFunctions.convertToTypeByName("4239c471-f052-4cd0-ae85-0e4ad91e9471", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].userName
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].userName
            : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametrelerPDF?.length > 0
            ? stateVars.GenelParametrelerPDF[0].destekTelNo
            : this.state.GenelParametrelerPDF?.length > 0
            ? this.state.GenelParametrelerPDF[0].destekTelNo
            : null
        ),
        "string"
      ),
      uyekategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].uyelikTipi
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].uyelikTipi
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].firstName
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].firstName
            : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].lastName
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].lastName
            : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciSahisBilgileri/TedarikciSahisBilgileriComponent_976323_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciSahisBilgileriComponent_976323_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_976323_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  TedarikciSahisBilgileriComponent_989950_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyelikOnay_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      UyelikOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UyelikOnayKisi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].kUserId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].kUserId
            : null
        ),
        "Guid"
      ),
      UyelikFormuId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmID ?? this.props.screenInputs.prmid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    stateVars.Confirm = await Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "f2180580_d00a_69a0_fa61_88fbdb2f2b58_confirmation",
        this.defaultML,
        "Üyelik Onaylanacaktır; İşleme Devam Edilsin mi?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel",
      customClass: {
        title: "swal2-font-poppins"
      }
    });

    if (stateVars.Confirm.isConfirmed) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "TedarikciSahisBilgileri/TedarikciSahisBilgileriComponent_989950_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.PowerUserOnay = result?.data.powerUserOnay;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.TedarikciSahisBilgileriComponent_989950_onClick1_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_989950_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.UpdateUserStartingScreen = await ReactSystemFunctions.updateUserStartingScreen(
      ReactSystemFunctions.toString(
        this,
        stateVars.UyelikFormuById?.length > 0
          ? stateVars.UyelikFormuById[0].userName
          : this.state.UyelikFormuById?.length > 0
          ? this.state.UyelikFormuById[0].userName
          : null
      ),
      "d7f40e29-7fa4-40c4-ae18-230255e8fb36"
    );
    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "0909b75c_1ac3_16c8_9f65_5df5fc47a7c7_notify",
        this.defaultML,
        "Form Onaylandı!"
      ),
      "success",
      "bottom-right",
      2,
      null,
      null,
      null
    );

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciSahisBilgileriComponent_989950_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_989950_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      actionkey_0: ReactSystemFunctions.convertToTypeByName("poweruseronay", "string"),
      uyeliktipi_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].uyelikTipi
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].uyelikTipi
            : null
        ),
        "string"
      ),
      numara_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_1: ReactSystemFunctions.convertToTypeByName("ee24e6c3-69ce-47a9-9dad-dff5e55542d0", "string"),
      receivers_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].userName
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].userName
            : null
        ),
        "string"
      ),
      sender_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametrelerPDF?.length > 0
            ? stateVars.GenelParametrelerPDF[0].destekTelNo
            : this.state.GenelParametrelerPDF?.length > 0
            ? this.state.GenelParametrelerPDF[0].destekTelNo
            : null
        ),
        "string"
      ),
      UyeIsmi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].firstName
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].firstName
            : null
        ),
        "string"
      ),
      UyeKategorisi_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].uyelikTipi
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].uyelikTipi
            : null
        ),
        "string"
      ),
      UyeSoyad_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.UyelikFormuById?.length > 0
            ? stateVars.UyelikFormuById[0].lastName
            : this.state.UyelikFormuById?.length > 0
            ? this.state.UyelikFormuById[0].lastName
            : null
        ),
        "string"
      ),
      language_1: ReactSystemFunctions.convertToTypeByName("", "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "TedarikciSahisBilgileri/TedarikciSahisBilgileriComponent_989950_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.TedarikciSahisBilgileriComponent_989950_onClick3_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  TedarikciSahisBilgileriComponent_989950_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [46230, , 496406] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.TedarikciSahisBilgileriPageInit();
    }
  }
}
