import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import {
  Footer,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KDrawer,
  KRow,
  Label,
  Switch
} from "../../components/web-components";
import { KSelectBox } from "../../kuika";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { MenuYetki_ScreenBase } from "./menuyetki-base";

import { AltRol_List_Screen } from "../altrol_list/altrol_list";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class MenuYetki_Screen extends MenuYetki_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              menuyetki_722952_value: undefined,
              menuyetki_347047_value: "931d55a2-3a98-440e-cd65-08dcb39bb748",
              menuyetki_591911_value: "ad9d2f4b-6d6d-4961-cd66-08dcb39bb748",
              menuyetki_794846_value: this.state.RoleMenuGoster?.at?.(0)?.m001 ?? undefined,
              menuyetki_783128_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg001 ?? undefined,
              menuyetki_630551_value: "6d250c59-d0a3-425a-cd67-08dcb39bb748",
              menuyetki_991681_value: this.state.RoleMenuGoster?.at?.(0)?.m011 ?? undefined,
              menuyetki_599288_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg011 ?? undefined,
              menuyetki_872359_value: "6734fed0-9486-45ec-cd68-08dcb39bb748",
              menuyetki_867369_value: this.state.RoleMenuGoster?.at?.(0)?.m012 ?? undefined,
              menuyetki_374583_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg012 ?? undefined,
              menuyetki_309374_value: "328c753c-aea2-44f6-cd6a-08dcb39bb748",
              menuyetki_930852_value: this.state.RoleMenuGoster?.at?.(0)?.m002 ?? undefined,
              menuyetki_344494_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg002 ?? undefined,
              menuyetki_664614_value: "fdcf53fb-fc31-4288-cd6b-08dcb39bb748",
              menuyetki_364118_value: this.state.RoleMenuGoster?.at?.(0)?.m021 ?? undefined,
              menuyetki_929129_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg021 ?? undefined,
              menuyetki_963636_value: "7de3bbee-fe9a-46db-cd69-08dcb39bb748",
              menuyetki_837451_value: this.state.RoleMenuGoster?.at?.(0)?.m022 ?? undefined,
              menuyetki_974301_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg022 ?? undefined,
              menuyetki_695738_value: "253d7c43-27a6-4193-cd6d-08dcb39bb748",
              menuyetki_653815_value: this.state.RoleMenuGoster?.at?.(0)?.m003 ?? undefined,
              menuyetki_544223_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg003 ?? undefined,
              menuyetki_692314_value: "e08410c6-39d4-4e26-cd6c-08dcb39bb748",
              menuyetki_997_value: this.state.RoleMenuGoster?.at?.(0)?.m004 ?? undefined,
              menuyetki_976959_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg004 ?? undefined,
              menuyetki_517146_value: "3cb498b1-daf8-44d2-cd6e-08dcb39bb748",
              menuyetki_894376_value: this.state.RoleMenuGoster?.at?.(0)?.m041 ?? undefined,
              menuyetki_165606_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg041 ?? undefined,
              menuyetki_68585_value: "6eab2eca-05d0-4768-cd70-08dcb39bb748",
              menuyetki_604653_value: this.state.RoleMenuGoster?.at?.(0)?.m042 ?? undefined,
              menuyetki_112048_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg042 ?? undefined,
              menuyetki_339952_value: "7b9456ad-8878-4513-cd6f-08dcb39bb748",
              menuyetki_588733_value: this.state.RoleMenuGoster?.at?.(0)?.m043 ?? undefined,
              menuyetki_610225_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg043 ?? undefined,
              menuyetki_915455_value: "f9ce5315-8be5-4060-0a03-08dcab469ae7",
              menuyetki_819978_value: this.state.RoleMenuGoster?.at?.(0)?.m044 ?? undefined,
              menuyetki_430529_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg044 ?? undefined,
              menuyetki_256123_value: "6f307214-bce1-47cf-0a04-08dcab469ae7",
              menuyetki_787081_value: this.state.RoleMenuGoster?.at?.(0)?.m045 ?? undefined,
              menuyetki_363900_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg045 ?? undefined,
              menuyetki_737709_value: "6eab2eca-05d0-4768-cd70-08dcb39bb748",
              menuyetki_555420_value: this.state.RoleMenuGoster?.at?.(0)?.m046 ?? undefined,
              menuyetki_591274_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg046 ?? undefined,
              menuyetki_964111_value: "6eab2eca-05d0-4768-cd70-08dcb39bb748",
              menuyetki_212542_value: this.state.RoleMenuGoster?.at?.(0)?.m047 ?? undefined,
              menuyetki_716806_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg047 ?? undefined,
              menuyetki_48700_value: "45bc9696-082e-4cea-cd75-08dcb39bb748",
              menuyetki_640419_value: this.state.RoleMenuGoster?.at?.(0)?.m005 ?? undefined,
              menuyetki_644120_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg005 ?? undefined,
              menuyetki_439768_value: "a50b5fa7-eefa-4cc4-cd76-08dcb39bb748",
              menuyetki_629264_value: this.state.RoleMenuGoster?.at?.(0)?.m051 ?? undefined,
              menuyetki_837170_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg051 ?? undefined,
              menuyetki_675186_value: "a50b5fa7-eefa-4cc4-cd76-08dcb39bb748",
              menuyetki_230506_value: this.state.RoleMenuGoster?.at?.(0)?.m052 ?? undefined,
              menuyetki_37445_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg052 ?? undefined,
              menuyetki_573854_value: "9b79e484-878c-4e33-cd77-08dcb39bb748",
              menuyetki_434547_value: this.state.RoleMenuGoster?.at?.(0)?.m006 ?? undefined,
              menuyetki_958848_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg006 ?? undefined,
              menuyetki_228648_value: "cec94cea-4104-49b7-cd78-08dcb39bb748",
              menuyetki_149742_value: this.state.RoleMenuGoster?.at?.(0)?.m061 ?? undefined,
              menuyetki_678905_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg061 ?? undefined,
              menuyetki_981392_value: "cec94cea-4104-49b7-cd78-08dcb39bb748",
              menuyetki_404080_value: this.state.RoleMenuGoster?.at?.(0)?.m062 ?? undefined,
              menuyetki_357083_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg062 ?? undefined,
              menuyetki_743064_value: "16767cb1-0120-47f2-0a02-08dcab469ae7",
              menuyetki_203639_value: this.state.RoleMenuGoster?.at?.(0)?.m007 ?? undefined,
              menuyetki_514287_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg007 ?? undefined,
              menuyetki_301700_value: "7f32a751-1986-49a0-cd7a-08dcb39bb748",
              menuyetki_198856_value: this.state.RoleMenuGoster?.at?.(0)?.m071 ?? undefined,
              menuyetki_289551_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg071 ?? undefined,
              menuyetki_966850_value: "7f32a751-1986-49a0-cd7a-08dcb39bb748",
              menuyetki_225717_value: this.state.RoleMenuGoster?.at?.(0)?.m072 ?? undefined,
              menuyetki_452224_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg072 ?? undefined,
              menuyetki_78630_value: "dc6ef937-ca07-4413-cd7b-08dcb39bb748",
              menuyetki_501768_value: this.state.RoleMenuGoster?.at?.(0)?.m008 ?? undefined,
              menuyetki_569419_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg008 ?? undefined,
              menuyetki_574419_value: "6e620651-78a1-4e65-cd7c-08dcb39bb748",
              menuyetki_774525_value: this.state.RoleMenuGoster?.at?.(0)?.m081 ?? undefined,
              menuyetki_997515_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg081 ?? undefined,
              menuyetki_938245_value: "6e620651-78a1-4e65-cd7c-08dcb39bb748",
              menuyetki_283222_value: this.state.RoleMenuGoster?.at?.(0)?.m082 ?? undefined,
              menuyetki_725313_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg082 ?? undefined,
              menuyetki_443063_value: "145bb1c4-caae-44ef-cd7d-08dcb39bb748",
              menuyetki_652816_value: this.state.RoleMenuGoster?.at?.(0)?.m009 ?? undefined,
              menuyetki_789397_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg009 ?? undefined,
              menuyetki_600416_value: "f9ce5315-8be5-4060-0a03-08dcab469ae7",
              menuyetki_837432_value: this.state.RoleMenuGoster?.at?.(0)?.m091 ?? undefined,
              menuyetki_440754_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg091 ?? undefined,
              menuyetki_344175_value: "1b8d940a-199c-43c7-cd80-08dcb39bb748",
              menuyetki_319620_value: this.state.RoleMenuGoster?.at?.(0)?.m092 ?? undefined,
              menuyetki_438871_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg092 ?? undefined,
              menuyetki_955263_value: "a07d5a9a-bec8-4bd0-cd82-08dcb39bb748",
              menuyetki_164532_value: this.state.RoleMenuGoster?.at?.(0)?.m093 ?? undefined,
              menuyetki_440263_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg093 ?? undefined,
              menuyetki_160145_value: "7c67393e-56c2-4e5d-cd81-08dcb39bb748",
              menuyetki_734041_value: this.state.RoleMenuGoster?.at?.(0)?.m094 ?? undefined,
              menuyetki_54846_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg094 ?? undefined,
              menuyetki_595077_value: "b8e60879-d451-49fb-cd7e-08dcb39bb748",
              menuyetki_800211_value: this.state.RoleMenuGoster?.at?.(0)?.m095 ?? undefined,
              menuyetki_552902_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg095 ?? undefined,
              menuyetki_370535_value: "58966e2a-3476-43c4-cd83-08dcb39bb748",
              menuyetki_565874_value: this.state.RoleMenuGoster?.at?.(0)?.m096 ?? undefined,
              menuyetki_173542_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg096 ?? undefined,
              menuyetki_184934_value: "58966e2a-3476-43c4-cd83-08dcb39bb748",
              menuyetki_543343_value: this.state.RoleMenuGoster?.at?.(0)?.m097 ?? undefined,
              menuyetki_887614_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg097 ?? undefined,
              menuyetki_651109_value: "38e37352-0af1-49d1-cd7f-08dcb39bb748",
              menuyetki_7317_value: this.state.RoleMenuGoster?.at?.(0)?.m098 ?? undefined,
              menuyetki_485717_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg098 ?? undefined,
              menuyetki_996826_value: "64569107-c24d-487e-cd87-08dcb39bb748",
              menuyetki_565193_value: this.state.RoleMenuGoster?.at?.(0)?.m099 ?? undefined,
              menuyetki_493787_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg099 ?? undefined,
              menuyetki_122341_value: "8d08a4cb-a9ee-423f-cd8d-08dcb39bb748",
              menuyetki_585429_value: this.state.RoleMenuGoster?.at?.(0)?.m991 ?? undefined,
              menuyetki_799536_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg991 ?? undefined,
              menuyetki_830613_value: "137d3d6b-8b98-4d02-cd8c-08dcb39bb748",
              menuyetki_537211_value: this.state.RoleMenuGoster?.at?.(0)?.m992 ?? undefined,
              menuyetki_690110_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg992 ?? undefined,
              menuyetki_818172_value: "a8f07ec7-a2ff-41f6-cd89-08dcb39bb748",
              menuyetki_831783_value: this.state.RoleMenuGoster?.at?.(0)?.m993 ?? undefined,
              menuyetki_227738_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg993 ?? undefined,
              menuyetki_501563_value: "06f664e1-9620-4605-cd90-08dcb39bb748",
              menuyetki_172770_value: this.state.RoleMenuGoster?.at?.(0)?.m994 ?? undefined,
              menuyetki_215953_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg994 ?? undefined,
              menuyetki_608902_value: "a5501afb-e76b-46fc-cd91-08dcb39bb748",
              menuyetki_996604_value: this.state.RoleMenuGoster?.at?.(0)?.m995 ?? undefined,
              menuyetki_849075_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg995 ?? undefined,
              menuyetki_960711_value: "a3dec620-00ec-4228-cd88-08dcb39bb748",
              menuyetki_623465_value: this.state.RoleMenuGoster?.at?.(0)?.m996 ?? undefined,
              menuyetki_276121_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg996 ?? undefined,
              menuyetki_910973_value: "8fa48645-3e2c-4323-cd86-08dcb39bb748",
              menuyetki_959888_value: this.state.RoleMenuGoster?.at?.(0)?.m101 ?? undefined,
              menuyetki_549197_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg101 ?? undefined,
              menuyetki_736037_value: "f83db1e2-d822-42ea-cd8a-08dcb39bb748",
              menuyetki_758360_value: this.state.RoleMenuGoster?.at?.(0)?.m102 ?? undefined,
              menuyetki_165541_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg102 ?? undefined,
              menuyetki_690393_value: "b499ec79-d47a-4a7b-cd84-08dcb39bb748",
              menuyetki_649716_value: this.state.RoleMenuGoster?.at?.(0)?.m103 ?? undefined,
              menuyetki_103489_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg103 ?? undefined,
              menuyetki_149430_value: "3709ca19-cee6-4c50-cd92-08dcb39bb748"
            }}
            onValuesChange={(changedValues, allValues) => {
              this.clearFieldValidation(changedValues);
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <div
                id="menuyetki_body"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="938846"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="585930"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 28,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(22, 48, 32, 1)"
                      } as any
                    }
                  >
                    <Label
                      id="205411"
                      value={ReactSystemFunctions.translate(this.ml, 205411, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(22, 48, 32, 1)"
                        } as any
                      }
                    ></Label>
                  </KCol>

                  <KCol
                    id="900369"
                    xs={7}
                    sm={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(22, 48, 32, 1)"
                      } as any
                    }
                  >
                    <Form.Item className="kFormItem" name="menuyetki_722952_value">
                      <KSelectBox
                        id="722952"
                        onChange={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.MenuYetkiComponent_722952_onChange();
                        }}
                        kuikaRef={this.menuyetki_722952_value_kuikaSelectBoxRef}
                        options={this.state.AltRolAll}
                        placeholder={ReactSystemFunctions.translate(this.ml, 722952, "placeholder", this.defaultML)}
                        allowClear={false}
                        autoClearSearchValue={true}
                        showSearch={false}
                        onSearch={{}}
                        widthType="fill"
                        containsNullItem={false}
                        sortBy="none"
                        datavaluefield="id"
                        datatextfield="altRolName"
                        style={
                          {
                            borderTopLeftRadius: 4,
                            borderTopRightRadius: 4,
                            borderBottomRightRadius: 4,
                            borderBottomLeftRadius: 4,
                            borderTopWidth: 1,
                            borderRightWidth: 1,
                            borderBottomWidth: 1,
                            borderLeftWidth: 1,
                            borderColor: "rgba(218, 218, 218, 1)",
                            borderStyle: "solid",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "block",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      ></KSelectBox>
                    </Form.Item>
                  </KCol>

                  <KCol
                    id="627786"
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    xl={2}
                    xxl={2}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(22, 48, 32, 1)"
                      } as any
                    }
                  >
                    <Icon
                      id="628909"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.MenuYetkiComponent_628909_onClick();
                      }}
                      showCursorPointer
                      iconName="queue"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "36px",
                          color: "rgba(2, 145, 201, 1)",
                          letterSpacing: "1px"
                        } as any
                      }
                    ></Icon>
                  </KCol>

                  <KCol
                    id="783867"
                    xs={7}
                    sm={7}
                    md={7}
                    lg={7}
                    xl={7}
                    xxl={7}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "center",
                        textAlign: "-webkit-right",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(22, 48, 32, 1)"
                      } as any
                    }
                  >
                    <Icon
                      id="620279"
                      onClick={(e?: any) => {
                        if (e && e.stopPropagation) e.stopPropagation();
                        this.MenuYetkiComponent_620279_onClick();
                      }}
                      showCursorPointer
                      iconName="close"
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "center",
                          textAlign: "-webkit-left",
                          display: "inline",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "20px",
                          color: "rgba(250, 140, 22, 1)",
                          letterSpacing: "1px"
                        } as any
                      }
                    ></Icon>
                  </KCol>
                </KRow>

                <KRow
                  id="731595"
                  visibility={this.state.isComp731595Visible}
                  visibilityCondition={(rowData: object) =>
                    ReactSystemFunctions.isNotEmpty(this.state.AltRolAll[0]?.id?.toString(), "")
                  }
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="634381"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(22, 48, 32, 1)"
                      } as any
                    }
                  >
                    <KRow
                      id="822360"
                      visibility={this.state.isCompmenuyetki_822360AuthorizationVisible}
                      visibilityByAuthorization={this.state.isCompmenuyetki_822360AuthorizationVisible}
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderTopWidth: 1,
                          borderColor: "rgba(244, 244, 244, 1)",
                          borderStyle: "solid",
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(22, 48, 32, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="692740"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="974114"
                          direction="horizontal"
                          size={12}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <Form.Item className="kFormItem" name="menuyetki_347047_value">
                            <Image
                              id="347047"
                              zoomOnClick={false}
                              imageFit="fit"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  width: "24px",
                                  height: "24px",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            ></Image>
                          </Form.Item>

                          <Label
                            id="950642"
                            visibility={this.state.isComp950642Visible}
                            value={ReactSystemFunctions.translate(this.ml, 950642, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          ></Label>
                        </HorizontalStack>

                        <Label
                          id="763095"
                          value={ReactSystemFunctions.translate(this.ml, 763095, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        ></Label>
                      </KCol>

                      <KCol
                        id="730535"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-right",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KRow
                          id="765313"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KCol
                            id="578505"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="392348"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="239613"
                              value={ReactSystemFunctions.translate(this.ml, 239613, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            ></Label>
                          </KCol>

                          <KCol
                            id="851175"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                alignItems: "center",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <Label
                              id="949188"
                              value={ReactSystemFunctions.translate(this.ml, 949188, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            ></Label>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>

                    <KRow
                      id="246193"
                      visibility={
                        this.state.isComp246193Visible == "hidden" ||
                        this.state.isCompmenuyetki_246193AuthorizationVisible == "hidden"
                          ? "hidden"
                          : "visible"
                      }
                      visibilityByAuthorization={
                        this.state.isCompmenuyetki_246193AuthorizationVisible == "hidden" ? "hidden" : "visible"
                      }
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderTopWidth: 1,
                          borderColor: "rgba(244, 244, 244, 1)",
                          borderStyle: "solid",
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(24, 144, 255, 0.05)",
                          paddingTop: 8,
                          paddingRight: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(22, 48, 32, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="874463"
                        xs={9}
                        sm={9}
                        md={9}
                        lg={9}
                        xl={9}
                        xxl={9}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <HorizontalStack
                          id="88545"
                          direction="horizontal"
                          size={12}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <Form.Item className="kFormItem" name="menuyetki_591911_value">
                            <Image
                              id="591911"
                              zoomOnClick={false}
                              imageFit="fit"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  width: "24px",
                                  height: "24px",
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "block",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            ></Image>
                          </Form.Item>

                          <Label
                            id="143058"
                            value={ReactSystemFunctions.translate(this.ml, 143058, "value", this.defaultML)}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                display: "inline",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          ></Label>
                        </HorizontalStack>
                      </KCol>

                      <KCol
                        id="200065"
                        xs={3}
                        sm={3}
                        md={3}
                        lg={3}
                        xl={3}
                        xxl={3}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-right",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <Icon
                          id="285295"
                          visibility={this.state.isComp285295Visible}
                          iconName="keyboard_arrow_down"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "20px",
                              color: "rgba(250, 173, 20, 1)",
                              letterSpacing: "1px"
                            } as any
                          }
                        ></Icon>

                        <Icon
                          id="712330"
                          visibility={this.state.isComp712330Visible}
                          iconName="keyboard_arrow_up"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "20px",
                              color: "rgba(48, 77, 48, 1)",
                              letterSpacing: "1px"
                            } as any
                          }
                        ></Icon>
                      </KCol>

                      <KCol
                        id="457017"
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        xxl={4}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      ></KCol>

                      <KCol
                        id="475097"
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        xxl={4}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-center",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="menuyetki_794846_value">
                          <Switch
                            id="794846"
                            onChange={(e?: any) => {
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.MenuYetkiComponent_794846_onChange();
                            }}
                            disabled={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                boxShadow: "2px 4px 3px 0px rgba(0, 167, 229, 1)",
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          ></Switch>
                        </Form.Item>
                      </KCol>

                      <KCol
                        id="189459"
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        xxl={4}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 8,
                            paddingRight: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            alignItems: "flex-start",
                            textAlign: "-webkit-center",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <Form.Item className="kFormItem" name="menuyetki_783128_value">
                          <Switch
                            id="783128"
                            onChange={(e?: any) => {
                              if (e && e.stopPropagation) e.stopPropagation();
                              this.MenuYetkiComponent_783128_onChange();
                            }}
                            visibility={this.state.isComp783128Visible}
                            disabled={false}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          ></Switch>
                        </Form.Item>
                      </KCol>
                    </KRow>

                    <KRow
                      id="255417"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          alignItems: "flex-start",
                          textAlign: "-webkit-left",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(22, 48, 32, 1)"
                        } as any
                      }
                    >
                      <KCol
                        id="530183"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "flex-start",
                            textAlign: "-webkit-left",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(22, 48, 32, 1)"
                          } as any
                        }
                      >
                        <KRow
                          id="192925"
                          visibility={this.state.isCompmenuyetki_192925AuthorizationVisible}
                          visibilityByAuthorization={this.state.isCompmenuyetki_192925AuthorizationVisible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderTopWidth: 1,
                              borderColor: "rgba(244, 244, 244, 1)",
                              borderStyle: "solid",
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KCol
                            id="132861"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <HorizontalStack
                              id="978277"
                              direction="horizontal"
                              size={12}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 36,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <Form.Item className="kFormItem" name="menuyetki_630551_value">
                                <Image
                                  id="630551"
                                  zoomOnClick={false}
                                  imageFit="fit"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      width: "24px",
                                      height: "24px",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Image>
                              </Form.Item>

                              <Label
                                id="442039"
                                value={ReactSystemFunctions.translate(this.ml, 442039, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              ></Label>
                            </HorizontalStack>
                          </KCol>

                          <KCol
                            id="202391"
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            xxl={4}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="600182"
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            xxl={4}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="menuyetki_991681_value">
                              <Switch
                                id="991681"
                                onChange={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.MenuYetkiComponent_991681_onChange();
                                }}
                                visibility={this.state.isComp991681Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isNotEqualTo(
                                    this.state.RoleSadeceGoruntule[0]?.sg011?.toString(),
                                    "1"
                                  )
                                }
                                editability={this.state.isComp991681Enabled}
                                editabilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(this.state.RoleMenuGoster[0]?.m001?.toString(), "1")
                                }
                                disabled={false}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              ></Switch>
                            </Form.Item>
                          </KCol>

                          <KCol
                            id="611557"
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            xxl={4}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="menuyetki_599288_value">
                              <Switch
                                id="599288"
                                onChange={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.MenuYetkiComponent_599288_onChange();
                                }}
                                visibility={this.state.isComp599288Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(this.state.RoleMenuGoster[0]?.m011?.toString(), "1")
                                }
                                disabled={false}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              ></Switch>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="884300"
                          visibility={this.state.isCompmenuyetki_884300AuthorizationVisible}
                          visibilityByAuthorization={this.state.isCompmenuyetki_884300AuthorizationVisible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderTopWidth: 1,
                              borderColor: "rgba(244, 244, 244, 1)",
                              borderStyle: "solid",
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KCol
                            id="861134"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <HorizontalStack
                              id="554020"
                              direction="horizontal"
                              size={12}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 36,
                                  alignItems: "center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <Form.Item className="kFormItem" name="menuyetki_872359_value">
                                <Image
                                  id="872359"
                                  zoomOnClick={false}
                                  imageFit="fit"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      width: "24px",
                                      height: "24px",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Image>
                              </Form.Item>

                              <Label
                                id="398599"
                                value={ReactSystemFunctions.translate(this.ml, 398599, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              ></Label>
                            </HorizontalStack>
                          </KCol>

                          <KCol
                            id="460308"
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            xxl={4}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="208137"
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            xxl={4}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="menuyetki_867369_value">
                              <Switch
                                id="867369"
                                onChange={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.MenuYetkiComponent_867369_onChange();
                                }}
                                visibility={this.state.isComp867369Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isNotEqualTo(
                                    this.state.RoleSadeceGoruntule[0]?.sg012?.toString(),
                                    "1"
                                  )
                                }
                                editability={this.state.isComp867369Enabled}
                                editabilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(this.state.RoleMenuGoster[0]?.m001?.toString(), "1")
                                }
                                disabled={false}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              ></Switch>
                            </Form.Item>
                          </KCol>

                          <KCol
                            id="626635"
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            xxl={4}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="menuyetki_374583_value">
                              <Switch
                                id="374583"
                                onChange={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.MenuYetkiComponent_374583_onChange();
                                }}
                                visibility={this.state.isComp374583Visible}
                                visibilityCondition={(rowData: object) =>
                                  ReactSystemFunctions.isEqualTo(this.state.RoleMenuGoster[0]?.m012?.toString(), "1")
                                }
                                disabled={false}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              ></Switch>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="985374"
                          visibility={
                            this.state.isComp985374Visible == "hidden" ||
                            this.state.isCompmenuyetki_985374AuthorizationVisible == "hidden"
                              ? "hidden"
                              : "visible"
                          }
                          visibilityByAuthorization={
                            this.state.isCompmenuyetki_985374AuthorizationVisible == "hidden" ? "hidden" : "visible"
                          }
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderTopWidth: 1,
                              borderColor: "rgba(244, 244, 244, 1)",
                              borderStyle: "solid",
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(24, 144, 255, 0.05)",
                              paddingTop: 8,
                              paddingRight: 8,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KCol
                            id="130304"
                            xs={9}
                            sm={9}
                            md={9}
                            lg={9}
                            xl={9}
                            xxl={9}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <HorizontalStack
                              id="903527"
                              direction="horizontal"
                              size={12}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <Form.Item className="kFormItem" name="menuyetki_309374_value">
                                <Image
                                  id="309374"
                                  zoomOnClick={false}
                                  imageFit="fit"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      width: "24px",
                                      height: "24px",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                ></Image>
                              </Form.Item>

                              <Label
                                id="308498"
                                value={ReactSystemFunctions.translate(this.ml, 308498, "value", this.defaultML)}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "inline",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              ></Label>
                            </HorizontalStack>
                          </KCol>

                          <KCol
                            id="449165"
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            xxl={3}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-right",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <Icon
                              id="395331"
                              visibility={this.state.isComp395331Visible}
                              iconName="keyboard_arrow_down"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "20px",
                                  color: "rgba(250, 173, 20, 1)",
                                  letterSpacing: "1px"
                                } as any
                              }
                            ></Icon>

                            <Icon
                              id="411912"
                              visibility={this.state.isComp411912Visible}
                              iconName="keyboard_arrow_up"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "20px",
                                  color: "rgba(48, 77, 48, 1)",
                                  letterSpacing: "1px"
                                } as any
                              }
                            ></Icon>
                          </KCol>

                          <KCol
                            id="61177"
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            xxl={4}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="75590"
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            xxl={4}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="menuyetki_930852_value">
                              <Switch
                                id="930852"
                                onChange={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.MenuYetkiComponent_930852_onChange();
                                }}
                                disabled={false}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    boxShadow: "2px 4px 3px 0px rgba(0, 167, 229, 1)",
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              ></Switch>
                            </Form.Item>
                          </KCol>

                          <KCol
                            id="673730"
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            xxl={4}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-center",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="menuyetki_344494_value">
                              <Switch
                                id="344494"
                                onChange={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.MenuYetkiComponent_344494_onChange();
                                }}
                                visibility={this.state.isComp344494Visible}
                                disabled={false}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              ></Switch>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="902617"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              alignItems: "flex-start",
                              textAlign: "-webkit-left",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        >
                          <KCol
                            id="810154"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 0,
                                paddingRight: 0,
                                paddingBottom: 0,
                                paddingLeft: 0,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(22, 48, 32, 1)"
                              } as any
                            }
                          >
                            <KRow
                              id="769054"
                              visibility={this.state.isCompmenuyetki_769054AuthorizationVisible}
                              visibilityByAuthorization={this.state.isCompmenuyetki_769054AuthorizationVisible}
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderColor: "rgba(244, 244, 244, 1)",
                                  borderStyle: "solid",
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KCol
                                id="315375"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 36,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <HorizontalStack
                                  id="96104"
                                  direction="horizontal"
                                  size={12}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <Form.Item className="kFormItem" name="menuyetki_664614_value">
                                    <Image
                                      id="664614"
                                      zoomOnClick={false}
                                      imageFit="fit"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          width: "24px",
                                          height: "24px",
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    ></Image>
                                  </Form.Item>

                                  <Label
                                    id="60264"
                                    value={ReactSystemFunctions.translate(this.ml, 60264, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </HorizontalStack>
                              </KCol>

                              <KCol
                                id="199117"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              ></KCol>

                              <KCol
                                id="706422"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="menuyetki_364118_value">
                                  <Switch
                                    id="364118"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.MenuYetkiComponent_364118_onChange();
                                    }}
                                    visibility={this.state.isComp364118Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isNotEqualTo(
                                        this.state.RoleSadeceGoruntule[0]?.sg021?.toString(),
                                        "1"
                                      )
                                    }
                                    editability={this.state.isComp364118Enabled}
                                    editabilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isEqualTo(
                                        this.state.RoleMenuGoster[0]?.m002?.toString(),
                                        "1"
                                      )
                                    }
                                    disabled={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></Switch>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="697167"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="menuyetki_929129_value">
                                  <Switch
                                    id="929129"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.MenuYetkiComponent_929129_onChange();
                                    }}
                                    visibility={this.state.isComp929129Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isEqualTo(
                                        this.state.RoleMenuGoster[0]?.m021?.toString(),
                                        "1"
                                      )
                                    }
                                    disabled={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></Switch>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="923752"
                              visibility={this.state.isCompmenuyetki_923752AuthorizationVisible}
                              visibilityByAuthorization={this.state.isCompmenuyetki_923752AuthorizationVisible}
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderColor: "rgba(244, 244, 244, 1)",
                                  borderStyle: "solid",
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KCol
                                id="498558"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 36,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <HorizontalStack
                                  id="591169"
                                  direction="horizontal"
                                  size={12}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <Form.Item className="kFormItem" name="menuyetki_963636_value">
                                    <Image
                                      id="963636"
                                      zoomOnClick={false}
                                      imageFit="fit"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          width: "24px",
                                          height: "24px",
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    ></Image>
                                  </Form.Item>

                                  <Label
                                    id="726551"
                                    value={ReactSystemFunctions.translate(this.ml, 726551, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </HorizontalStack>
                              </KCol>

                              <KCol
                                id="566138"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              ></KCol>

                              <KCol
                                id="486825"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="menuyetki_837451_value">
                                  <Switch
                                    id="837451"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.MenuYetkiComponent_837451_onChange();
                                    }}
                                    visibility={this.state.isComp837451Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isNotEqualTo(
                                        this.state.RoleSadeceGoruntule[0]?.sg022?.toString(),
                                        "1"
                                      )
                                    }
                                    editability={this.state.isComp837451Enabled}
                                    editabilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isEqualTo(
                                        this.state.RoleMenuGoster[0]?.m002?.toString(),
                                        "1"
                                      )
                                    }
                                    disabled={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></Switch>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="946762"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="menuyetki_974301_value">
                                  <Switch
                                    id="974301"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.MenuYetkiComponent_974301_onChange();
                                    }}
                                    visibility={this.state.isComp974301Visible}
                                    visibilityCondition={(rowData: object) =>
                                      ReactSystemFunctions.isEqualTo(
                                        this.state.RoleMenuGoster[0]?.m022?.toString(),
                                        "1"
                                      )
                                    }
                                    disabled={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></Switch>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="670211"
                              visibility={
                                this.state.isComp670211Visible == "hidden" ||
                                this.state.isCompmenuyetki_670211AuthorizationVisible == "hidden"
                                  ? "hidden"
                                  : "visible"
                              }
                              visibilityByAuthorization={
                                this.state.isCompmenuyetki_670211AuthorizationVisible == "hidden" ? "hidden" : "visible"
                              }
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderColor: "rgba(244, 244, 244, 1)",
                                  borderStyle: "solid",
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KCol
                                id="140439"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <HorizontalStack
                                  id="873465"
                                  direction="horizontal"
                                  size={12}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <Form.Item className="kFormItem" name="menuyetki_695738_value">
                                    <Image
                                      id="695738"
                                      zoomOnClick={false}
                                      imageFit="fit"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          width: "24px",
                                          height: "24px",
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    ></Image>
                                  </Form.Item>

                                  <Label
                                    id="376448"
                                    value={ReactSystemFunctions.translate(this.ml, 376448, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </HorizontalStack>
                              </KCol>

                              <KCol
                                id="898661"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              ></KCol>

                              <KCol
                                id="287567"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="menuyetki_653815_value">
                                  <Switch
                                    id="653815"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.MenuYetkiComponent_653815_onChange();
                                    }}
                                    disabled={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></Switch>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="473523"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="menuyetki_544223_value">
                                  <Switch
                                    id="544223"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.MenuYetkiComponent_544223_onChange();
                                    }}
                                    visibility={this.state.isComp544223Visible}
                                    disabled={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></Switch>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="504493"
                              visibility={
                                this.state.isComp504493Visible == "hidden" ||
                                this.state.isCompmenuyetki_504493AuthorizationVisible == "hidden"
                                  ? "hidden"
                                  : "visible"
                              }
                              visibilityByAuthorization={
                                this.state.isCompmenuyetki_504493AuthorizationVisible == "hidden" ? "hidden" : "visible"
                              }
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderColor: "rgba(244, 244, 244, 1)",
                                  borderStyle: "solid",
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(24, 144, 255, 0.05)",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KCol
                                id="544552"
                                xs={9}
                                sm={9}
                                md={9}
                                lg={9}
                                xl={9}
                                xxl={9}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <HorizontalStack
                                  id="9696"
                                  direction="horizontal"
                                  size={12}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <Form.Item className="kFormItem" name="menuyetki_692314_value">
                                    <Image
                                      id="692314"
                                      zoomOnClick={false}
                                      imageFit="fit"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          width: "24px",
                                          height: "24px",
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    ></Image>
                                  </Form.Item>

                                  <Label
                                    id="148395"
                                    value={ReactSystemFunctions.translate(this.ml, 148395, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></Label>
                                </HorizontalStack>
                              </KCol>

                              <KCol
                                id="20938"
                                xs={3}
                                sm={3}
                                md={3}
                                lg={3}
                                xl={3}
                                xxl={3}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-right",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Icon
                                  id="49017"
                                  visibility={this.state.isComp49017Visible}
                                  iconName="keyboard_arrow_down"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "rgba(250, 173, 20, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>

                                <Icon
                                  id="636754"
                                  visibility={this.state.isComp636754Visible}
                                  iconName="keyboard_arrow_up"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "20px",
                                      color: "rgba(48, 77, 48, 1)",
                                      letterSpacing: "1px"
                                    } as any
                                  }
                                ></Icon>
                              </KCol>

                              <KCol
                                id="59324"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              ></KCol>

                              <KCol
                                id="552886"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="menuyetki_997_value">
                                  <Switch
                                    id="997"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.MenuYetkiComponent_997_onChange();
                                    }}
                                    disabled={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        boxShadow: "2px 4px 3px 0px rgba(0, 167, 229, 1)",
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></Switch>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="407114"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-center",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <Form.Item className="kFormItem" name="menuyetki_976959_value">
                                  <Switch
                                    id="976959"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.MenuYetkiComponent_976959_onChange();
                                    }}
                                    visibility={this.state.isComp976959Visible}
                                    disabled={false}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></Switch>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="501198"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 0,
                                  paddingRight: 0,
                                  paddingBottom: 0,
                                  paddingLeft: 0,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(22, 48, 32, 1)"
                                } as any
                              }
                            >
                              <KCol
                                id="151981"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(22, 48, 32, 1)"
                                  } as any
                                }
                              >
                                <KRow
                                  id="406721"
                                  visibility={this.state.isCompmenuyetki_406721AuthorizationVisible}
                                  visibilityByAuthorization={this.state.isCompmenuyetki_406721AuthorizationVisible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="624013"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 36,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <HorizontalStack
                                      id="154800"
                                      direction="horizontal"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Form.Item className="kFormItem" name="menuyetki_517146_value">
                                        <Image
                                          id="517146"
                                          zoomOnClick={false}
                                          imageFit="fit"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              width: "24px",
                                              height: "24px",
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        ></Image>
                                      </Form.Item>

                                      <Label
                                        id="107114"
                                        value={ReactSystemFunctions.translate(this.ml, 107114, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </HorizontalStack>
                                  </KCol>

                                  <KCol
                                    id="44115"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></KCol>

                                  <KCol
                                    id="923708"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="menuyetki_894376_value">
                                      <Switch
                                        id="894376"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.MenuYetkiComponent_894376_onChange();
                                        }}
                                        visibility={this.state.isComp894376Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isNotEqualTo(
                                            this.state.RoleSadeceGoruntule[0]?.sg041?.toString(),
                                            "1"
                                          )
                                        }
                                        editability={this.state.isComp894376Enabled}
                                        editabilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(
                                            this.state.RoleMenuGoster[0]?.m004?.toString(),
                                            "1"
                                          )
                                        }
                                        disabled={false}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Switch>
                                    </Form.Item>
                                  </KCol>

                                  <KCol
                                    id="883842"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="menuyetki_165606_value">
                                      <Switch
                                        id="165606"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.MenuYetkiComponent_165606_onChange();
                                        }}
                                        visibility={this.state.isComp165606Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(
                                            this.state.RoleMenuGoster[0]?.m041?.toString(),
                                            "1"
                                          )
                                        }
                                        disabled={false}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Switch>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="361522"
                                  visibility={this.state.isCompmenuyetki_361522AuthorizationVisible}
                                  visibilityByAuthorization={this.state.isCompmenuyetki_361522AuthorizationVisible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="758682"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 36,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <HorizontalStack
                                      id="14870"
                                      direction="horizontal"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Form.Item className="kFormItem" name="menuyetki_68585_value">
                                        <Image
                                          id="68585"
                                          zoomOnClick={false}
                                          imageFit="fit"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              width: "24px",
                                              height: "24px",
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        ></Image>
                                      </Form.Item>

                                      <Label
                                        id="828117"
                                        value={ReactSystemFunctions.translate(this.ml, 828117, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </HorizontalStack>
                                  </KCol>

                                  <KCol
                                    id="346022"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></KCol>

                                  <KCol
                                    id="144671"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="menuyetki_604653_value">
                                      <Switch
                                        id="604653"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.MenuYetkiComponent_604653_onChange();
                                        }}
                                        visibility={this.state.isComp604653Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isNotEqualTo(
                                            this.state.RoleSadeceGoruntule[0]?.sg042?.toString(),
                                            "1"
                                          )
                                        }
                                        editability={this.state.isComp604653Enabled}
                                        editabilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(
                                            this.state.RoleMenuGoster[0]?.m004?.toString(),
                                            "1"
                                          )
                                        }
                                        disabled={false}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Switch>
                                    </Form.Item>
                                  </KCol>

                                  <KCol
                                    id="620759"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="menuyetki_112048_value">
                                      <Switch
                                        id="112048"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.MenuYetkiComponent_112048_onChange();
                                        }}
                                        visibility={this.state.isComp112048Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(
                                            this.state.RoleMenuGoster[0]?.m042?.toString(),
                                            "1"
                                          )
                                        }
                                        disabled={false}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Switch>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="533454"
                                  visibility={this.state.isCompmenuyetki_533454AuthorizationVisible}
                                  visibilityByAuthorization={this.state.isCompmenuyetki_533454AuthorizationVisible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="761006"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 36,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <HorizontalStack
                                      id="28130"
                                      direction="horizontal"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Form.Item className="kFormItem" name="menuyetki_339952_value">
                                        <Image
                                          id="339952"
                                          zoomOnClick={false}
                                          imageFit="fit"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              width: "24px",
                                              height: "24px",
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        ></Image>
                                      </Form.Item>

                                      <Label
                                        id="57952"
                                        value={ReactSystemFunctions.translate(this.ml, 57952, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </HorizontalStack>
                                  </KCol>

                                  <KCol
                                    id="11147"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></KCol>

                                  <KCol
                                    id="909550"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="menuyetki_588733_value">
                                      <Switch
                                        id="588733"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.MenuYetkiComponent_588733_onChange();
                                        }}
                                        visibility={this.state.isComp588733Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isNotEqualTo(
                                            this.state.RoleSadeceGoruntule[0]?.sg043?.toString(),
                                            "1"
                                          )
                                        }
                                        editability={this.state.isComp588733Enabled}
                                        editabilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(
                                            this.state.RoleMenuGoster[0]?.m004?.toString(),
                                            "1"
                                          )
                                        }
                                        disabled={false}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Switch>
                                    </Form.Item>
                                  </KCol>

                                  <KCol
                                    id="513418"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="menuyetki_610225_value">
                                      <Switch
                                        id="610225"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.MenuYetkiComponent_610225_onChange();
                                        }}
                                        visibility={this.state.isComp610225Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(
                                            this.state.RoleMenuGoster[0]?.m043?.toString(),
                                            "1"
                                          )
                                        }
                                        disabled={false}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Switch>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="970680"
                                  visibility={this.state.isCompmenuyetki_970680AuthorizationVisible}
                                  visibilityByAuthorization={this.state.isCompmenuyetki_970680AuthorizationVisible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="529589"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 36,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <HorizontalStack
                                      id="126673"
                                      direction="horizontal"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Form.Item className="kFormItem" name="menuyetki_915455_value">
                                        <Image
                                          id="915455"
                                          zoomOnClick={false}
                                          imageFit="fit"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              width: "24px",
                                              height: "24px",
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        ></Image>
                                      </Form.Item>

                                      <Label
                                        id="579963"
                                        value={ReactSystemFunctions.translate(this.ml, 579963, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </HorizontalStack>
                                  </KCol>

                                  <KCol
                                    id="564252"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></KCol>

                                  <KCol
                                    id="770116"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="menuyetki_819978_value">
                                      <Switch
                                        id="819978"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.MenuYetkiComponent_819978_onChange();
                                        }}
                                        visibility={this.state.isComp819978Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isNotEqualTo(
                                            this.state.RoleSadeceGoruntule[0]?.sg044?.toString(),
                                            "1"
                                          )
                                        }
                                        editability={this.state.isComp819978Enabled}
                                        editabilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(
                                            this.state.RoleMenuGoster[0]?.m004?.toString(),
                                            "1"
                                          )
                                        }
                                        disabled={false}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Switch>
                                    </Form.Item>
                                  </KCol>

                                  <KCol
                                    id="869788"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="menuyetki_430529_value">
                                      <Switch
                                        id="430529"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.MenuYetkiComponent_430529_onChange();
                                        }}
                                        visibility={this.state.isComp430529Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(
                                            this.state.RoleMenuGoster[0]?.m044?.toString(),
                                            "1"
                                          )
                                        }
                                        disabled={false}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Switch>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="411822"
                                  visibility={this.state.isCompmenuyetki_411822AuthorizationVisible}
                                  visibilityByAuthorization={this.state.isCompmenuyetki_411822AuthorizationVisible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="851977"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 36,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <HorizontalStack
                                      id="534068"
                                      direction="horizontal"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Form.Item className="kFormItem" name="menuyetki_256123_value">
                                        <Image
                                          id="256123"
                                          zoomOnClick={false}
                                          imageFit="fit"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              width: "24px",
                                              height: "24px",
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        ></Image>
                                      </Form.Item>

                                      <Label
                                        id="292867"
                                        value={ReactSystemFunctions.translate(this.ml, 292867, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </HorizontalStack>
                                  </KCol>

                                  <KCol
                                    id="504647"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></KCol>

                                  <KCol
                                    id="115235"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="menuyetki_787081_value">
                                      <Switch
                                        id="787081"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.MenuYetkiComponent_787081_onChange();
                                        }}
                                        visibility={this.state.isComp787081Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isNotEqualTo(
                                            this.state.RoleSadeceGoruntule[0]?.sg045?.toString(),
                                            "1"
                                          )
                                        }
                                        editability={this.state.isComp787081Enabled}
                                        editabilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(
                                            this.state.RoleMenuGoster[0]?.m004?.toString(),
                                            "1"
                                          )
                                        }
                                        disabled={false}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Switch>
                                    </Form.Item>
                                  </KCol>

                                  <KCol
                                    id="774673"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="menuyetki_363900_value">
                                      <Switch
                                        id="363900"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.MenuYetkiComponent_363900_onChange();
                                        }}
                                        visibility={this.state.isComp363900Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(
                                            this.state.RoleMenuGoster[0]?.m045?.toString(),
                                            "1"
                                          )
                                        }
                                        disabled={false}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Switch>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="15063"
                                  visibility={this.state.isCompmenuyetki_15063AuthorizationVisible}
                                  visibilityByAuthorization={this.state.isCompmenuyetki_15063AuthorizationVisible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="993722"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 36,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <HorizontalStack
                                      id="585584"
                                      direction="horizontal"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Form.Item className="kFormItem" name="menuyetki_737709_value">
                                        <Image
                                          id="737709"
                                          zoomOnClick={false}
                                          imageFit="fit"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              width: "24px",
                                              height: "24px",
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        ></Image>
                                      </Form.Item>

                                      <Label
                                        id="428389"
                                        value={ReactSystemFunctions.translate(this.ml, 428389, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </HorizontalStack>
                                  </KCol>

                                  <KCol
                                    id="683776"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></KCol>

                                  <KCol
                                    id="115673"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="menuyetki_555420_value">
                                      <Switch
                                        id="555420"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.MenuYetkiComponent_555420_onChange();
                                        }}
                                        visibility={this.state.isComp555420Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isNotEqualTo(
                                            this.state.RoleSadeceGoruntule[0]?.sg046?.toString(),
                                            "1"
                                          )
                                        }
                                        editability={this.state.isComp555420Enabled}
                                        editabilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(
                                            this.state.RoleMenuGoster[0]?.m004?.toString(),
                                            "1"
                                          )
                                        }
                                        disabled={false}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Switch>
                                    </Form.Item>
                                  </KCol>

                                  <KCol
                                    id="644248"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="menuyetki_591274_value">
                                      <Switch
                                        id="591274"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.MenuYetkiComponent_591274_onChange();
                                        }}
                                        visibility={this.state.isComp591274Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(
                                            this.state.RoleMenuGoster[0]?.m046?.toString(),
                                            "1"
                                          )
                                        }
                                        disabled={false}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Switch>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="64247"
                                  visibility={this.state.isCompmenuyetki_64247AuthorizationVisible}
                                  visibilityByAuthorization={this.state.isCompmenuyetki_64247AuthorizationVisible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="776054"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 36,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <HorizontalStack
                                      id="911561"
                                      direction="horizontal"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Form.Item className="kFormItem" name="menuyetki_964111_value">
                                        <Image
                                          id="964111"
                                          zoomOnClick={false}
                                          imageFit="fit"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              width: "24px",
                                              height: "24px",
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        ></Image>
                                      </Form.Item>

                                      <Label
                                        id="799101"
                                        value={ReactSystemFunctions.translate(this.ml, 799101, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 400,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </HorizontalStack>
                                  </KCol>

                                  <KCol
                                    id="47823"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></KCol>

                                  <KCol
                                    id="368684"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="menuyetki_212542_value">
                                      <Switch
                                        id="212542"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.MenuYetkiComponent_212542_onChange();
                                        }}
                                        visibility={this.state.isComp212542Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isNotEqualTo(
                                            this.state.RoleSadeceGoruntule[0]?.sg047?.toString(),
                                            "1"
                                          )
                                        }
                                        editability={this.state.isComp212542Enabled}
                                        editabilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(
                                            this.state.RoleMenuGoster[0]?.m004?.toString(),
                                            "1"
                                          )
                                        }
                                        disabled={false}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Switch>
                                    </Form.Item>
                                  </KCol>

                                  <KCol
                                    id="508240"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="menuyetki_716806_value">
                                      <Switch
                                        id="716806"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.MenuYetkiComponent_716806_onChange();
                                        }}
                                        visibility={this.state.isComp716806Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(
                                            this.state.RoleMenuGoster[0]?.m047?.toString(),
                                            "1"
                                          )
                                        }
                                        disabled={false}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Switch>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="765434"
                                  visibility={
                                    this.state.isComp765434Visible == "hidden" ||
                                    this.state.isCompmenuyetki_765434AuthorizationVisible == "hidden"
                                      ? "hidden"
                                      : "visible"
                                  }
                                  visibilityByAuthorization={
                                    this.state.isCompmenuyetki_765434AuthorizationVisible == "hidden"
                                      ? "hidden"
                                      : "visible"
                                  }
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderColor: "rgba(244, 244, 244, 1)",
                                      borderStyle: "solid",
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(24, 144, 255, 0.05)",
                                      paddingTop: 8,
                                      paddingRight: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="971105"
                                    xs={9}
                                    sm={9}
                                    md={9}
                                    lg={9}
                                    xl={9}
                                    xxl={9}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <HorizontalStack
                                      id="125562"
                                      direction="horizontal"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <Form.Item className="kFormItem" name="menuyetki_48700_value">
                                        <Image
                                          id="48700"
                                          zoomOnClick={false}
                                          imageFit="fit"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              width: "24px",
                                              height: "24px",
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        ></Image>
                                      </Form.Item>

                                      <Label
                                        id="212431"
                                        value={ReactSystemFunctions.translate(this.ml, 212431, "value", this.defaultML)}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Label>
                                    </HorizontalStack>
                                  </KCol>

                                  <KCol
                                    id="731121"
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    lg={3}
                                    xl={3}
                                    xxl={3}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="874444"
                                      visibility={this.state.isComp874444Visible}
                                      iconName="keyboard_arrow_down"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(250, 173, 20, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>

                                    <Icon
                                      id="623750"
                                      visibility={this.state.isComp623750Visible}
                                      iconName="keyboard_arrow_up"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "20px",
                                          color: "rgba(48, 77, 48, 1)",
                                          letterSpacing: "1px"
                                        } as any
                                      }
                                    ></Icon>
                                  </KCol>

                                  <KCol
                                    id="762891"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  ></KCol>

                                  <KCol
                                    id="668270"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="menuyetki_640419_value">
                                      <Switch
                                        id="640419"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.MenuYetkiComponent_640419_onChange();
                                        }}
                                        disabled={false}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            boxShadow: "2px 4px 3px 0px rgba(0, 167, 229, 1)",
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Switch>
                                    </Form.Item>
                                  </KCol>

                                  <KCol
                                    id="221578"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-center",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="menuyetki_644120_value">
                                      <Switch
                                        id="644120"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.MenuYetkiComponent_644120_onChange();
                                        }}
                                        visibility={this.state.isComp644120Visible}
                                        disabled={false}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></Switch>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="357361"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      alignItems: "flex-start",
                                      textAlign: "-webkit-left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(22, 48, 32, 1)"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="605961"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        alignItems: "flex-start",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(22, 48, 32, 1)"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="518738"
                                      visibility={this.state.isCompmenuyetki_518738AuthorizationVisible}
                                      visibilityByAuthorization={this.state.isCompmenuyetki_518738AuthorizationVisible}
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopWidth: 1,
                                          borderColor: "rgba(244, 244, 244, 1)",
                                          borderStyle: "solid",
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="791368"
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        xxl={12}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 36,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <HorizontalStack
                                          id="438612"
                                          direction="horizontal"
                                          size={12}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        >
                                          <Form.Item className="kFormItem" name="menuyetki_439768_value">
                                            <Image
                                              id="439768"
                                              zoomOnClick={false}
                                              imageFit="fit"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  width: "24px",
                                                  height: "24px",
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(22, 48, 32, 1)"
                                                } as any
                                              }
                                            ></Image>
                                          </Form.Item>

                                          <Label
                                            id="280726"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              280726,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </HorizontalStack>
                                      </KCol>

                                      <KCol
                                        id="556634"
                                        xs={4}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                        xxl={4}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></KCol>

                                      <KCol
                                        id="69082"
                                        xs={4}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                        xxl={4}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-center",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Form.Item className="kFormItem" name="menuyetki_629264_value">
                                          <Switch
                                            id="629264"
                                            onChange={(e?: any) => {
                                              if (e && e.stopPropagation) e.stopPropagation();
                                              this.MenuYetkiComponent_629264_onChange();
                                            }}
                                            visibility={this.state.isComp629264Visible}
                                            visibilityCondition={(rowData: object) =>
                                              ReactSystemFunctions.isNotEqualTo(
                                                this.state.RoleSadeceGoruntule[0]?.sg051?.toString(),
                                                "1"
                                              )
                                            }
                                            editability={this.state.isComp629264Enabled}
                                            editabilityCondition={(rowData: object) =>
                                              ReactSystemFunctions.isEqualTo(
                                                this.state.RoleMenuGoster[0]?.m005?.toString(),
                                                "1"
                                              )
                                            }
                                            disabled={false}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          ></Switch>
                                        </Form.Item>
                                      </KCol>

                                      <KCol
                                        id="401063"
                                        xs={4}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                        xxl={4}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-center",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Form.Item className="kFormItem" name="menuyetki_837170_value">
                                          <Switch
                                            id="837170"
                                            onChange={(e?: any) => {
                                              if (e && e.stopPropagation) e.stopPropagation();
                                              this.MenuYetkiComponent_837170_onChange();
                                            }}
                                            visibility={this.state.isComp837170Visible}
                                            visibilityCondition={(rowData: object) =>
                                              ReactSystemFunctions.isEqualTo(
                                                this.state.RoleMenuGoster[0]?.m051?.toString(),
                                                "1"
                                              )
                                            }
                                            disabled={false}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          ></Switch>
                                        </Form.Item>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="274493"
                                      visibility={this.state.isCompmenuyetki_274493AuthorizationVisible}
                                      visibilityByAuthorization={this.state.isCompmenuyetki_274493AuthorizationVisible}
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopWidth: 1,
                                          borderColor: "rgba(244, 244, 244, 1)",
                                          borderStyle: "solid",
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="775127"
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        xxl={12}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 36,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <HorizontalStack
                                          id="927080"
                                          direction="horizontal"
                                          size={12}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        >
                                          <Form.Item className="kFormItem" name="menuyetki_675186_value">
                                            <Image
                                              id="675186"
                                              zoomOnClick={false}
                                              imageFit="fit"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  width: "24px",
                                                  height: "24px",
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(22, 48, 32, 1)"
                                                } as any
                                              }
                                            ></Image>
                                          </Form.Item>

                                          <Label
                                            id="765228"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              765228,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </HorizontalStack>
                                      </KCol>

                                      <KCol
                                        id="929061"
                                        xs={4}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                        xxl={4}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></KCol>

                                      <KCol
                                        id="876643"
                                        xs={4}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                        xxl={4}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-center",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Form.Item className="kFormItem" name="menuyetki_230506_value">
                                          <Switch
                                            id="230506"
                                            onChange={(e?: any) => {
                                              if (e && e.stopPropagation) e.stopPropagation();
                                              this.MenuYetkiComponent_230506_onChange();
                                            }}
                                            visibility={this.state.isComp230506Visible}
                                            visibilityCondition={(rowData: object) =>
                                              ReactSystemFunctions.isNotEqualTo(
                                                this.state.RoleSadeceGoruntule[0]?.sg052?.toString(),
                                                "1"
                                              )
                                            }
                                            editability={this.state.isComp230506Enabled}
                                            editabilityCondition={(rowData: object) =>
                                              ReactSystemFunctions.isEqualTo(
                                                this.state.RoleMenuGoster[0]?.m005?.toString(),
                                                "1"
                                              )
                                            }
                                            disabled={false}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          ></Switch>
                                        </Form.Item>
                                      </KCol>

                                      <KCol
                                        id="918205"
                                        xs={4}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                        xxl={4}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-center",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Form.Item className="kFormItem" name="menuyetki_37445_value">
                                          <Switch
                                            id="37445"
                                            onChange={(e?: any) => {
                                              if (e && e.stopPropagation) e.stopPropagation();
                                              this.MenuYetkiComponent_37445_onChange();
                                            }}
                                            visibility={this.state.isComp37445Visible}
                                            visibilityCondition={(rowData: object) =>
                                              ReactSystemFunctions.isEqualTo(
                                                this.state.RoleMenuGoster[0]?.m052?.toString(),
                                                "1"
                                              )
                                            }
                                            disabled={false}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          ></Switch>
                                        </Form.Item>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="884453"
                                      visibility={
                                        this.state.isComp884453Visible == "hidden" ||
                                        this.state.isCompmenuyetki_884453AuthorizationVisible == "hidden"
                                          ? "hidden"
                                          : "visible"
                                      }
                                      visibilityByAuthorization={
                                        this.state.isCompmenuyetki_884453AuthorizationVisible == "hidden"
                                          ? "hidden"
                                          : "visible"
                                      }
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderTopWidth: 1,
                                          borderColor: "rgba(244, 244, 244, 1)",
                                          borderStyle: "solid",
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundColor: "rgba(24, 144, 255, 0.05)",
                                          paddingTop: 8,
                                          paddingRight: 8,
                                          paddingBottom: 8,
                                          paddingLeft: 8,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="405457"
                                        xs={9}
                                        sm={9}
                                        md={9}
                                        lg={9}
                                        xl={9}
                                        xxl={9}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <HorizontalStack
                                          id="142070"
                                          direction="horizontal"
                                          size={12}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        >
                                          <Form.Item className="kFormItem" name="menuyetki_573854_value">
                                            <Image
                                              id="573854"
                                              zoomOnClick={false}
                                              imageFit="fit"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  width: "24px",
                                                  height: "24px",
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "block",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(22, 48, 32, 1)"
                                                } as any
                                              }
                                            ></Image>
                                          </Form.Item>

                                          <Label
                                            id="787770"
                                            value={ReactSystemFunctions.translate(
                                              this.ml,
                                              787770,
                                              "value",
                                              this.defaultML
                                            )}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                display: "inline",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          ></Label>
                                        </HorizontalStack>
                                      </KCol>

                                      <KCol
                                        id="456828"
                                        xs={3}
                                        sm={3}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                        xxl={3}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Icon
                                          id="64207"
                                          visibility={this.state.isComp64207Visible}
                                          iconName="keyboard_arrow_down"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "20px",
                                              color: "rgba(250, 173, 20, 1)",
                                              letterSpacing: "1px"
                                            } as any
                                          }
                                        ></Icon>

                                        <Icon
                                          id="506233"
                                          visibility={this.state.isComp506233Visible}
                                          iconName="keyboard_arrow_up"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "20px",
                                              color: "rgba(48, 77, 48, 1)",
                                              letterSpacing: "1px"
                                            } as any
                                          }
                                        ></Icon>
                                      </KCol>

                                      <KCol
                                        id="549796"
                                        xs={4}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                        xxl={4}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      ></KCol>

                                      <KCol
                                        id="477774"
                                        xs={4}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                        xxl={4}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-center",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Form.Item className="kFormItem" name="menuyetki_434547_value">
                                          <Switch
                                            id="434547"
                                            onChange={(e?: any) => {
                                              if (e && e.stopPropagation) e.stopPropagation();
                                              this.MenuYetkiComponent_434547_onChange();
                                            }}
                                            disabled={false}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                boxShadow: "2px 4px 3px 0px rgba(0, 167, 229, 1)",
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          ></Switch>
                                        </Form.Item>
                                      </KCol>

                                      <KCol
                                        id="932947"
                                        xs={4}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                        xxl={4}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-center",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <Form.Item className="kFormItem" name="menuyetki_958848_value">
                                          <Switch
                                            id="958848"
                                            onChange={(e?: any) => {
                                              if (e && e.stopPropagation) e.stopPropagation();
                                              this.MenuYetkiComponent_958848_onChange();
                                            }}
                                            visibility={this.state.isComp958848Visible}
                                            disabled={false}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          ></Switch>
                                        </Form.Item>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="313658"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "flex-start",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(22, 48, 32, 1)"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="420891"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(22, 48, 32, 1)"
                                          } as any
                                        }
                                      >
                                        <KRow
                                          id="291782"
                                          visibility={this.state.isCompmenuyetki_291782AuthorizationVisible}
                                          visibilityByAuthorization={
                                            this.state.isCompmenuyetki_291782AuthorizationVisible
                                          }
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderTopWidth: 1,
                                              borderColor: "rgba(244, 244, 244, 1)",
                                              borderStyle: "solid",
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "flex-start",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="817650"
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            xxl={12}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 36,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          >
                                            <HorizontalStack
                                              id="383293"
                                              direction="horizontal"
                                              size={12}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(22, 48, 32, 1)"
                                                } as any
                                              }
                                            >
                                              <Form.Item className="kFormItem" name="menuyetki_228648_value">
                                                <Image
                                                  id="228648"
                                                  zoomOnClick={false}
                                                  imageFit="fit"
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      width: "24px",
                                                      height: "24px",
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      display: "block",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                ></Image>
                                              </Form.Item>

                                              <Label
                                                id="863818"
                                                value={ReactSystemFunctions.translate(
                                                  this.ml,
                                                  863818,
                                                  "value",
                                                  this.defaultML
                                                )}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              ></Label>
                                            </HorizontalStack>
                                          </KCol>

                                          <KCol
                                            id="591489"
                                            xs={4}
                                            sm={4}
                                            md={4}
                                            lg={4}
                                            xl={4}
                                            xxl={4}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          ></KCol>

                                          <KCol
                                            id="437140"
                                            xs={4}
                                            sm={4}
                                            md={4}
                                            lg={4}
                                            xl={4}
                                            xxl={4}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-center",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          >
                                            <Form.Item className="kFormItem" name="menuyetki_149742_value">
                                              <Switch
                                                id="149742"
                                                onChange={(e?: any) => {
                                                  if (e && e.stopPropagation) e.stopPropagation();
                                                  this.MenuYetkiComponent_149742_onChange();
                                                }}
                                                visibility={this.state.isComp149742Visible}
                                                visibilityCondition={(rowData: object) =>
                                                  ReactSystemFunctions.isNotEqualTo(
                                                    this.state.RoleSadeceGoruntule[0]?.sg061?.toString(),
                                                    "1"
                                                  )
                                                }
                                                editability={this.state.isComp149742Enabled}
                                                editabilityCondition={(rowData: object) =>
                                                  ReactSystemFunctions.isEqualTo(
                                                    this.state.RoleMenuGoster[0]?.m006?.toString(),
                                                    "1"
                                                  )
                                                }
                                                disabled={false}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              ></Switch>
                                            </Form.Item>
                                          </KCol>

                                          <KCol
                                            id="893965"
                                            xs={4}
                                            sm={4}
                                            md={4}
                                            lg={4}
                                            xl={4}
                                            xxl={4}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-center",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          >
                                            <Form.Item className="kFormItem" name="menuyetki_678905_value">
                                              <Switch
                                                id="678905"
                                                onChange={(e?: any) => {
                                                  if (e && e.stopPropagation) e.stopPropagation();
                                                  this.MenuYetkiComponent_678905_onChange();
                                                }}
                                                visibility={this.state.isComp678905Visible}
                                                visibilityCondition={(rowData: object) =>
                                                  ReactSystemFunctions.isEqualTo(
                                                    this.state.RoleMenuGoster[0]?.m061?.toString(),
                                                    "1"
                                                  )
                                                }
                                                disabled={false}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              ></Switch>
                                            </Form.Item>
                                          </KCol>
                                        </KRow>

                                        <KRow
                                          id="446418"
                                          visibility={this.state.isCompmenuyetki_446418AuthorizationVisible}
                                          visibilityByAuthorization={
                                            this.state.isCompmenuyetki_446418AuthorizationVisible
                                          }
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderTopWidth: 1,
                                              borderColor: "rgba(244, 244, 244, 1)",
                                              borderStyle: "solid",
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "flex-start",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="885527"
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            xxl={12}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 36,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          >
                                            <HorizontalStack
                                              id="578564"
                                              direction="horizontal"
                                              size={12}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(22, 48, 32, 1)"
                                                } as any
                                              }
                                            >
                                              <Form.Item className="kFormItem" name="menuyetki_981392_value">
                                                <Image
                                                  id="981392"
                                                  zoomOnClick={false}
                                                  imageFit="fit"
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      width: "24px",
                                                      height: "24px",
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      display: "block",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                ></Image>
                                              </Form.Item>

                                              <Label
                                                id="616724"
                                                value={ReactSystemFunctions.translate(
                                                  this.ml,
                                                  616724,
                                                  "value",
                                                  this.defaultML
                                                )}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 400,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              ></Label>
                                            </HorizontalStack>
                                          </KCol>

                                          <KCol
                                            id="580649"
                                            xs={4}
                                            sm={4}
                                            md={4}
                                            lg={4}
                                            xl={4}
                                            xxl={4}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          ></KCol>

                                          <KCol
                                            id="14901"
                                            xs={4}
                                            sm={4}
                                            md={4}
                                            lg={4}
                                            xl={4}
                                            xxl={4}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-center",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          >
                                            <Form.Item className="kFormItem" name="menuyetki_404080_value">
                                              <Switch
                                                id="404080"
                                                onChange={(e?: any) => {
                                                  if (e && e.stopPropagation) e.stopPropagation();
                                                  this.MenuYetkiComponent_404080_onChange();
                                                }}
                                                visibility={this.state.isComp404080Visible}
                                                visibilityCondition={(rowData: object) =>
                                                  ReactSystemFunctions.isNotEqualTo(
                                                    this.state.RoleSadeceGoruntule[0]?.sg062?.toString(),
                                                    "1"
                                                  )
                                                }
                                                editability={this.state.isComp404080Enabled}
                                                editabilityCondition={(rowData: object) =>
                                                  ReactSystemFunctions.isEqualTo(
                                                    this.state.RoleMenuGoster[0]?.m006?.toString(),
                                                    "1"
                                                  )
                                                }
                                                disabled={false}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              ></Switch>
                                            </Form.Item>
                                          </KCol>

                                          <KCol
                                            id="975282"
                                            xs={4}
                                            sm={4}
                                            md={4}
                                            lg={4}
                                            xl={4}
                                            xxl={4}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-center",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          >
                                            <Form.Item className="kFormItem" name="menuyetki_357083_value">
                                              <Switch
                                                id="357083"
                                                onChange={(e?: any) => {
                                                  if (e && e.stopPropagation) e.stopPropagation();
                                                  this.MenuYetkiComponent_357083_onChange();
                                                }}
                                                visibility={this.state.isComp357083Visible}
                                                visibilityCondition={(rowData: object) =>
                                                  ReactSystemFunctions.isEqualTo(
                                                    this.state.RoleMenuGoster[0]?.m062?.toString(),
                                                    "1"
                                                  )
                                                }
                                                disabled={false}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              ></Switch>
                                            </Form.Item>
                                          </KCol>
                                        </KRow>

                                        <KRow
                                          id="997757"
                                          visibility={
                                            this.state.isComp997757Visible == "hidden" ||
                                            this.state.isCompmenuyetki_997757AuthorizationVisible == "hidden"
                                              ? "hidden"
                                              : "visible"
                                          }
                                          visibilityByAuthorization={
                                            this.state.isCompmenuyetki_997757AuthorizationVisible == "hidden"
                                              ? "hidden"
                                              : "visible"
                                          }
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderTopWidth: 1,
                                              borderColor: "rgba(244, 244, 244, 1)",
                                              borderStyle: "solid",
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(24, 144, 255, 0.05)",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              alignItems: "flex-start",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="273159"
                                            xs={9}
                                            sm={9}
                                            md={9}
                                            lg={9}
                                            xl={9}
                                            xxl={9}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          >
                                            <HorizontalStack
                                              id="481894"
                                              direction="horizontal"
                                              size={12}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(22, 48, 32, 1)"
                                                } as any
                                              }
                                            >
                                              <Form.Item className="kFormItem" name="menuyetki_743064_value">
                                                <Image
                                                  id="743064"
                                                  zoomOnClick={false}
                                                  imageFit="fit"
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      width: "24px",
                                                      height: "24px",
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      display: "block",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                ></Image>
                                              </Form.Item>

                                              <Label
                                                id="645789"
                                                value={ReactSystemFunctions.translate(
                                                  this.ml,
                                                  645789,
                                                  "value",
                                                  this.defaultML
                                                )}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    alignItems: "center",
                                                    textAlign: "-webkit-left",
                                                    display: "inline",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              ></Label>
                                            </HorizontalStack>
                                          </KCol>

                                          <KCol
                                            id="463938"
                                            xs={3}
                                            sm={3}
                                            md={3}
                                            lg={3}
                                            xl={3}
                                            xxl={3}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-right",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="521233"
                                              visibility={this.state.isComp521233Visible}
                                              iconName="keyboard_arrow_down"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(250, 173, 20, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>

                                            <Icon
                                              id="252253"
                                              visibility={this.state.isComp252253Visible}
                                              iconName="keyboard_arrow_up"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "20px",
                                                  color: "rgba(48, 77, 48, 1)",
                                                  letterSpacing: "1px"
                                                } as any
                                              }
                                            ></Icon>
                                          </KCol>

                                          <KCol
                                            id="291280"
                                            xs={4}
                                            sm={4}
                                            md={4}
                                            lg={4}
                                            xl={4}
                                            xxl={4}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          ></KCol>

                                          <KCol
                                            id="298093"
                                            xs={4}
                                            sm={4}
                                            md={4}
                                            lg={4}
                                            xl={4}
                                            xxl={4}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-center",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          >
                                            <Form.Item className="kFormItem" name="menuyetki_203639_value">
                                              <Switch
                                                id="203639"
                                                onChange={(e?: any) => {
                                                  if (e && e.stopPropagation) e.stopPropagation();
                                                  this.MenuYetkiComponent_203639_onChange();
                                                }}
                                                disabled={false}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    boxShadow: "2px 4px 3px 0px rgba(0, 167, 229, 1)",
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              ></Switch>
                                            </Form.Item>
                                          </KCol>

                                          <KCol
                                            id="890758"
                                            xs={4}
                                            sm={4}
                                            md={4}
                                            lg={4}
                                            xl={4}
                                            xxl={4}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 8,
                                                paddingRight: 8,
                                                paddingBottom: 8,
                                                paddingLeft: 8,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-center",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          >
                                            <Form.Item className="kFormItem" name="menuyetki_514287_value">
                                              <Switch
                                                id="514287"
                                                onChange={(e?: any) => {
                                                  if (e && e.stopPropagation) e.stopPropagation();
                                                  this.MenuYetkiComponent_514287_onChange();
                                                }}
                                                visibility={this.state.isComp514287Visible}
                                                disabled={false}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              ></Switch>
                                            </Form.Item>
                                          </KCol>
                                        </KRow>

                                        <KRow
                                          id="835245"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "flex-start",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(22, 48, 32, 1)"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="327663"
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={24}
                                            xl={24}
                                            xxl={24}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                alignItems: "flex-start",
                                                textAlign: "-webkit-left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(22, 48, 32, 1)"
                                              } as any
                                            }
                                          >
                                            <KRow
                                              id="166669"
                                              visibility={this.state.isCompmenuyetki_166669AuthorizationVisible}
                                              visibilityByAuthorization={
                                                this.state.isCompmenuyetki_166669AuthorizationVisible
                                              }
                                              align="top"
                                              justify="start"
                                              horizontalGutter={0}
                                              verticalGutter={0}
                                              style={
                                                {
                                                  borderTopWidth: 1,
                                                  borderColor: "rgba(244, 244, 244, 1)",
                                                  borderStyle: "solid",
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "flex-start",
                                                  textAlign: "-webkit-left",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(22, 48, 32, 1)"
                                                } as any
                                              }
                                            >
                                              <KCol
                                                id="54683"
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                xxl={12}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 36,
                                                    alignItems: "flex-start",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              >
                                                <HorizontalStack
                                                  id="642823"
                                                  direction="horizontal"
                                                  size={12}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      alignItems: "center",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <Form.Item className="kFormItem" name="menuyetki_301700_value">
                                                    <Image
                                                      id="301700"
                                                      zoomOnClick={false}
                                                      imageFit="fit"
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingRight: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 0,
                                                          width: "24px",
                                                          height: "24px",
                                                          alignItems: "center",
                                                          textAlign: "-webkit-left",
                                                          display: "block",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    ></Image>
                                                  </Form.Item>

                                                  <Label
                                                    id="656901"
                                                    value={ReactSystemFunctions.translate(
                                                      this.ml,
                                                      656901,
                                                      "value",
                                                      this.defaultML
                                                    )}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 400,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </HorizontalStack>
                                              </KCol>

                                              <KCol
                                                id="364922"
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                lg={4}
                                                xl={4}
                                                xxl={4}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    alignItems: "flex-start",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              ></KCol>

                                              <KCol
                                                id="430778"
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                lg={4}
                                                xl={4}
                                                xxl={4}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    alignItems: "flex-start",
                                                    textAlign: "-webkit-center",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              >
                                                <Form.Item className="kFormItem" name="menuyetki_198856_value">
                                                  <Switch
                                                    id="198856"
                                                    onChange={(e?: any) => {
                                                      if (e && e.stopPropagation) e.stopPropagation();
                                                      this.MenuYetkiComponent_198856_onChange();
                                                    }}
                                                    visibility={this.state.isComp198856Visible}
                                                    visibilityCondition={(rowData: object) =>
                                                      ReactSystemFunctions.isNotEqualTo(
                                                        this.state.RoleSadeceGoruntule[0]?.sg071?.toString(),
                                                        "1"
                                                      )
                                                    }
                                                    editability={this.state.isComp198856Enabled}
                                                    editabilityCondition={(rowData: object) =>
                                                      ReactSystemFunctions.isEqualTo(
                                                        this.state.RoleMenuGoster[0]?.m007?.toString(),
                                                        "1"
                                                      )
                                                    }
                                                    disabled={false}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Switch>
                                                </Form.Item>
                                              </KCol>

                                              <KCol
                                                id="611494"
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                lg={4}
                                                xl={4}
                                                xxl={4}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    alignItems: "flex-start",
                                                    textAlign: "-webkit-center",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              >
                                                <Form.Item className="kFormItem" name="menuyetki_289551_value">
                                                  <Switch
                                                    id="289551"
                                                    onChange={(e?: any) => {
                                                      if (e && e.stopPropagation) e.stopPropagation();
                                                      this.MenuYetkiComponent_289551_onChange();
                                                    }}
                                                    visibility={this.state.isComp289551Visible}
                                                    visibilityCondition={(rowData: object) =>
                                                      ReactSystemFunctions.isEqualTo(
                                                        this.state.RoleMenuGoster[0]?.m071?.toString(),
                                                        "1"
                                                      )
                                                    }
                                                    disabled={false}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Switch>
                                                </Form.Item>
                                              </KCol>
                                            </KRow>

                                            <KRow
                                              id="122527"
                                              visibility={this.state.isCompmenuyetki_122527AuthorizationVisible}
                                              visibilityByAuthorization={
                                                this.state.isCompmenuyetki_122527AuthorizationVisible
                                              }
                                              align="top"
                                              justify="start"
                                              horizontalGutter={0}
                                              verticalGutter={0}
                                              style={
                                                {
                                                  borderTopWidth: 1,
                                                  borderColor: "rgba(244, 244, 244, 1)",
                                                  borderStyle: "solid",
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "flex-start",
                                                  textAlign: "-webkit-left",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(22, 48, 32, 1)"
                                                } as any
                                              }
                                            >
                                              <KCol
                                                id="317403"
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                xxl={12}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 36,
                                                    alignItems: "flex-start",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              >
                                                <HorizontalStack
                                                  id="790076"
                                                  direction="horizontal"
                                                  size={12}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      alignItems: "center",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <Form.Item className="kFormItem" name="menuyetki_966850_value">
                                                    <Image
                                                      id="966850"
                                                      zoomOnClick={false}
                                                      imageFit="fit"
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingRight: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 0,
                                                          width: "24px",
                                                          height: "24px",
                                                          alignItems: "center",
                                                          textAlign: "-webkit-left",
                                                          display: "block",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    ></Image>
                                                  </Form.Item>

                                                  <Label
                                                    id="897638"
                                                    value={ReactSystemFunctions.translate(
                                                      this.ml,
                                                      897638,
                                                      "value",
                                                      this.defaultML
                                                    )}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 400,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </HorizontalStack>
                                              </KCol>

                                              <KCol
                                                id="899221"
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                lg={4}
                                                xl={4}
                                                xxl={4}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    alignItems: "flex-start",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              ></KCol>

                                              <KCol
                                                id="292019"
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                lg={4}
                                                xl={4}
                                                xxl={4}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    alignItems: "flex-start",
                                                    textAlign: "-webkit-center",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              >
                                                <Form.Item className="kFormItem" name="menuyetki_225717_value">
                                                  <Switch
                                                    id="225717"
                                                    onChange={(e?: any) => {
                                                      if (e && e.stopPropagation) e.stopPropagation();
                                                      this.MenuYetkiComponent_225717_onChange();
                                                    }}
                                                    visibility={this.state.isComp225717Visible}
                                                    visibilityCondition={(rowData: object) =>
                                                      ReactSystemFunctions.isNotEqualTo(
                                                        this.state.RoleSadeceGoruntule[0]?.sg072?.toString(),
                                                        "1"
                                                      )
                                                    }
                                                    editability={this.state.isComp225717Enabled}
                                                    editabilityCondition={(rowData: object) =>
                                                      ReactSystemFunctions.isEqualTo(
                                                        this.state.RoleMenuGoster[0]?.m007?.toString(),
                                                        "1"
                                                      )
                                                    }
                                                    disabled={false}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Switch>
                                                </Form.Item>
                                              </KCol>

                                              <KCol
                                                id="66246"
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                lg={4}
                                                xl={4}
                                                xxl={4}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    alignItems: "flex-start",
                                                    textAlign: "-webkit-center",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              >
                                                <Form.Item className="kFormItem" name="menuyetki_452224_value">
                                                  <Switch
                                                    id="452224"
                                                    onChange={(e?: any) => {
                                                      if (e && e.stopPropagation) e.stopPropagation();
                                                      this.MenuYetkiComponent_452224_onChange();
                                                    }}
                                                    visibility={this.state.isComp452224Visible}
                                                    visibilityCondition={(rowData: object) =>
                                                      ReactSystemFunctions.isEqualTo(
                                                        this.state.RoleMenuGoster[0]?.m072?.toString(),
                                                        "1"
                                                      )
                                                    }
                                                    disabled={false}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Switch>
                                                </Form.Item>
                                              </KCol>
                                            </KRow>

                                            <KRow
                                              id="228966"
                                              visibility={
                                                this.state.isComp228966Visible == "hidden" ||
                                                this.state.isCompmenuyetki_228966AuthorizationVisible == "hidden"
                                                  ? "hidden"
                                                  : "visible"
                                              }
                                              visibilityByAuthorization={
                                                this.state.isCompmenuyetki_228966AuthorizationVisible == "hidden"
                                                  ? "hidden"
                                                  : "visible"
                                              }
                                              align="top"
                                              justify="start"
                                              horizontalGutter={0}
                                              verticalGutter={0}
                                              style={
                                                {
                                                  borderTopWidth: 1,
                                                  borderColor: "rgba(244, 244, 244, 1)",
                                                  borderStyle: "solid",
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundColor: "rgba(24, 144, 255, 0.05)",
                                                  paddingTop: 8,
                                                  paddingRight: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  alignItems: "flex-start",
                                                  textAlign: "-webkit-left",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(22, 48, 32, 1)"
                                                } as any
                                              }
                                            >
                                              <KCol
                                                id="317112"
                                                xs={9}
                                                sm={9}
                                                md={9}
                                                lg={9}
                                                xl={9}
                                                xxl={9}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    alignItems: "flex-start",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              >
                                                <HorizontalStack
                                                  id="122905"
                                                  direction="horizontal"
                                                  size={12}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      alignItems: "center",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <Form.Item className="kFormItem" name="menuyetki_78630_value">
                                                    <Image
                                                      id="78630"
                                                      zoomOnClick={false}
                                                      imageFit="fit"
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingRight: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 0,
                                                          width: "24px",
                                                          height: "24px",
                                                          alignItems: "center",
                                                          textAlign: "-webkit-left",
                                                          display: "block",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    ></Image>
                                                  </Form.Item>

                                                  <Label
                                                    id="635051"
                                                    value={ReactSystemFunctions.translate(
                                                      this.ml,
                                                      635051,
                                                      "value",
                                                      this.defaultML
                                                    )}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "center",
                                                        textAlign: "-webkit-left",
                                                        display: "inline",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Label>
                                                </HorizontalStack>
                                              </KCol>

                                              <KCol
                                                id="918141"
                                                xs={3}
                                                sm={3}
                                                md={3}
                                                lg={3}
                                                xl={3}
                                                xxl={3}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    alignItems: "flex-start",
                                                    textAlign: "-webkit-right",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              >
                                                <Icon
                                                  id="256986"
                                                  visibility={this.state.isComp256986Visible}
                                                  iconName="keyboard_arrow_down"
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      display: "inline",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "20px",
                                                      color: "rgba(250, 173, 20, 1)",
                                                      letterSpacing: "1px"
                                                    } as any
                                                  }
                                                ></Icon>

                                                <Icon
                                                  id="683617"
                                                  visibility={this.state.isComp683617Visible}
                                                  iconName="keyboard_arrow_up"
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      alignItems: "center",
                                                      textAlign: "-webkit-left",
                                                      display: "inline",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "20px",
                                                      color: "rgba(48, 77, 48, 1)",
                                                      letterSpacing: "1px"
                                                    } as any
                                                  }
                                                ></Icon>
                                              </KCol>

                                              <KCol
                                                id="468341"
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                lg={4}
                                                xl={4}
                                                xxl={4}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    alignItems: "flex-start",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              ></KCol>

                                              <KCol
                                                id="960921"
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                lg={4}
                                                xl={4}
                                                xxl={4}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    alignItems: "flex-start",
                                                    textAlign: "-webkit-center",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              >
                                                <Form.Item className="kFormItem" name="menuyetki_501768_value">
                                                  <Switch
                                                    id="501768"
                                                    onChange={(e?: any) => {
                                                      if (e && e.stopPropagation) e.stopPropagation();
                                                      this.MenuYetkiComponent_501768_onChange();
                                                    }}
                                                    disabled={false}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        boxShadow: "2px 4px 3px 0px rgba(0, 167, 229, 1)",
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Switch>
                                                </Form.Item>
                                              </KCol>

                                              <KCol
                                                id="861167"
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                lg={4}
                                                xl={4}
                                                xxl={4}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 8,
                                                    paddingRight: 8,
                                                    paddingBottom: 8,
                                                    paddingLeft: 8,
                                                    alignItems: "flex-start",
                                                    textAlign: "-webkit-center",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              >
                                                <Form.Item className="kFormItem" name="menuyetki_569419_value">
                                                  <Switch
                                                    id="569419"
                                                    onChange={(e?: any) => {
                                                      if (e && e.stopPropagation) e.stopPropagation();
                                                      this.MenuYetkiComponent_569419_onChange();
                                                    }}
                                                    visibility={this.state.isComp569419Visible}
                                                    disabled={false}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></Switch>
                                                </Form.Item>
                                              </KCol>
                                            </KRow>

                                            <KRow
                                              id="106170"
                                              align="top"
                                              justify="start"
                                              horizontalGutter={0}
                                              verticalGutter={0}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  alignItems: "flex-start",
                                                  textAlign: "-webkit-left",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(22, 48, 32, 1)"
                                                } as any
                                              }
                                            >
                                              <KCol
                                                id="699823"
                                                xs={24}
                                                sm={24}
                                                md={24}
                                                lg={24}
                                                xl={24}
                                                xxl={24}
                                                order={0}
                                                pull={0}
                                                push={0}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    alignItems: "flex-start",
                                                    textAlign: "-webkit-left",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(22, 48, 32, 1)"
                                                  } as any
                                                }
                                              >
                                                <KRow
                                                  id="331618"
                                                  visibility={this.state.isCompmenuyetki_331618AuthorizationVisible}
                                                  visibilityByAuthorization={
                                                    this.state.isCompmenuyetki_331618AuthorizationVisible
                                                  }
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderTopWidth: 1,
                                                      borderColor: "rgba(244, 244, 244, 1)",
                                                      borderStyle: "solid",
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      alignItems: "flex-start",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <KCol
                                                    id="426317"
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                    xxl={12}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 8,
                                                        paddingRight: 8,
                                                        paddingBottom: 8,
                                                        paddingLeft: 36,
                                                        alignItems: "flex-start",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  >
                                                    <HorizontalStack
                                                      id="956775"
                                                      direction="horizontal"
                                                      size={12}
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingRight: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 0,
                                                          alignItems: "center",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    >
                                                      <Form.Item className="kFormItem" name="menuyetki_574419_value">
                                                        <Image
                                                          id="574419"
                                                          zoomOnClick={false}
                                                          imageFit="fit"
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 0,
                                                              paddingRight: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 0,
                                                              width: "24px",
                                                              height: "24px",
                                                              alignItems: "center",
                                                              textAlign: "-webkit-left",
                                                              display: "block",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 48, 32, 1)"
                                                            } as any
                                                          }
                                                        ></Image>
                                                      </Form.Item>

                                                      <Label
                                                        id="974938"
                                                        value={ReactSystemFunctions.translate(
                                                          this.ml,
                                                          974938,
                                                          "value",
                                                          this.defaultML
                                                        )}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 0,
                                                            paddingRight: 0,
                                                            paddingBottom: 0,
                                                            paddingLeft: 0,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            display: "inline",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 400,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></Label>
                                                    </HorizontalStack>
                                                  </KCol>

                                                  <KCol
                                                    id="162234"
                                                    xs={4}
                                                    sm={4}
                                                    md={4}
                                                    lg={4}
                                                    xl={4}
                                                    xxl={4}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 8,
                                                        paddingRight: 8,
                                                        paddingBottom: 8,
                                                        paddingLeft: 8,
                                                        alignItems: "flex-start",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></KCol>

                                                  <KCol
                                                    id="653540"
                                                    xs={4}
                                                    sm={4}
                                                    md={4}
                                                    lg={4}
                                                    xl={4}
                                                    xxl={4}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 8,
                                                        paddingRight: 8,
                                                        paddingBottom: 8,
                                                        paddingLeft: 8,
                                                        alignItems: "flex-start",
                                                        textAlign: "-webkit-center",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  >
                                                    <Form.Item className="kFormItem" name="menuyetki_774525_value">
                                                      <Switch
                                                        id="774525"
                                                        onChange={(e?: any) => {
                                                          if (e && e.stopPropagation) e.stopPropagation();
                                                          this.MenuYetkiComponent_774525_onChange();
                                                        }}
                                                        visibility={this.state.isComp774525Visible}
                                                        visibilityCondition={(rowData: object) =>
                                                          ReactSystemFunctions.isNotEqualTo(
                                                            this.state.RoleSadeceGoruntule[0]?.sg081?.toString(),
                                                            "1"
                                                          )
                                                        }
                                                        editability={this.state.isComp774525Enabled}
                                                        editabilityCondition={(rowData: object) =>
                                                          ReactSystemFunctions.isEqualTo(
                                                            this.state.RoleMenuGoster[0]?.m008?.toString(),
                                                            "1"
                                                          )
                                                        }
                                                        disabled={false}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 0,
                                                            paddingRight: 0,
                                                            paddingBottom: 0,
                                                            paddingLeft: 0,
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></Switch>
                                                    </Form.Item>
                                                  </KCol>

                                                  <KCol
                                                    id="453455"
                                                    xs={4}
                                                    sm={4}
                                                    md={4}
                                                    lg={4}
                                                    xl={4}
                                                    xxl={4}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 8,
                                                        paddingRight: 8,
                                                        paddingBottom: 8,
                                                        paddingLeft: 8,
                                                        alignItems: "flex-start",
                                                        textAlign: "-webkit-center",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  >
                                                    <Form.Item className="kFormItem" name="menuyetki_997515_value">
                                                      <Switch
                                                        id="997515"
                                                        onChange={(e?: any) => {
                                                          if (e && e.stopPropagation) e.stopPropagation();
                                                          this.MenuYetkiComponent_997515_onChange();
                                                        }}
                                                        visibility={this.state.isComp997515Visible}
                                                        visibilityCondition={(rowData: object) =>
                                                          ReactSystemFunctions.isEqualTo(
                                                            this.state.RoleMenuGoster[0]?.m081?.toString(),
                                                            "1"
                                                          )
                                                        }
                                                        disabled={false}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 0,
                                                            paddingRight: 0,
                                                            paddingBottom: 0,
                                                            paddingLeft: 0,
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></Switch>
                                                    </Form.Item>
                                                  </KCol>
                                                </KRow>

                                                <KRow
                                                  id="853472"
                                                  visibility={this.state.isCompmenuyetki_853472AuthorizationVisible}
                                                  visibilityByAuthorization={
                                                    this.state.isCompmenuyetki_853472AuthorizationVisible
                                                  }
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderTopWidth: 1,
                                                      borderColor: "rgba(244, 244, 244, 1)",
                                                      borderStyle: "solid",
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      alignItems: "flex-start",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <KCol
                                                    id="470465"
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                    xxl={12}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 8,
                                                        paddingRight: 8,
                                                        paddingBottom: 8,
                                                        paddingLeft: 36,
                                                        alignItems: "flex-start",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  >
                                                    <HorizontalStack
                                                      id="244789"
                                                      direction="horizontal"
                                                      size={12}
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingRight: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 0,
                                                          alignItems: "center",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    >
                                                      <Form.Item className="kFormItem" name="menuyetki_938245_value">
                                                        <Image
                                                          id="938245"
                                                          zoomOnClick={false}
                                                          imageFit="fit"
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 0,
                                                              paddingRight: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 0,
                                                              width: "24px",
                                                              height: "24px",
                                                              alignItems: "center",
                                                              textAlign: "-webkit-left",
                                                              display: "block",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 48, 32, 1)"
                                                            } as any
                                                          }
                                                        ></Image>
                                                      </Form.Item>

                                                      <Label
                                                        id="344524"
                                                        value={ReactSystemFunctions.translate(
                                                          this.ml,
                                                          344524,
                                                          "value",
                                                          this.defaultML
                                                        )}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 0,
                                                            paddingRight: 0,
                                                            paddingBottom: 0,
                                                            paddingLeft: 0,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            display: "inline",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 400,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></Label>
                                                    </HorizontalStack>
                                                  </KCol>

                                                  <KCol
                                                    id="98483"
                                                    xs={4}
                                                    sm={4}
                                                    md={4}
                                                    lg={4}
                                                    xl={4}
                                                    xxl={4}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 8,
                                                        paddingRight: 8,
                                                        paddingBottom: 8,
                                                        paddingLeft: 8,
                                                        alignItems: "flex-start",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></KCol>

                                                  <KCol
                                                    id="348209"
                                                    xs={4}
                                                    sm={4}
                                                    md={4}
                                                    lg={4}
                                                    xl={4}
                                                    xxl={4}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 8,
                                                        paddingRight: 8,
                                                        paddingBottom: 8,
                                                        paddingLeft: 8,
                                                        alignItems: "flex-start",
                                                        textAlign: "-webkit-center",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  >
                                                    <Form.Item className="kFormItem" name="menuyetki_283222_value">
                                                      <Switch
                                                        id="283222"
                                                        onChange={(e?: any) => {
                                                          if (e && e.stopPropagation) e.stopPropagation();
                                                          this.MenuYetkiComponent_283222_onChange();
                                                        }}
                                                        visibility={this.state.isComp283222Visible}
                                                        visibilityCondition={(rowData: object) =>
                                                          ReactSystemFunctions.isNotEqualTo(
                                                            this.state.RoleSadeceGoruntule[0]?.sg082?.toString(),
                                                            "1"
                                                          )
                                                        }
                                                        editability={this.state.isComp283222Enabled}
                                                        editabilityCondition={(rowData: object) =>
                                                          ReactSystemFunctions.isEqualTo(
                                                            this.state.RoleMenuGoster[0]?.m008?.toString(),
                                                            "1"
                                                          )
                                                        }
                                                        disabled={false}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 0,
                                                            paddingRight: 0,
                                                            paddingBottom: 0,
                                                            paddingLeft: 0,
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></Switch>
                                                    </Form.Item>
                                                  </KCol>

                                                  <KCol
                                                    id="46980"
                                                    xs={4}
                                                    sm={4}
                                                    md={4}
                                                    lg={4}
                                                    xl={4}
                                                    xxl={4}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 8,
                                                        paddingRight: 8,
                                                        paddingBottom: 8,
                                                        paddingLeft: 8,
                                                        alignItems: "flex-start",
                                                        textAlign: "-webkit-center",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  >
                                                    <Form.Item className="kFormItem" name="menuyetki_725313_value">
                                                      <Switch
                                                        id="725313"
                                                        onChange={(e?: any) => {
                                                          if (e && e.stopPropagation) e.stopPropagation();
                                                          this.MenuYetkiComponent_725313_onChange();
                                                        }}
                                                        visibility={this.state.isComp725313Visible}
                                                        visibilityCondition={(rowData: object) =>
                                                          ReactSystemFunctions.isEqualTo(
                                                            this.state.RoleMenuGoster[0]?.m082?.toString(),
                                                            "1"
                                                          )
                                                        }
                                                        disabled={false}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 0,
                                                            paddingRight: 0,
                                                            paddingBottom: 0,
                                                            paddingLeft: 0,
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></Switch>
                                                    </Form.Item>
                                                  </KCol>
                                                </KRow>

                                                <KRow
                                                  id="713421"
                                                  visibility={
                                                    this.state.isComp713421Visible == "hidden" ||
                                                    this.state.isCompmenuyetki_713421AuthorizationVisible == "hidden"
                                                      ? "hidden"
                                                      : "visible"
                                                  }
                                                  visibilityByAuthorization={
                                                    this.state.isCompmenuyetki_713421AuthorizationVisible == "hidden"
                                                      ? "hidden"
                                                      : "visible"
                                                  }
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderTopWidth: 1,
                                                      borderColor: "rgba(244, 244, 244, 1)",
                                                      borderStyle: "solid",
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      backgroundColor: "rgba(24, 144, 255, 0.05)",
                                                      paddingTop: 8,
                                                      paddingRight: 8,
                                                      paddingBottom: 8,
                                                      paddingLeft: 8,
                                                      alignItems: "flex-start",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <KCol
                                                    id="505830"
                                                    xs={9}
                                                    sm={9}
                                                    md={9}
                                                    lg={9}
                                                    xl={9}
                                                    xxl={9}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 8,
                                                        paddingRight: 8,
                                                        paddingBottom: 8,
                                                        paddingLeft: 8,
                                                        alignItems: "flex-start",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  >
                                                    <HorizontalStack
                                                      id="248474"
                                                      direction="horizontal"
                                                      size={12}
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingRight: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 0,
                                                          alignItems: "center",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    >
                                                      <Form.Item className="kFormItem" name="menuyetki_443063_value">
                                                        <Image
                                                          id="443063"
                                                          zoomOnClick={false}
                                                          imageFit="fit"
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 0,
                                                              paddingRight: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 0,
                                                              width: "24px",
                                                              height: "24px",
                                                              alignItems: "center",
                                                              textAlign: "-webkit-left",
                                                              display: "block",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 48, 32, 1)"
                                                            } as any
                                                          }
                                                        ></Image>
                                                      </Form.Item>

                                                      <Label
                                                        id="525983"
                                                        value={ReactSystemFunctions.translate(
                                                          this.ml,
                                                          525983,
                                                          "value",
                                                          this.defaultML
                                                        )}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 0,
                                                            paddingRight: 0,
                                                            paddingBottom: 0,
                                                            paddingLeft: 0,
                                                            alignItems: "center",
                                                            textAlign: "-webkit-left",
                                                            display: "inline",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></Label>
                                                    </HorizontalStack>
                                                  </KCol>

                                                  <KCol
                                                    id="972770"
                                                    xs={3}
                                                    sm={3}
                                                    md={3}
                                                    lg={3}
                                                    xl={3}
                                                    xxl={3}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 8,
                                                        paddingRight: 8,
                                                        paddingBottom: 8,
                                                        paddingLeft: 8,
                                                        alignItems: "flex-start",
                                                        textAlign: "-webkit-right",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  >
                                                    <Icon
                                                      id="496085"
                                                      visibility={this.state.isComp496085Visible}
                                                      iconName="keyboard_arrow_down"
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingRight: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 0,
                                                          alignItems: "center",
                                                          textAlign: "-webkit-left",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "20px",
                                                          color: "rgba(250, 173, 20, 1)",
                                                          letterSpacing: "1px"
                                                        } as any
                                                      }
                                                    ></Icon>

                                                    <Icon
                                                      id="82614"
                                                      visibility={this.state.isComp82614Visible}
                                                      iconName="keyboard_arrow_up"
                                                      style={
                                                        {
                                                          borderStyle: "solid",
                                                          borderTopWidth: 0,
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 0,
                                                          paddingRight: 0,
                                                          paddingBottom: 0,
                                                          paddingLeft: 0,
                                                          alignItems: "center",
                                                          textAlign: "-webkit-left",
                                                          display: "inline",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "20px",
                                                          color: "rgba(48, 77, 48, 1)",
                                                          letterSpacing: "1px"
                                                        } as any
                                                      }
                                                    ></Icon>
                                                  </KCol>

                                                  <KCol
                                                    id="130576"
                                                    xs={4}
                                                    sm={4}
                                                    md={4}
                                                    lg={4}
                                                    xl={4}
                                                    xxl={4}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 8,
                                                        paddingRight: 8,
                                                        paddingBottom: 8,
                                                        paddingLeft: 8,
                                                        alignItems: "flex-start",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  ></KCol>

                                                  <KCol
                                                    id="205668"
                                                    xs={4}
                                                    sm={4}
                                                    md={4}
                                                    lg={4}
                                                    xl={4}
                                                    xxl={4}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 8,
                                                        paddingRight: 8,
                                                        paddingBottom: 8,
                                                        paddingLeft: 8,
                                                        alignItems: "flex-start",
                                                        textAlign: "-webkit-center",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  >
                                                    <Form.Item className="kFormItem" name="menuyetki_652816_value">
                                                      <Switch
                                                        id="652816"
                                                        onChange={(e?: any) => {
                                                          if (e && e.stopPropagation) e.stopPropagation();
                                                          this.MenuYetkiComponent_652816_onChange();
                                                        }}
                                                        disabled={false}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            boxShadow: "2px 4px 3px 0px rgba(0, 167, 229, 1)",
                                                            paddingTop: 0,
                                                            paddingRight: 0,
                                                            paddingBottom: 0,
                                                            paddingLeft: 0,
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></Switch>
                                                    </Form.Item>
                                                  </KCol>

                                                  <KCol
                                                    id="173302"
                                                    xs={4}
                                                    sm={4}
                                                    md={4}
                                                    lg={4}
                                                    xl={4}
                                                    xxl={4}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 8,
                                                        paddingRight: 8,
                                                        paddingBottom: 8,
                                                        paddingLeft: 8,
                                                        alignItems: "flex-start",
                                                        textAlign: "-webkit-center",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  >
                                                    <Form.Item className="kFormItem" name="menuyetki_789397_value">
                                                      <Switch
                                                        id="789397"
                                                        onChange={(e?: any) => {
                                                          if (e && e.stopPropagation) e.stopPropagation();
                                                          this.MenuYetkiComponent_789397_onChange();
                                                        }}
                                                        visibility={this.state.isComp789397Visible}
                                                        disabled={false}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 0,
                                                            paddingRight: 0,
                                                            paddingBottom: 0,
                                                            paddingLeft: 0,
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></Switch>
                                                    </Form.Item>
                                                  </KCol>
                                                </KRow>

                                                <KRow
                                                  id="676441"
                                                  align="top"
                                                  justify="start"
                                                  horizontalGutter={0}
                                                  verticalGutter={0}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      paddingTop: 0,
                                                      paddingRight: 0,
                                                      paddingBottom: 0,
                                                      paddingLeft: 0,
                                                      alignItems: "flex-start",
                                                      textAlign: "-webkit-left",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                      color: "rgba(22, 48, 32, 1)"
                                                    } as any
                                                  }
                                                >
                                                  <KCol
                                                    id="795327"
                                                    xs={24}
                                                    sm={24}
                                                    md={24}
                                                    lg={24}
                                                    xl={24}
                                                    xxl={24}
                                                    order={0}
                                                    pull={0}
                                                    push={0}
                                                    style={
                                                      {
                                                        borderStyle: "solid",
                                                        borderTopWidth: 0,
                                                        borderRightWidth: 0,
                                                        borderBottomWidth: 0,
                                                        borderLeftWidth: 0,
                                                        paddingTop: 0,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 0,
                                                        alignItems: "flex-start",
                                                        textAlign: "-webkit-left",
                                                        fontFamily: "Poppins",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        color: "rgba(22, 48, 32, 1)"
                                                      } as any
                                                    }
                                                  >
                                                    <KRow
                                                      id="246978"
                                                      visibility={this.state.isCompmenuyetki_246978AuthorizationVisible}
                                                      visibilityByAuthorization={
                                                        this.state.isCompmenuyetki_246978AuthorizationVisible
                                                      }
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopWidth: 1,
                                                          borderColor: "rgba(244, 244, 244, 1)",
                                                          borderStyle: "solid",
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "flex-start",
                                                          textAlign: "-webkit-left",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="578392"
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 36,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="57747"
                                                          direction="horizontal"
                                                          size={12}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 0,
                                                              paddingRight: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 0,
                                                              alignItems: "center",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 48, 32, 1)"
                                                            } as any
                                                          }
                                                        >
                                                          <Form.Item
                                                            className="kFormItem"
                                                            name="menuyetki_600416_value"
                                                          >
                                                            <Image
                                                              id="600416"
                                                              zoomOnClick={false}
                                                              imageFit="fit"
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  paddingTop: 0,
                                                                  paddingRight: 0,
                                                                  paddingBottom: 0,
                                                                  paddingLeft: 0,
                                                                  width: "24px",
                                                                  height: "24px",
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "block",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(22, 48, 32, 1)"
                                                                } as any
                                                              }
                                                            ></Image>
                                                          </Form.Item>

                                                          <Label
                                                            id="435130"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              435130,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>

                                                      <KCol
                                                        id="513200"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="869208"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_837432_value">
                                                          <Switch
                                                            id="837432"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_837432_onChange();
                                                            }}
                                                            visibility={this.state.isComp837432Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isNotEqualTo(
                                                                this.state.RoleSadeceGoruntule[0]?.sg091?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            editability={this.state.isComp837432Enabled}
                                                            editabilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m009?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>

                                                      <KCol
                                                        id="388936"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_440754_value">
                                                          <Switch
                                                            id="440754"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_440754_onChange();
                                                            }}
                                                            visibility={this.state.isComp440754Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m091?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>
                                                    </KRow>

                                                    <KRow
                                                      id="246851"
                                                      visibility={this.state.isCompmenuyetki_246851AuthorizationVisible}
                                                      visibilityByAuthorization={
                                                        this.state.isCompmenuyetki_246851AuthorizationVisible
                                                      }
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopWidth: 1,
                                                          borderColor: "rgba(244, 244, 244, 1)",
                                                          borderStyle: "solid",
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "flex-start",
                                                          textAlign: "-webkit-left",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="876259"
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 36,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="615477"
                                                          direction="horizontal"
                                                          size={12}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 0,
                                                              paddingRight: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 0,
                                                              alignItems: "center",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 48, 32, 1)"
                                                            } as any
                                                          }
                                                        >
                                                          <Form.Item
                                                            className="kFormItem"
                                                            name="menuyetki_344175_value"
                                                          >
                                                            <Image
                                                              id="344175"
                                                              zoomOnClick={false}
                                                              imageFit="fit"
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  paddingTop: 0,
                                                                  paddingRight: 0,
                                                                  paddingBottom: 0,
                                                                  paddingLeft: 0,
                                                                  width: "24px",
                                                                  height: "24px",
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "block",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(22, 48, 32, 1)"
                                                                } as any
                                                              }
                                                            ></Image>
                                                          </Form.Item>

                                                          <Label
                                                            id="981883"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              981883,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>

                                                      <KCol
                                                        id="919047"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="874652"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_319620_value">
                                                          <Switch
                                                            id="319620"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_319620_onChange();
                                                            }}
                                                            visibility={this.state.isComp319620Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isNotEqualTo(
                                                                this.state.RoleSadeceGoruntule[0]?.sg092?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            editability={this.state.isComp319620Enabled}
                                                            editabilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m009?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>

                                                      <KCol
                                                        id="793450"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_438871_value">
                                                          <Switch
                                                            id="438871"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_438871_onChange();
                                                            }}
                                                            visibility={this.state.isComp438871Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m092?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>
                                                    </KRow>

                                                    <KRow
                                                      id="336587"
                                                      visibility={this.state.isCompmenuyetki_336587AuthorizationVisible}
                                                      visibilityByAuthorization={
                                                        this.state.isCompmenuyetki_336587AuthorizationVisible
                                                      }
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopWidth: 1,
                                                          borderColor: "rgba(244, 244, 244, 1)",
                                                          borderStyle: "solid",
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "flex-start",
                                                          textAlign: "-webkit-left",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="561803"
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 36,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="449380"
                                                          direction="horizontal"
                                                          size={12}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 0,
                                                              paddingRight: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 0,
                                                              alignItems: "center",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 48, 32, 1)"
                                                            } as any
                                                          }
                                                        >
                                                          <Form.Item
                                                            className="kFormItem"
                                                            name="menuyetki_955263_value"
                                                          >
                                                            <Image
                                                              id="955263"
                                                              zoomOnClick={false}
                                                              imageFit="fit"
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  paddingTop: 0,
                                                                  paddingRight: 0,
                                                                  paddingBottom: 0,
                                                                  paddingLeft: 0,
                                                                  width: "24px",
                                                                  height: "24px",
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "block",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(22, 48, 32, 1)"
                                                                } as any
                                                              }
                                                            ></Image>
                                                          </Form.Item>

                                                          <Label
                                                            id="140116"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              140116,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>

                                                      <KCol
                                                        id="633627"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="790503"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_164532_value">
                                                          <Switch
                                                            id="164532"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_164532_onChange();
                                                            }}
                                                            visibility={this.state.isComp164532Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isNotEqualTo(
                                                                this.state.RoleSadeceGoruntule[0]?.sg093?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            editability={this.state.isComp164532Enabled}
                                                            editabilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m009?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>

                                                      <KCol
                                                        id="375320"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_440263_value">
                                                          <Switch
                                                            id="440263"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_440263_onChange();
                                                            }}
                                                            visibility={this.state.isComp440263Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m093?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>
                                                    </KRow>

                                                    <KRow
                                                      id="577042"
                                                      visibility={this.state.isCompmenuyetki_577042AuthorizationVisible}
                                                      visibilityByAuthorization={
                                                        this.state.isCompmenuyetki_577042AuthorizationVisible
                                                      }
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopWidth: 1,
                                                          borderColor: "rgba(244, 244, 244, 1)",
                                                          borderStyle: "solid",
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "flex-start",
                                                          textAlign: "-webkit-left",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="239148"
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 36,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="783873"
                                                          direction="horizontal"
                                                          size={12}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 0,
                                                              paddingRight: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 0,
                                                              alignItems: "center",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 48, 32, 1)"
                                                            } as any
                                                          }
                                                        >
                                                          <Form.Item
                                                            className="kFormItem"
                                                            name="menuyetki_160145_value"
                                                          >
                                                            <Image
                                                              id="160145"
                                                              zoomOnClick={false}
                                                              imageFit="fit"
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  paddingTop: 0,
                                                                  paddingRight: 0,
                                                                  paddingBottom: 0,
                                                                  paddingLeft: 0,
                                                                  width: "24px",
                                                                  height: "24px",
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "block",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(22, 48, 32, 1)"
                                                                } as any
                                                              }
                                                            ></Image>
                                                          </Form.Item>

                                                          <Label
                                                            id="396221"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              396221,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>

                                                      <KCol
                                                        id="758404"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="227958"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_734041_value">
                                                          <Switch
                                                            id="734041"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_734041_onChange();
                                                            }}
                                                            visibility={this.state.isComp734041Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isNotEqualTo(
                                                                this.state.RoleSadeceGoruntule[0]?.sg094?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            editability={this.state.isComp734041Enabled}
                                                            editabilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m009?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>

                                                      <KCol
                                                        id="254740"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_54846_value">
                                                          <Switch
                                                            id="54846"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_54846_onChange();
                                                            }}
                                                            visibility={this.state.isComp54846Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m094?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>
                                                    </KRow>

                                                    <KRow
                                                      id="52402"
                                                      visibility={this.state.isCompmenuyetki_52402AuthorizationVisible}
                                                      visibilityByAuthorization={
                                                        this.state.isCompmenuyetki_52402AuthorizationVisible
                                                      }
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopWidth: 1,
                                                          borderColor: "rgba(244, 244, 244, 1)",
                                                          borderStyle: "solid",
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "flex-start",
                                                          textAlign: "-webkit-left",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="363032"
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 36,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="104744"
                                                          direction="horizontal"
                                                          size={12}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 0,
                                                              paddingRight: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 0,
                                                              alignItems: "center",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 48, 32, 1)"
                                                            } as any
                                                          }
                                                        >
                                                          <Form.Item
                                                            className="kFormItem"
                                                            name="menuyetki_595077_value"
                                                          >
                                                            <Image
                                                              id="595077"
                                                              zoomOnClick={false}
                                                              imageFit="fit"
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  paddingTop: 0,
                                                                  paddingRight: 0,
                                                                  paddingBottom: 0,
                                                                  paddingLeft: 0,
                                                                  width: "24px",
                                                                  height: "24px",
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "block",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(22, 48, 32, 1)"
                                                                } as any
                                                              }
                                                            ></Image>
                                                          </Form.Item>

                                                          <Label
                                                            id="534929"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              534929,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>

                                                      <KCol
                                                        id="274155"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="140030"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_800211_value">
                                                          <Switch
                                                            id="800211"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_800211_onChange();
                                                            }}
                                                            visibility={this.state.isComp800211Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isNotEqualTo(
                                                                this.state.RoleSadeceGoruntule[0]?.sg095?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            editability={this.state.isComp800211Enabled}
                                                            editabilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m009?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>

                                                      <KCol
                                                        id="876548"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_552902_value">
                                                          <Switch
                                                            id="552902"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_552902_onChange();
                                                            }}
                                                            visibility={this.state.isComp552902Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m095?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>
                                                    </KRow>

                                                    <KRow
                                                      id="626072"
                                                      visibility={this.state.isCompmenuyetki_626072AuthorizationVisible}
                                                      visibilityByAuthorization={
                                                        this.state.isCompmenuyetki_626072AuthorizationVisible
                                                      }
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopWidth: 1,
                                                          borderColor: "rgba(244, 244, 244, 1)",
                                                          borderStyle: "solid",
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "flex-start",
                                                          textAlign: "-webkit-left",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="118958"
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 36,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="260229"
                                                          direction="horizontal"
                                                          size={12}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 0,
                                                              paddingRight: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 0,
                                                              alignItems: "center",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 48, 32, 1)"
                                                            } as any
                                                          }
                                                        >
                                                          <Form.Item
                                                            className="kFormItem"
                                                            name="menuyetki_370535_value"
                                                          >
                                                            <Image
                                                              id="370535"
                                                              zoomOnClick={false}
                                                              imageFit="fit"
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  paddingTop: 0,
                                                                  paddingRight: 0,
                                                                  paddingBottom: 0,
                                                                  paddingLeft: 0,
                                                                  width: "24px",
                                                                  height: "24px",
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "block",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(22, 48, 32, 1)"
                                                                } as any
                                                              }
                                                            ></Image>
                                                          </Form.Item>

                                                          <Label
                                                            id="687082"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              687082,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>

                                                      <KCol
                                                        id="454040"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="924190"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_565874_value">
                                                          <Switch
                                                            id="565874"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_565874_onChange();
                                                            }}
                                                            visibility={this.state.isComp565874Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isNotEqualTo(
                                                                this.state.RoleSadeceGoruntule[0]?.sg096?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            editability={this.state.isComp565874Enabled}
                                                            editabilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m009?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>

                                                      <KCol
                                                        id="792854"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_173542_value">
                                                          <Switch
                                                            id="173542"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_173542_onChange();
                                                            }}
                                                            visibility={this.state.isComp173542Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m096?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>
                                                    </KRow>

                                                    <KRow
                                                      id="288022"
                                                      visibility={this.state.isCompmenuyetki_288022AuthorizationVisible}
                                                      visibilityByAuthorization={
                                                        this.state.isCompmenuyetki_288022AuthorizationVisible
                                                      }
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopWidth: 1,
                                                          borderColor: "rgba(244, 244, 244, 1)",
                                                          borderStyle: "solid",
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "flex-start",
                                                          textAlign: "-webkit-left",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="16988"
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 36,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="688247"
                                                          direction="horizontal"
                                                          size={12}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 0,
                                                              paddingRight: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 0,
                                                              alignItems: "center",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 48, 32, 1)"
                                                            } as any
                                                          }
                                                        >
                                                          <Form.Item
                                                            className="kFormItem"
                                                            name="menuyetki_184934_value"
                                                          >
                                                            <Image
                                                              id="184934"
                                                              zoomOnClick={false}
                                                              imageFit="fit"
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  paddingTop: 0,
                                                                  paddingRight: 0,
                                                                  paddingBottom: 0,
                                                                  paddingLeft: 0,
                                                                  width: "24px",
                                                                  height: "24px",
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "block",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(22, 48, 32, 1)"
                                                                } as any
                                                              }
                                                            ></Image>
                                                          </Form.Item>

                                                          <Label
                                                            id="311903"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              311903,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>

                                                      <KCol
                                                        id="403605"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="108809"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_543343_value">
                                                          <Switch
                                                            id="543343"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_543343_onChange();
                                                            }}
                                                            visibility={this.state.isComp543343Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isNotEqualTo(
                                                                this.state.RoleSadeceGoruntule[0]?.sg097?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            editability={this.state.isComp543343Enabled}
                                                            editabilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m009?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>

                                                      <KCol
                                                        id="689908"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_887614_value">
                                                          <Switch
                                                            id="887614"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_887614_onChange();
                                                            }}
                                                            visibility={this.state.isComp887614Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m097?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>
                                                    </KRow>

                                                    <KRow
                                                      id="479491"
                                                      visibility={this.state.isCompmenuyetki_479491AuthorizationVisible}
                                                      visibilityByAuthorization={
                                                        this.state.isCompmenuyetki_479491AuthorizationVisible
                                                      }
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopWidth: 1,
                                                          borderColor: "rgba(244, 244, 244, 1)",
                                                          borderStyle: "solid",
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "flex-start",
                                                          textAlign: "-webkit-left",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="207666"
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 36,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="995646"
                                                          direction="horizontal"
                                                          size={12}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 0,
                                                              paddingRight: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 0,
                                                              alignItems: "center",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 48, 32, 1)"
                                                            } as any
                                                          }
                                                        >
                                                          <Form.Item
                                                            className="kFormItem"
                                                            name="menuyetki_651109_value"
                                                          >
                                                            <Image
                                                              id="651109"
                                                              zoomOnClick={false}
                                                              imageFit="fit"
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  paddingTop: 0,
                                                                  paddingRight: 0,
                                                                  paddingBottom: 0,
                                                                  paddingLeft: 0,
                                                                  width: "24px",
                                                                  height: "24px",
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "block",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(22, 48, 32, 1)"
                                                                } as any
                                                              }
                                                            ></Image>
                                                          </Form.Item>

                                                          <Label
                                                            id="189954"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              189954,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>

                                                      <KCol
                                                        id="85711"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="86060"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_7317_value">
                                                          <Switch
                                                            id="7317"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_7317_onChange();
                                                            }}
                                                            visibility={this.state.isComp7317Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isNotEqualTo(
                                                                this.state.RoleSadeceGoruntule[0]?.sg098?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            editability={this.state.isComp7317Enabled}
                                                            editabilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m009?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>

                                                      <KCol
                                                        id="152994"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_485717_value">
                                                          <Switch
                                                            id="485717"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_485717_onChange();
                                                            }}
                                                            visibility={this.state.isComp485717Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m098?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>
                                                    </KRow>

                                                    <KRow
                                                      id="843608"
                                                      visibility={this.state.isCompmenuyetki_843608AuthorizationVisible}
                                                      visibilityByAuthorization={
                                                        this.state.isCompmenuyetki_843608AuthorizationVisible
                                                      }
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopWidth: 1,
                                                          borderColor: "rgba(244, 244, 244, 1)",
                                                          borderStyle: "solid",
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "flex-start",
                                                          textAlign: "-webkit-left",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="200177"
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 36,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="982154"
                                                          direction="horizontal"
                                                          size={12}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 0,
                                                              paddingRight: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 0,
                                                              alignItems: "center",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 48, 32, 1)"
                                                            } as any
                                                          }
                                                        >
                                                          <Form.Item
                                                            className="kFormItem"
                                                            name="menuyetki_996826_value"
                                                          >
                                                            <Image
                                                              id="996826"
                                                              zoomOnClick={false}
                                                              imageFit="fit"
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  paddingTop: 0,
                                                                  paddingRight: 0,
                                                                  paddingBottom: 0,
                                                                  paddingLeft: 0,
                                                                  width: "24px",
                                                                  height: "24px",
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "block",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(22, 48, 32, 1)"
                                                                } as any
                                                              }
                                                            ></Image>
                                                          </Form.Item>

                                                          <Label
                                                            id="520029"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              520029,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>

                                                      <KCol
                                                        id="457735"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="304384"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_565193_value">
                                                          <Switch
                                                            id="565193"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_565193_onChange();
                                                            }}
                                                            visibility={this.state.isComp565193Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isNotEqualTo(
                                                                this.state.RoleSadeceGoruntule[0]?.sg099?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            editability={this.state.isComp565193Enabled}
                                                            editabilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m009?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>

                                                      <KCol
                                                        id="148917"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_493787_value">
                                                          <Switch
                                                            id="493787"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_493787_onChange();
                                                            }}
                                                            visibility={this.state.isComp493787Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m099?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>
                                                    </KRow>

                                                    <KRow
                                                      id="894707"
                                                      visibility={this.state.isCompmenuyetki_894707AuthorizationVisible}
                                                      visibilityByAuthorization={
                                                        this.state.isCompmenuyetki_894707AuthorizationVisible
                                                      }
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopWidth: 1,
                                                          borderColor: "rgba(244, 244, 244, 1)",
                                                          borderStyle: "solid",
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "flex-start",
                                                          textAlign: "-webkit-left",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="376281"
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 36,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="867089"
                                                          direction="horizontal"
                                                          size={12}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 0,
                                                              paddingRight: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 0,
                                                              alignItems: "center",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 48, 32, 1)"
                                                            } as any
                                                          }
                                                        >
                                                          <Form.Item
                                                            className="kFormItem"
                                                            name="menuyetki_122341_value"
                                                          >
                                                            <Image
                                                              id="122341"
                                                              zoomOnClick={false}
                                                              imageFit="fit"
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  paddingTop: 0,
                                                                  paddingRight: 0,
                                                                  paddingBottom: 0,
                                                                  paddingLeft: 0,
                                                                  width: "24px",
                                                                  height: "24px",
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "block",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(22, 48, 32, 1)"
                                                                } as any
                                                              }
                                                            ></Image>
                                                          </Form.Item>

                                                          <Label
                                                            id="550495"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              550495,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>

                                                      <KCol
                                                        id="775706"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="858896"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_585429_value">
                                                          <Switch
                                                            id="585429"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_585429_onChange();
                                                            }}
                                                            visibility={this.state.isComp585429Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isNotEqualTo(
                                                                this.state.RoleSadeceGoruntule[0]?.sg991?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            editability={this.state.isComp585429Enabled}
                                                            editabilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m009?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>

                                                      <KCol
                                                        id="298565"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_799536_value">
                                                          <Switch
                                                            id="799536"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_799536_onChange();
                                                            }}
                                                            visibility={this.state.isComp799536Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m991?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>
                                                    </KRow>

                                                    <KRow
                                                      id="373446"
                                                      visibility={this.state.isCompmenuyetki_373446AuthorizationVisible}
                                                      visibilityByAuthorization={
                                                        this.state.isCompmenuyetki_373446AuthorizationVisible
                                                      }
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopWidth: 1,
                                                          borderColor: "rgba(244, 244, 244, 1)",
                                                          borderStyle: "solid",
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "flex-start",
                                                          textAlign: "-webkit-left",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="114336"
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 36,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="700954"
                                                          direction="horizontal"
                                                          size={12}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 0,
                                                              paddingRight: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 0,
                                                              alignItems: "center",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 48, 32, 1)"
                                                            } as any
                                                          }
                                                        >
                                                          <Form.Item
                                                            className="kFormItem"
                                                            name="menuyetki_830613_value"
                                                          >
                                                            <Image
                                                              id="830613"
                                                              zoomOnClick={false}
                                                              imageFit="fit"
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  paddingTop: 0,
                                                                  paddingRight: 0,
                                                                  paddingBottom: 0,
                                                                  paddingLeft: 0,
                                                                  width: "24px",
                                                                  height: "24px",
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "block",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(22, 48, 32, 1)"
                                                                } as any
                                                              }
                                                            ></Image>
                                                          </Form.Item>

                                                          <Label
                                                            id="149051"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              149051,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>

                                                      <KCol
                                                        id="13854"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="595078"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_537211_value">
                                                          <Switch
                                                            id="537211"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_537211_onChange();
                                                            }}
                                                            visibility={this.state.isComp537211Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isNotEqualTo(
                                                                this.state.RoleSadeceGoruntule[0]?.sg992?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            editability={this.state.isComp537211Enabled}
                                                            editabilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m009?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>

                                                      <KCol
                                                        id="889745"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_690110_value">
                                                          <Switch
                                                            id="690110"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_690110_onChange();
                                                            }}
                                                            visibility={this.state.isComp690110Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m992?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>
                                                    </KRow>

                                                    <KRow
                                                      id="19935"
                                                      visibility={this.state.isCompmenuyetki_19935AuthorizationVisible}
                                                      visibilityByAuthorization={
                                                        this.state.isCompmenuyetki_19935AuthorizationVisible
                                                      }
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopWidth: 1,
                                                          borderColor: "rgba(244, 244, 244, 1)",
                                                          borderStyle: "solid",
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "flex-start",
                                                          textAlign: "-webkit-left",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="35304"
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 36,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="505064"
                                                          direction="horizontal"
                                                          size={12}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 0,
                                                              paddingRight: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 0,
                                                              alignItems: "center",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 48, 32, 1)"
                                                            } as any
                                                          }
                                                        >
                                                          <Form.Item
                                                            className="kFormItem"
                                                            name="menuyetki_818172_value"
                                                          >
                                                            <Image
                                                              id="818172"
                                                              zoomOnClick={false}
                                                              imageFit="fit"
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  paddingTop: 0,
                                                                  paddingRight: 0,
                                                                  paddingBottom: 0,
                                                                  paddingLeft: 0,
                                                                  width: "24px",
                                                                  height: "24px",
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "block",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(22, 48, 32, 1)"
                                                                } as any
                                                              }
                                                            ></Image>
                                                          </Form.Item>

                                                          <Label
                                                            id="269960"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              269960,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>

                                                      <KCol
                                                        id="642101"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="156432"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_831783_value">
                                                          <Switch
                                                            id="831783"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_831783_onChange();
                                                            }}
                                                            visibility={this.state.isComp831783Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isNotEqualTo(
                                                                this.state.RoleSadeceGoruntule[0]?.sg993?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            editability={this.state.isComp831783Enabled}
                                                            editabilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m009?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>

                                                      <KCol
                                                        id="978821"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_227738_value">
                                                          <Switch
                                                            id="227738"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_227738_onChange();
                                                            }}
                                                            visibility={this.state.isComp227738Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m993?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>
                                                    </KRow>

                                                    <KRow
                                                      id="375595"
                                                      visibility={this.state.isCompmenuyetki_375595AuthorizationVisible}
                                                      visibilityByAuthorization={
                                                        this.state.isCompmenuyetki_375595AuthorizationVisible
                                                      }
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopWidth: 1,
                                                          borderColor: "rgba(244, 244, 244, 1)",
                                                          borderStyle: "solid",
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "flex-start",
                                                          textAlign: "-webkit-left",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="46752"
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 36,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="854060"
                                                          direction="horizontal"
                                                          size={12}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 0,
                                                              paddingRight: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 0,
                                                              alignItems: "center",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 48, 32, 1)"
                                                            } as any
                                                          }
                                                        >
                                                          <Form.Item
                                                            className="kFormItem"
                                                            name="menuyetki_501563_value"
                                                          >
                                                            <Image
                                                              id="501563"
                                                              zoomOnClick={false}
                                                              imageFit="fit"
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  paddingTop: 0,
                                                                  paddingRight: 0,
                                                                  paddingBottom: 0,
                                                                  paddingLeft: 0,
                                                                  width: "24px",
                                                                  height: "24px",
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "block",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(22, 48, 32, 1)"
                                                                } as any
                                                              }
                                                            ></Image>
                                                          </Form.Item>

                                                          <Label
                                                            id="46853"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              46853,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>

                                                      <KCol
                                                        id="922001"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="266331"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_172770_value">
                                                          <Switch
                                                            id="172770"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_172770_onChange();
                                                            }}
                                                            visibility={this.state.isComp172770Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isNotEqualTo(
                                                                this.state.RoleSadeceGoruntule[0]?.sg994?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            editability={this.state.isComp172770Enabled}
                                                            editabilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m009?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>

                                                      <KCol
                                                        id="792518"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_215953_value">
                                                          <Switch
                                                            id="215953"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_215953_onChange();
                                                            }}
                                                            visibility={this.state.isComp215953Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m994?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>
                                                    </KRow>

                                                    <KRow
                                                      id="723274"
                                                      visibility={this.state.isCompmenuyetki_723274AuthorizationVisible}
                                                      visibilityByAuthorization={
                                                        this.state.isCompmenuyetki_723274AuthorizationVisible
                                                      }
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopWidth: 1,
                                                          borderColor: "rgba(244, 244, 244, 1)",
                                                          borderStyle: "solid",
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "flex-start",
                                                          textAlign: "-webkit-left",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="716054"
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 36,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="897356"
                                                          direction="horizontal"
                                                          size={12}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 0,
                                                              paddingRight: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 0,
                                                              alignItems: "center",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 48, 32, 1)"
                                                            } as any
                                                          }
                                                        >
                                                          <Form.Item
                                                            className="kFormItem"
                                                            name="menuyetki_608902_value"
                                                          >
                                                            <Image
                                                              id="608902"
                                                              zoomOnClick={false}
                                                              imageFit="fit"
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  paddingTop: 0,
                                                                  paddingRight: 0,
                                                                  paddingBottom: 0,
                                                                  paddingLeft: 0,
                                                                  width: "24px",
                                                                  height: "24px",
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "block",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(22, 48, 32, 1)"
                                                                } as any
                                                              }
                                                            ></Image>
                                                          </Form.Item>

                                                          <Label
                                                            id="885838"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              885838,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>

                                                      <KCol
                                                        id="309714"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="568667"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_996604_value">
                                                          <Switch
                                                            id="996604"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_996604_onChange();
                                                            }}
                                                            visibility={this.state.isComp996604Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isNotEqualTo(
                                                                this.state.RoleSadeceGoruntule[0]?.sg995?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            editability={this.state.isComp996604Enabled}
                                                            editabilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m009?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>

                                                      <KCol
                                                        id="252513"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_849075_value">
                                                          <Switch
                                                            id="849075"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_849075_onChange();
                                                            }}
                                                            visibility={this.state.isComp849075Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m995?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>
                                                    </KRow>

                                                    <KRow
                                                      id="152077"
                                                      visibility={this.state.isCompmenuyetki_152077AuthorizationVisible}
                                                      visibilityByAuthorization={
                                                        this.state.isCompmenuyetki_152077AuthorizationVisible
                                                      }
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopWidth: 1,
                                                          borderColor: "rgba(244, 244, 244, 1)",
                                                          borderStyle: "solid",
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "flex-start",
                                                          textAlign: "-webkit-left",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="136295"
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                        xxl={12}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 36,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="793784"
                                                          direction="horizontal"
                                                          size={12}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 0,
                                                              paddingRight: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 0,
                                                              alignItems: "center",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 48, 32, 1)"
                                                            } as any
                                                          }
                                                        >
                                                          <Form.Item
                                                            className="kFormItem"
                                                            name="menuyetki_960711_value"
                                                          >
                                                            <Image
                                                              id="960711"
                                                              zoomOnClick={false}
                                                              imageFit="fit"
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  paddingTop: 0,
                                                                  paddingRight: 0,
                                                                  paddingBottom: 0,
                                                                  paddingLeft: 0,
                                                                  width: "24px",
                                                                  height: "24px",
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "block",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(22, 48, 32, 1)"
                                                                } as any
                                                              }
                                                            ></Image>
                                                          </Form.Item>

                                                          <Label
                                                            id="101341"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              101341,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 400,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>

                                                      <KCol
                                                        id="674643"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="527772"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_623465_value">
                                                          <Switch
                                                            id="623465"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_623465_onChange();
                                                            }}
                                                            visibility={this.state.isComp623465Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isNotEqualTo(
                                                                this.state.RoleSadeceGoruntule[0]?.sg996?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            editability={this.state.isComp623465Enabled}
                                                            editabilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m009?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>

                                                      <KCol
                                                        id="991426"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_276121_value">
                                                          <Switch
                                                            id="276121"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_276121_onChange();
                                                            }}
                                                            visibility={this.state.isComp276121Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m996?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>
                                                    </KRow>

                                                    <KRow
                                                      id="567043"
                                                      visibility={
                                                        this.state.isComp567043Visible == "hidden" ||
                                                        this.state.isCompmenuyetki_567043AuthorizationVisible ==
                                                          "hidden"
                                                          ? "hidden"
                                                          : "visible"
                                                      }
                                                      visibilityByAuthorization={
                                                        this.state.isCompmenuyetki_567043AuthorizationVisible ==
                                                        "hidden"
                                                          ? "hidden"
                                                          : "visible"
                                                      }
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopWidth: 1,
                                                          borderColor: "rgba(244, 244, 244, 1)",
                                                          borderStyle: "solid",
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "flex-start",
                                                          textAlign: "-webkit-left",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="550996"
                                                        xs={9}
                                                        sm={9}
                                                        md={9}
                                                        lg={9}
                                                        xl={9}
                                                        xxl={9}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="365756"
                                                          direction="horizontal"
                                                          size={12}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 0,
                                                              paddingRight: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 0,
                                                              alignItems: "center",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 48, 32, 1)"
                                                            } as any
                                                          }
                                                        >
                                                          <Form.Item
                                                            className="kFormItem"
                                                            name="menuyetki_910973_value"
                                                          >
                                                            <Image
                                                              id="910973"
                                                              zoomOnClick={false}
                                                              imageFit="fit"
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  paddingTop: 0,
                                                                  paddingRight: 0,
                                                                  paddingBottom: 0,
                                                                  paddingLeft: 0,
                                                                  width: "24px",
                                                                  height: "24px",
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "block",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(22, 48, 32, 1)"
                                                                } as any
                                                              }
                                                            ></Image>
                                                          </Form.Item>

                                                          <Label
                                                            id="903452"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              903452,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>

                                                      <KCol
                                                        id="136653"
                                                        xs={3}
                                                        sm={3}
                                                        md={3}
                                                        lg={3}
                                                        xl={3}
                                                        xxl={3}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-right",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="362874"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="113334"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_959888_value">
                                                          <Switch
                                                            id="959888"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_959888_onChange();
                                                            }}
                                                            visibility={this.state.isComp959888Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isNotEqualTo(
                                                                this.state.RoleSadeceGoruntule[0]?.sg101?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>

                                                      <KCol
                                                        id="258915"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_549197_value">
                                                          <Switch
                                                            id="549197"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_549197_onChange();
                                                            }}
                                                            visibility={this.state.isComp549197Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m101?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>
                                                    </KRow>

                                                    <KRow
                                                      id="481394"
                                                      visibility={
                                                        this.state.isComp481394Visible == "hidden" ||
                                                        this.state.isCompmenuyetki_481394AuthorizationVisible ==
                                                          "hidden"
                                                          ? "hidden"
                                                          : "visible"
                                                      }
                                                      visibilityByAuthorization={
                                                        this.state.isCompmenuyetki_481394AuthorizationVisible ==
                                                        "hidden"
                                                          ? "hidden"
                                                          : "visible"
                                                      }
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopWidth: 1,
                                                          borderColor: "rgba(244, 244, 244, 1)",
                                                          borderStyle: "solid",
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "flex-start",
                                                          textAlign: "-webkit-left",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="925538"
                                                        xs={9}
                                                        sm={9}
                                                        md={9}
                                                        lg={9}
                                                        xl={9}
                                                        xxl={9}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="719927"
                                                          direction="horizontal"
                                                          size={12}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 0,
                                                              paddingRight: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 0,
                                                              alignItems: "center",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 48, 32, 1)"
                                                            } as any
                                                          }
                                                        >
                                                          <Form.Item
                                                            className="kFormItem"
                                                            name="menuyetki_736037_value"
                                                          >
                                                            <Image
                                                              id="736037"
                                                              zoomOnClick={false}
                                                              imageFit="fit"
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  paddingTop: 0,
                                                                  paddingRight: 0,
                                                                  paddingBottom: 0,
                                                                  paddingLeft: 0,
                                                                  width: "24px",
                                                                  height: "24px",
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "block",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(22, 48, 32, 1)"
                                                                } as any
                                                              }
                                                            ></Image>
                                                          </Form.Item>

                                                          <Label
                                                            id="110256"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              110256,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>

                                                      <KCol
                                                        id="202835"
                                                        xs={3}
                                                        sm={3}
                                                        md={3}
                                                        lg={3}
                                                        xl={3}
                                                        xxl={3}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-right",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="406865"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="66383"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_758360_value">
                                                          <Switch
                                                            id="758360"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_758360_onChange();
                                                            }}
                                                            visibility={this.state.isComp758360Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isNotEqualTo(
                                                                this.state.RoleSadeceGoruntule[0]?.sg102?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>

                                                      <KCol
                                                        id="567909"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_165541_value">
                                                          <Switch
                                                            id="165541"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_165541_onChange();
                                                            }}
                                                            visibility={this.state.isComp165541Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m102?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>
                                                    </KRow>

                                                    <KRow
                                                      id="402894"
                                                      visibility={
                                                        this.state.isComp402894Visible == "hidden" ||
                                                        this.state.isCompmenuyetki_402894AuthorizationVisible ==
                                                          "hidden"
                                                          ? "hidden"
                                                          : "visible"
                                                      }
                                                      visibilityByAuthorization={
                                                        this.state.isCompmenuyetki_402894AuthorizationVisible ==
                                                        "hidden"
                                                          ? "hidden"
                                                          : "visible"
                                                      }
                                                      align="top"
                                                      justify="start"
                                                      horizontalGutter={0}
                                                      verticalGutter={0}
                                                      style={
                                                        {
                                                          borderTopWidth: 1,
                                                          borderColor: "rgba(244, 244, 244, 1)",
                                                          borderStyle: "solid",
                                                          borderRightWidth: 0,
                                                          borderBottomWidth: 0,
                                                          borderLeftWidth: 0,
                                                          paddingTop: 8,
                                                          paddingRight: 8,
                                                          paddingBottom: 8,
                                                          paddingLeft: 8,
                                                          alignItems: "flex-start",
                                                          textAlign: "-webkit-left",
                                                          fontFamily: "Poppins",
                                                          fontWeight: 500,
                                                          fontSize: "14px",
                                                          color: "rgba(22, 48, 32, 1)"
                                                        } as any
                                                      }
                                                    >
                                                      <KCol
                                                        id="558651"
                                                        xs={9}
                                                        sm={9}
                                                        md={9}
                                                        lg={9}
                                                        xl={9}
                                                        xxl={9}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <HorizontalStack
                                                          id="267127"
                                                          direction="horizontal"
                                                          size={12}
                                                          style={
                                                            {
                                                              borderStyle: "solid",
                                                              borderTopWidth: 0,
                                                              borderRightWidth: 0,
                                                              borderBottomWidth: 0,
                                                              borderLeftWidth: 0,
                                                              paddingTop: 0,
                                                              paddingRight: 0,
                                                              paddingBottom: 0,
                                                              paddingLeft: 0,
                                                              alignItems: "center",
                                                              fontFamily: "Poppins",
                                                              fontWeight: 500,
                                                              fontSize: "14px",
                                                              color: "rgba(22, 48, 32, 1)"
                                                            } as any
                                                          }
                                                        >
                                                          <Form.Item
                                                            className="kFormItem"
                                                            name="menuyetki_690393_value"
                                                          >
                                                            <Image
                                                              id="690393"
                                                              zoomOnClick={false}
                                                              imageFit="fit"
                                                              style={
                                                                {
                                                                  borderStyle: "solid",
                                                                  borderTopWidth: 0,
                                                                  borderRightWidth: 0,
                                                                  borderBottomWidth: 0,
                                                                  borderLeftWidth: 0,
                                                                  paddingTop: 0,
                                                                  paddingRight: 0,
                                                                  paddingBottom: 0,
                                                                  paddingLeft: 0,
                                                                  width: "24px",
                                                                  height: "24px",
                                                                  alignItems: "center",
                                                                  textAlign: "-webkit-left",
                                                                  display: "block",
                                                                  fontFamily: "Poppins",
                                                                  fontWeight: 500,
                                                                  fontSize: "14px",
                                                                  color: "rgba(22, 48, 32, 1)"
                                                                } as any
                                                              }
                                                            ></Image>
                                                          </Form.Item>

                                                          <Label
                                                            id="718242"
                                                            value={ReactSystemFunctions.translate(
                                                              this.ml,
                                                              718242,
                                                              "value",
                                                              this.defaultML
                                                            )}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                alignItems: "center",
                                                                textAlign: "-webkit-left",
                                                                display: "inline",
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Label>
                                                        </HorizontalStack>
                                                      </KCol>

                                                      <KCol
                                                        id="31303"
                                                        xs={3}
                                                        sm={3}
                                                        md={3}
                                                        lg={3}
                                                        xl={3}
                                                        xxl={3}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-right",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="864215"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-left",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      ></KCol>

                                                      <KCol
                                                        id="33119"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_649716_value">
                                                          <Switch
                                                            id="649716"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_649716_onChange();
                                                            }}
                                                            visibility={this.state.isComp649716Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isNotEqualTo(
                                                                this.state.RoleSadeceGoruntule[0]?.sg103?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>

                                                      <KCol
                                                        id="818957"
                                                        xs={4}
                                                        sm={4}
                                                        md={4}
                                                        lg={4}
                                                        xl={4}
                                                        xxl={4}
                                                        order={0}
                                                        pull={0}
                                                        push={0}
                                                        style={
                                                          {
                                                            borderStyle: "solid",
                                                            borderTopWidth: 0,
                                                            borderRightWidth: 0,
                                                            borderBottomWidth: 0,
                                                            borderLeftWidth: 0,
                                                            paddingTop: 8,
                                                            paddingRight: 8,
                                                            paddingBottom: 8,
                                                            paddingLeft: 8,
                                                            alignItems: "flex-start",
                                                            textAlign: "-webkit-center",
                                                            fontFamily: "Poppins",
                                                            fontWeight: 500,
                                                            fontSize: "14px",
                                                            color: "rgba(22, 48, 32, 1)"
                                                          } as any
                                                        }
                                                      >
                                                        <Form.Item className="kFormItem" name="menuyetki_103489_value">
                                                          <Switch
                                                            id="103489"
                                                            onChange={(e?: any) => {
                                                              if (e && e.stopPropagation) e.stopPropagation();
                                                              this.MenuYetkiComponent_103489_onChange();
                                                            }}
                                                            visibility={this.state.isComp103489Visible}
                                                            visibilityCondition={(rowData: object) =>
                                                              ReactSystemFunctions.isEqualTo(
                                                                this.state.RoleMenuGoster[0]?.m103?.toString(),
                                                                "1"
                                                              )
                                                            }
                                                            disabled={false}
                                                            style={
                                                              {
                                                                borderStyle: "solid",
                                                                borderTopWidth: 0,
                                                                borderRightWidth: 0,
                                                                borderBottomWidth: 0,
                                                                borderLeftWidth: 0,
                                                                paddingTop: 0,
                                                                paddingRight: 0,
                                                                paddingBottom: 0,
                                                                paddingLeft: 0,
                                                                fontFamily: "Poppins",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                color: "rgba(22, 48, 32, 1)"
                                                              } as any
                                                            }
                                                          ></Switch>
                                                        </Form.Item>
                                                      </KCol>
                                                    </KRow>
                                                  </KCol>
                                                </KRow>
                                              </KCol>
                                            </KRow>
                                          </KCol>
                                        </KRow>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>
                  </KCol>
                </KRow>

                <KDrawer
                  id="914779"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible914779: false }, () => {
                      this.callCallbackFunction(914779);
                    });
                  }}
                  visible={this.state.NavVisible914779}
                  width="680px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible914779 && (
                      <AltRol_List_Screen
                        onClose={() => {
                          this.setState({ NavVisible914779: false }, () => {
                            this.callCallbackFunction(914779);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                ></KDrawer>
              </div>

              <Footer
                id="menuyetki_footer"
                visibility={this.state.isCompMinus3Visible}
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left",
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "rgba(22, 48, 32, 1)"
                  } as any
                }
              >
                <KRow
                  id="20424"
                  onClick={(e?: any) => {
                    if (e && e.stopPropagation) e.stopPropagation();
                    this.MenuYetkiComponent_20424_onClick();
                  }}
                  showCursorPointer
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 8,
                      paddingRight: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      alignItems: "flex-start",
                      textAlign: "-webkit-left",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(22, 48, 32, 1)"
                    } as any
                  }
                >
                  <KCol
                    id="9749"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingTop: 8,
                        paddingRight: 8,
                        paddingBottom: 8,
                        paddingLeft: 8,
                        alignItems: "flex-start",
                        textAlign: "-webkit-left",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "rgba(22, 48, 32, 1)"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="38383"
                      direction="horizontal"
                      size={12}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(22, 48, 32, 1)"
                        } as any
                      }
                    >
                      <Form.Item className="kFormItem" name="menuyetki_149430_value">
                        <Image
                          id="149430"
                          zoomOnClick={false}
                          imageFit="fit"
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 0,
                              paddingRight: 0,
                              paddingBottom: 0,
                              paddingLeft: 0,
                              width: "24px",
                              height: "24px",
                              alignItems: "center",
                              textAlign: "-webkit-left",
                              display: "block",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(22, 48, 32, 1)"
                            } as any
                          }
                        ></Image>
                      </Form.Item>

                      <Label
                        id="188381"
                        value={ReactSystemFunctions.translate(this.ml, 188381, "value", this.defaultML)}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            alignItems: "center",
                            textAlign: "-webkit-left",
                            display: "inline",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(48, 77, 48, 1)"
                          } as any
                        }
                      ></Label>
                    </HorizontalStack>
                  </KCol>
                </KRow>
              </Footer>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(MenuYetki_Screen)))))
);
export { tmp as MenuYetki_Screen };
//export default tmp;
//export { tmp as MenuYetki_Screen };
