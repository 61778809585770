import { FormInstance } from "antd/es/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IMenuYetki_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IMenuYetki_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  MyContext: any[];
  MyContext_dummy: any[];
  AltRolAll: any[];
  AltRolAll_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  RoleMenuGoster: any[];
  RoleMenuGoster_dummy: any[];
  RoleSadeceGoruntule: any[];
  RoleSadeceGoruntule_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  GoBack: any;
  GoBack_dummy: any;
  RoleMenuGosterCheckUpdateGroup: number;
  RoleMenuGosterCheckUpdateGroup_dummy: number;
  RoleSadeceGoruntuleCheckUpdate: number;
  RoleSadeceGoruntuleCheckUpdate_dummy: number;
  RoleMenuGosterCheckUpdate: number;
  RoleMenuGosterCheckUpdate_dummy: number;
  Logout: boolean;
  Logout_dummy: boolean;
  goToSignInActivity: any;
  goToSignInActivity_dummy: any;
  isComp246193Visible: "visible" | "hidden";
  isComp985374Visible: "visible" | "hidden";
  isComp504493Visible: "visible" | "hidden";
  isComp765434Visible: "visible" | "hidden";
  isComp884453Visible: "visible" | "hidden";
  isComp997757Visible: "visible" | "hidden";
  isComp228966Visible: "visible" | "hidden";
  isComp713421Visible: "visible" | "hidden";
  isComp567043Visible: "visible" | "hidden";
  isComp481394Visible: "visible" | "hidden";
  isComp402894Visible: "visible" | "hidden";
  isComp731595Visible: "visible" | "hidden";
  isComp950642Visible: "visible" | "hidden";
  isComp285295Visible: "visible" | "hidden";
  isComp712330Visible: "visible" | "hidden";
  isComp783128Visible: "visible" | "hidden";
  isComp991681Visible: "visible" | "hidden";
  isComp599288Visible: "visible" | "hidden";
  isComp867369Visible: "visible" | "hidden";
  isComp374583Visible: "visible" | "hidden";
  isComp395331Visible: "visible" | "hidden";
  isComp411912Visible: "visible" | "hidden";
  isComp344494Visible: "visible" | "hidden";
  isComp364118Visible: "visible" | "hidden";
  isComp929129Visible: "visible" | "hidden";
  isComp837451Visible: "visible" | "hidden";
  isComp974301Visible: "visible" | "hidden";
  isComp670211Visible: "visible" | "hidden";
  isComp544223Visible: "visible" | "hidden";
  isComp49017Visible: "visible" | "hidden";
  isComp636754Visible: "visible" | "hidden";
  isComp976959Visible: "visible" | "hidden";
  isComp894376Visible: "visible" | "hidden";
  isComp165606Visible: "visible" | "hidden";
  isComp604653Visible: "visible" | "hidden";
  isComp112048Visible: "visible" | "hidden";
  isComp588733Visible: "visible" | "hidden";
  isComp610225Visible: "visible" | "hidden";
  isComp819978Visible: "visible" | "hidden";
  isComp430529Visible: "visible" | "hidden";
  isComp787081Visible: "visible" | "hidden";
  isComp363900Visible: "visible" | "hidden";
  isComp555420Visible: "visible" | "hidden";
  isComp591274Visible: "visible" | "hidden";
  isComp212542Visible: "visible" | "hidden";
  isComp716806Visible: "visible" | "hidden";
  isComp874444Visible: "visible" | "hidden";
  isComp623750Visible: "visible" | "hidden";
  isComp644120Visible: "visible" | "hidden";
  isComp629264Visible: "visible" | "hidden";
  isComp837170Visible: "visible" | "hidden";
  isComp230506Visible: "visible" | "hidden";
  isComp37445Visible: "visible" | "hidden";
  isComp64207Visible: "visible" | "hidden";
  isComp506233Visible: "visible" | "hidden";
  isComp958848Visible: "visible" | "hidden";
  isComp149742Visible: "visible" | "hidden";
  isComp678905Visible: "visible" | "hidden";
  isComp404080Visible: "visible" | "hidden";
  isComp357083Visible: "visible" | "hidden";
  isComp521233Visible: "visible" | "hidden";
  isComp252253Visible: "visible" | "hidden";
  isComp514287Visible: "visible" | "hidden";
  isComp198856Visible: "visible" | "hidden";
  isComp289551Visible: "visible" | "hidden";
  isComp225717Visible: "visible" | "hidden";
  isComp452224Visible: "visible" | "hidden";
  isComp256986Visible: "visible" | "hidden";
  isComp683617Visible: "visible" | "hidden";
  isComp569419Visible: "visible" | "hidden";
  isComp774525Visible: "visible" | "hidden";
  isComp997515Visible: "visible" | "hidden";
  isComp283222Visible: "visible" | "hidden";
  isComp725313Visible: "visible" | "hidden";
  isComp496085Visible: "visible" | "hidden";
  isComp82614Visible: "visible" | "hidden";
  isComp789397Visible: "visible" | "hidden";
  isComp837432Visible: "visible" | "hidden";
  isComp440754Visible: "visible" | "hidden";
  isComp319620Visible: "visible" | "hidden";
  isComp438871Visible: "visible" | "hidden";
  isComp164532Visible: "visible" | "hidden";
  isComp440263Visible: "visible" | "hidden";
  isComp734041Visible: "visible" | "hidden";
  isComp54846Visible: "visible" | "hidden";
  isComp800211Visible: "visible" | "hidden";
  isComp552902Visible: "visible" | "hidden";
  isComp565874Visible: "visible" | "hidden";
  isComp173542Visible: "visible" | "hidden";
  isComp543343Visible: "visible" | "hidden";
  isComp887614Visible: "visible" | "hidden";
  isComp7317Visible: "visible" | "hidden";
  isComp485717Visible: "visible" | "hidden";
  isComp565193Visible: "visible" | "hidden";
  isComp493787Visible: "visible" | "hidden";
  isComp585429Visible: "visible" | "hidden";
  isComp799536Visible: "visible" | "hidden";
  isComp537211Visible: "visible" | "hidden";
  isComp690110Visible: "visible" | "hidden";
  isComp831783Visible: "visible" | "hidden";
  isComp227738Visible: "visible" | "hidden";
  isComp172770Visible: "visible" | "hidden";
  isComp215953Visible: "visible" | "hidden";
  isComp996604Visible: "visible" | "hidden";
  isComp849075Visible: "visible" | "hidden";
  isComp623465Visible: "visible" | "hidden";
  isComp276121Visible: "visible" | "hidden";
  isComp959888Visible: "visible" | "hidden";
  isComp549197Visible: "visible" | "hidden";
  isComp758360Visible: "visible" | "hidden";
  isComp165541Visible: "visible" | "hidden";
  isComp649716Visible: "visible" | "hidden";
  isComp103489Visible: "visible" | "hidden";
  isCompMinus3Visible: "visible" | "hidden";
  isComp991681Enabled: "enabled" | "disabled";
  isComp867369Enabled: "enabled" | "disabled";
  isComp364118Enabled: "enabled" | "disabled";
  isComp837451Enabled: "enabled" | "disabled";
  isComp894376Enabled: "enabled" | "disabled";
  isComp604653Enabled: "enabled" | "disabled";
  isComp588733Enabled: "enabled" | "disabled";
  isComp819978Enabled: "enabled" | "disabled";
  isComp787081Enabled: "enabled" | "disabled";
  isComp555420Enabled: "enabled" | "disabled";
  isComp212542Enabled: "enabled" | "disabled";
  isComp629264Enabled: "enabled" | "disabled";
  isComp230506Enabled: "enabled" | "disabled";
  isComp149742Enabled: "enabled" | "disabled";
  isComp404080Enabled: "enabled" | "disabled";
  isComp198856Enabled: "enabled" | "disabled";
  isComp225717Enabled: "enabled" | "disabled";
  isComp774525Enabled: "enabled" | "disabled";
  isComp283222Enabled: "enabled" | "disabled";
  isComp837432Enabled: "enabled" | "disabled";
  isComp319620Enabled: "enabled" | "disabled";
  isComp164532Enabled: "enabled" | "disabled";
  isComp734041Enabled: "enabled" | "disabled";
  isComp800211Enabled: "enabled" | "disabled";
  isComp565874Enabled: "enabled" | "disabled";
  isComp543343Enabled: "enabled" | "disabled";
  isComp7317Enabled: "enabled" | "disabled";
  isComp565193Enabled: "enabled" | "disabled";
  isComp585429Enabled: "enabled" | "disabled";
  isComp537211Enabled: "enabled" | "disabled";
  isComp831783Enabled: "enabled" | "disabled";
  isComp172770Enabled: "enabled" | "disabled";
  isComp996604Enabled: "enabled" | "disabled";
  isComp623465Enabled: "enabled" | "disabled";
  isCompmenuyetki_822360AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_246193AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_192925AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_884300AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_985374AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_769054AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_923752AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_670211AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_504493AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_406721AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_361522AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_533454AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_970680AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_411822AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_15063AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_64247AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_765434AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_518738AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_274493AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_884453AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_291782AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_446418AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_997757AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_166669AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_122527AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_228966AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_331618AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_853472AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_713421AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_246978AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_246851AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_336587AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_577042AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_52402AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_626072AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_288022AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_479491AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_843608AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_894707AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_373446AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_19935AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_375595AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_723274AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_152077AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_567043AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_481394AuthorizationVisible: "visible" | "hidden";
  isCompmenuyetki_402894AuthorizationVisible: "visible" | "hidden";
}

export class MenuYetki_ScreenBase extends React.PureComponent<IMenuYetki_ScreenProps, any> {
  menuyetki_722952_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "406e6064-90b0-4de2-a799-c18a1f5d50f5",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 205411, PropertyName: "value", Value: "Rol Ayarları" },
        { Id: 722952, PropertyName: "placeholder", Value: "Rol Seçiniz..." },
        { Id: 950642, PropertyName: "value", Value: "Dashboard" },
        { Id: 763095, PropertyName: "value", Value: "Menü Başlıkları" },
        { Id: 239613, PropertyName: "value", Value: "Erişim Yetkisi Ver" },
        { Id: 949188, PropertyName: "value", Value: "Sadece Görüntüleme" },
        { Id: 143058, PropertyName: "value", Value: "Üyelik Bilgileri" },
        { Id: 442039, PropertyName: "value", Value: "Üye Onay (Power User)" },
        { Id: 398599, PropertyName: "value", Value: "Eser Bilgisi Onay (Power User)" },
        { Id: 308498, PropertyName: "value", Value: "Teklif" },
        { Id: 60264, PropertyName: "value", Value: "Teklif Alma" },
        { Id: 726551, PropertyName: "value", Value: "Teklif Verme" },
        { Id: 376448, PropertyName: "value", Value: "Eser Bilgileri" },
        { Id: 148395, PropertyName: "value", Value: "Sipariş" },
        { Id: 107114, PropertyName: "value", Value: "Kabul Edilen Teklifler (Eser Bilgi Girişi)" },
        { Id: 828117, PropertyName: "value", Value: "Teklif Listesi" },
        { Id: 57952, PropertyName: "value", Value: "Teklif Listesi (Eser Bilgileri Onaylı)" },
        { Id: 579963, PropertyName: "value", Value: "Taslak & Onay Bekleyen Siparişler" },
        { Id: 292867, PropertyName: "value", Value: "Onay Beklenen Siparişler" },
        { Id: 428389, PropertyName: "value", Value: "Sipariş Listesi" },
        { Id: 799101, PropertyName: "value", Value: "Sipariş Listesi" },
        { Id: 212431, PropertyName: "value", Value: "Bandrol Takip" },
        { Id: 280726, PropertyName: "value", Value: "Bandrol Takip Listesi" },
        { Id: 765228, PropertyName: "value", Value: "Bandrol Takip Listesi" },
        { Id: 787770, PropertyName: "value", Value: "Baskı Öncesi" },
        { Id: 863818, PropertyName: "value", Value: "Görsel Montaj  Listesi" },
        { Id: 616724, PropertyName: "value", Value: "Görsel Montaj  Listesi" },
        { Id: 645789, PropertyName: "value", Value: "Üretim" },
        { Id: 656901, PropertyName: "value", Value: "Üretim Listesi" },
        { Id: 897638, PropertyName: "value", Value: "Üretim Listesi" },
        { Id: 635051, PropertyName: "value", Value: "Depo/Sevkiyat" },
        { Id: 974938, PropertyName: "value", Value: "Sipariş Teslim Takip Listesi" },
        { Id: 344524, PropertyName: "value", Value: "Sipariş Teslim Takip Listesi" },
        { Id: 525983, PropertyName: "value", Value: "Tanımlar" },
        { Id: 435130, PropertyName: "value", Value: "Hızlı Teklif Girişi" },
        { Id: 981883, PropertyName: "value", Value: "Eser Bilgileri" },
        { Id: 140116, PropertyName: "value", Value: "Kullanıcı Tanımlama" },
        { Id: 396221, PropertyName: "value", Value: "Kitap Boyutu" },
        { Id: 534929, PropertyName: "value", Value: "Cilt Biçimi" },
        { Id: 687082, PropertyName: "value", Value: "İç Baskı Kâğıt Cinsi" },
        { Id: 311903, PropertyName: "value", Value: "İç Baskı Kâğıt Gramajı" },
        { Id: 189954, PropertyName: "value", Value: "Kapak Cinsi" },
        { Id: 520029, PropertyName: "value", Value: "Kapak Gramajı" },
        { Id: 550495, PropertyName: "value", Value: "İç Baskı Renk" },
        { Id: 149051, PropertyName: "value", Value: "Selefon" },
        { Id: 269960, PropertyName: "value", Value: "Para Birimi" },
        { Id: 46853, PropertyName: "value", Value: "Teslim Türü" },
        { Id: 885838, PropertyName: "value", Value: "Öneri Şikayet Kategori" },
        { Id: 101341, PropertyName: "value", Value: "Genel Parametreler" },
        { Id: 903452, PropertyName: "value", Value: "Log Takibi" },
        { Id: 110256, PropertyName: "value", Value: "Yardım ve Destek" },
        { Id: 718242, PropertyName: "value", Value: "Sıkça Sorulan Sorular" },
        { Id: 188381, PropertyName: "value", Value: "Logout" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);
    this.menuyetki_722952_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      MyContext: [],
      AltRolAll: [],
      ChangeVisibilityOf: "",
      RoleMenuGoster: [],
      RoleSadeceGoruntule: [],
      NAVIGATE: "",
      GoBack: "",
      RoleMenuGosterCheckUpdateGroup: 0,
      RoleSadeceGoruntuleCheckUpdate: 0,
      RoleMenuGosterCheckUpdate: 0,
      Logout: false,
      goToSignInActivity: "",
      isComp246193Visible: "hidden",
      isComp985374Visible: "hidden",
      isComp504493Visible: "hidden",
      isComp765434Visible: "hidden",
      isComp884453Visible: "hidden",
      isComp997757Visible: "hidden",
      isComp228966Visible: "hidden",
      isComp713421Visible: "hidden",
      isComp567043Visible: "hidden",
      isComp481394Visible: "hidden",
      isComp402894Visible: "hidden",
      isComp731595Visible: "hidden",
      isComp950642Visible: "hidden",
      isComp285295Visible: "hidden",
      isComp712330Visible: "hidden",
      isComp783128Visible: "hidden",
      isComp991681Visible: "hidden",
      isComp599288Visible: "hidden",
      isComp867369Visible: "hidden",
      isComp374583Visible: "hidden",
      isComp395331Visible: "hidden",
      isComp411912Visible: "hidden",
      isComp344494Visible: "hidden",
      isComp364118Visible: "hidden",
      isComp929129Visible: "hidden",
      isComp837451Visible: "hidden",
      isComp974301Visible: "hidden",
      isComp670211Visible: "hidden",
      isComp544223Visible: "hidden",
      isComp49017Visible: "hidden",
      isComp636754Visible: "hidden",
      isComp976959Visible: "hidden",
      isComp894376Visible: "hidden",
      isComp165606Visible: "hidden",
      isComp604653Visible: "hidden",
      isComp112048Visible: "hidden",
      isComp588733Visible: "hidden",
      isComp610225Visible: "hidden",
      isComp819978Visible: "hidden",
      isComp430529Visible: "hidden",
      isComp787081Visible: "hidden",
      isComp363900Visible: "hidden",
      isComp555420Visible: "hidden",
      isComp591274Visible: "hidden",
      isComp212542Visible: "hidden",
      isComp716806Visible: "hidden",
      isComp874444Visible: "hidden",
      isComp623750Visible: "hidden",
      isComp644120Visible: "hidden",
      isComp629264Visible: "hidden",
      isComp837170Visible: "hidden",
      isComp230506Visible: "hidden",
      isComp37445Visible: "hidden",
      isComp64207Visible: "hidden",
      isComp506233Visible: "hidden",
      isComp958848Visible: "hidden",
      isComp149742Visible: "hidden",
      isComp678905Visible: "hidden",
      isComp404080Visible: "hidden",
      isComp357083Visible: "hidden",
      isComp521233Visible: "hidden",
      isComp252253Visible: "hidden",
      isComp514287Visible: "hidden",
      isComp198856Visible: "hidden",
      isComp289551Visible: "hidden",
      isComp225717Visible: "hidden",
      isComp452224Visible: "hidden",
      isComp256986Visible: "hidden",
      isComp683617Visible: "hidden",
      isComp569419Visible: "hidden",
      isComp774525Visible: "hidden",
      isComp997515Visible: "hidden",
      isComp283222Visible: "hidden",
      isComp725313Visible: "hidden",
      isComp496085Visible: "hidden",
      isComp82614Visible: "hidden",
      isComp789397Visible: "hidden",
      isComp837432Visible: "hidden",
      isComp440754Visible: "hidden",
      isComp319620Visible: "hidden",
      isComp438871Visible: "hidden",
      isComp164532Visible: "hidden",
      isComp440263Visible: "hidden",
      isComp734041Visible: "hidden",
      isComp54846Visible: "hidden",
      isComp800211Visible: "hidden",
      isComp552902Visible: "hidden",
      isComp565874Visible: "hidden",
      isComp173542Visible: "hidden",
      isComp543343Visible: "hidden",
      isComp887614Visible: "hidden",
      isComp7317Visible: "hidden",
      isComp485717Visible: "hidden",
      isComp565193Visible: "hidden",
      isComp493787Visible: "hidden",
      isComp585429Visible: "hidden",
      isComp799536Visible: "hidden",
      isComp537211Visible: "hidden",
      isComp690110Visible: "hidden",
      isComp831783Visible: "hidden",
      isComp227738Visible: "hidden",
      isComp172770Visible: "hidden",
      isComp215953Visible: "hidden",
      isComp996604Visible: "hidden",
      isComp849075Visible: "hidden",
      isComp623465Visible: "hidden",
      isComp276121Visible: "hidden",
      isComp959888Visible: "hidden",
      isComp549197Visible: "hidden",
      isComp758360Visible: "hidden",
      isComp165541Visible: "hidden",
      isComp649716Visible: "hidden",
      isComp103489Visible: "hidden",
      isCompMinus3Visible: "hidden",
      isComp991681Enabled: "disabled",
      isComp867369Enabled: "disabled",
      isComp364118Enabled: "disabled",
      isComp837451Enabled: "disabled",
      isComp894376Enabled: "disabled",
      isComp604653Enabled: "disabled",
      isComp588733Enabled: "disabled",
      isComp819978Enabled: "disabled",
      isComp787081Enabled: "disabled",
      isComp555420Enabled: "disabled",
      isComp212542Enabled: "disabled",
      isComp629264Enabled: "disabled",
      isComp230506Enabled: "disabled",
      isComp149742Enabled: "disabled",
      isComp404080Enabled: "disabled",
      isComp198856Enabled: "disabled",
      isComp225717Enabled: "disabled",
      isComp774525Enabled: "disabled",
      isComp283222Enabled: "disabled",
      isComp837432Enabled: "disabled",
      isComp319620Enabled: "disabled",
      isComp164532Enabled: "disabled",
      isComp734041Enabled: "disabled",
      isComp800211Enabled: "disabled",
      isComp565874Enabled: "disabled",
      isComp543343Enabled: "disabled",
      isComp7317Enabled: "disabled",
      isComp565193Enabled: "disabled",
      isComp585429Enabled: "disabled",
      isComp537211Enabled: "disabled",
      isComp831783Enabled: "disabled",
      isComp172770Enabled: "disabled",
      isComp996604Enabled: "disabled",
      isComp623465Enabled: "disabled",
      isCompmenuyetki_822360AuthorizationVisible: "visible",
      isCompmenuyetki_246193AuthorizationVisible: "visible",
      isCompmenuyetki_192925AuthorizationVisible: "visible",
      isCompmenuyetki_884300AuthorizationVisible: "visible",
      isCompmenuyetki_985374AuthorizationVisible: "visible",
      isCompmenuyetki_769054AuthorizationVisible: "visible",
      isCompmenuyetki_923752AuthorizationVisible: "visible",
      isCompmenuyetki_670211AuthorizationVisible: "visible",
      isCompmenuyetki_504493AuthorizationVisible: "visible",
      isCompmenuyetki_406721AuthorizationVisible: "visible",
      isCompmenuyetki_361522AuthorizationVisible: "visible",
      isCompmenuyetki_533454AuthorizationVisible: "visible",
      isCompmenuyetki_970680AuthorizationVisible: "visible",
      isCompmenuyetki_411822AuthorizationVisible: "visible",
      isCompmenuyetki_15063AuthorizationVisible: "visible",
      isCompmenuyetki_64247AuthorizationVisible: "visible",
      isCompmenuyetki_765434AuthorizationVisible: "visible",
      isCompmenuyetki_518738AuthorizationVisible: "visible",
      isCompmenuyetki_274493AuthorizationVisible: "visible",
      isCompmenuyetki_884453AuthorizationVisible: "visible",
      isCompmenuyetki_291782AuthorizationVisible: "visible",
      isCompmenuyetki_446418AuthorizationVisible: "visible",
      isCompmenuyetki_997757AuthorizationVisible: "visible",
      isCompmenuyetki_166669AuthorizationVisible: "visible",
      isCompmenuyetki_122527AuthorizationVisible: "visible",
      isCompmenuyetki_228966AuthorizationVisible: "visible",
      isCompmenuyetki_331618AuthorizationVisible: "visible",
      isCompmenuyetki_853472AuthorizationVisible: "visible",
      isCompmenuyetki_713421AuthorizationVisible: "visible",
      isCompmenuyetki_246978AuthorizationVisible: "visible",
      isCompmenuyetki_246851AuthorizationVisible: "visible",
      isCompmenuyetki_336587AuthorizationVisible: "visible",
      isCompmenuyetki_577042AuthorizationVisible: "visible",
      isCompmenuyetki_52402AuthorizationVisible: "visible",
      isCompmenuyetki_626072AuthorizationVisible: "visible",
      isCompmenuyetki_288022AuthorizationVisible: "visible",
      isCompmenuyetki_479491AuthorizationVisible: "visible",
      isCompmenuyetki_843608AuthorizationVisible: "visible",
      isCompmenuyetki_894707AuthorizationVisible: "visible",
      isCompmenuyetki_373446AuthorizationVisible: "visible",
      isCompmenuyetki_19935AuthorizationVisible: "visible",
      isCompmenuyetki_375595AuthorizationVisible: "visible",
      isCompmenuyetki_723274AuthorizationVisible: "visible",
      isCompmenuyetki_152077AuthorizationVisible: "visible",
      isCompmenuyetki_567043AuthorizationVisible: "visible",
      isCompmenuyetki_481394AuthorizationVisible: "visible",
      isCompmenuyetki_402894AuthorizationVisible: "visible"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("menuyetki", "");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.MenuYetkiPageInit();
    }
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_822360AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_246193AuthorizationVisible", [
      "14edcefa-b68b-f3ff-04f6-14747e4e9ef3",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "POWERUSER",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_192925AuthorizationVisible", [
      "14edcefa-b68b-f3ff-04f6-14747e4e9ef3",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "POWERUSER",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_884300AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_985374AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_769054AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "YAYINCI",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_923752AuthorizationVisible", [
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "MATBAA",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_670211AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "YAYINCI",
      "MATBAA",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_504493AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "YAYINCI",
      "MATBAA"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_406721AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_361522AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "MATBAA",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_533454AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_970680AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "MATBAA",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_411822AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_15063AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "MATBAA",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_64247AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_765434AuthorizationVisible", [
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "MATBAA"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_518738AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "MATBAA",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_274493AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_884453AuthorizationVisible", [
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "MATBAA"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_291782AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "MATBAA",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_446418AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_997757AuthorizationVisible", [
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "YAYINCI",
      "MATBAA"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_166669AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "MATBAA",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_122527AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_228966AuthorizationVisible", [
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "MATBAA"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_331618AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "MATBAA",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_853472AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "YAYINCI",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_713421AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_246978AuthorizationVisible", [
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "MATBAA"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_246851AuthorizationVisible", [
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_336587AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_577042AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_52402AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_626072AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_288022AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_479491AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_843608AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_894707AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_373446AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_19935AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_375595AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_723274AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_152077AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_567043AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_481394AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR",
      "ADMIINx"
    ]);
    ReactSystemFunctions.hideIsNotAuthorized(this, "isCompmenuyetki_402894AuthorizationVisible", [
      "1962b92d-0cdd-a731-78a2-81cb386d7198",
      "883dff9d-4322-caea-d821-f6e48f6cf89a",
      "931b836b-2c2a-0cc5-9ec5-2c79d386878c",
      "da956395-ad87-c7ed-6ed8-8ee4e311ec77",
      "fadf86ac-6f93-5e35-4537-fdeff4d15c0e",
      "3b9dea02-3abc-e79c-8f93-9c6f64daa1ec",
      "6a8ce9d2-7c78-39fd-4050-156be7974293",
      "717b12d0-ef3d-734d-f04a-024b78c2688e",
      "YAYINCI",
      "MATBAA",
      "YAYINCITEDARIKCITICARI",
      "YAYINCITEDARIKCISAHIS",
      "MUSTERITICARI",
      "MUSTERISAHIS",
      "YAZAR",
      "ADMIINx"
    ]);

    KuikaAppManager.calculateAndSetBodyHeight("menuyetki", "");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("menuyetki", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.MenuYetkiPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      menuyetki_794846_value: this.state.RoleMenuGoster?.at?.(0)?.m001 ?? undefined,
      menuyetki_783128_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg001 ?? undefined,
      menuyetki_991681_value: this.state.RoleMenuGoster?.at?.(0)?.m011 ?? undefined,
      menuyetki_599288_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg011 ?? undefined,
      menuyetki_867369_value: this.state.RoleMenuGoster?.at?.(0)?.m012 ?? undefined,
      menuyetki_374583_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg012 ?? undefined,
      menuyetki_930852_value: this.state.RoleMenuGoster?.at?.(0)?.m002 ?? undefined,
      menuyetki_344494_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg002 ?? undefined,
      menuyetki_364118_value: this.state.RoleMenuGoster?.at?.(0)?.m021 ?? undefined,
      menuyetki_929129_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg021 ?? undefined,
      menuyetki_837451_value: this.state.RoleMenuGoster?.at?.(0)?.m022 ?? undefined,
      menuyetki_974301_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg022 ?? undefined,
      menuyetki_653815_value: this.state.RoleMenuGoster?.at?.(0)?.m003 ?? undefined,
      menuyetki_544223_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg003 ?? undefined,
      menuyetki_997_value: this.state.RoleMenuGoster?.at?.(0)?.m004 ?? undefined,
      menuyetki_976959_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg004 ?? undefined,
      menuyetki_894376_value: this.state.RoleMenuGoster?.at?.(0)?.m041 ?? undefined,
      menuyetki_165606_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg041 ?? undefined,
      menuyetki_604653_value: this.state.RoleMenuGoster?.at?.(0)?.m042 ?? undefined,
      menuyetki_112048_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg042 ?? undefined,
      menuyetki_588733_value: this.state.RoleMenuGoster?.at?.(0)?.m043 ?? undefined,
      menuyetki_610225_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg043 ?? undefined,
      menuyetki_819978_value: this.state.RoleMenuGoster?.at?.(0)?.m044 ?? undefined,
      menuyetki_430529_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg044 ?? undefined,
      menuyetki_787081_value: this.state.RoleMenuGoster?.at?.(0)?.m045 ?? undefined,
      menuyetki_363900_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg045 ?? undefined,
      menuyetki_555420_value: this.state.RoleMenuGoster?.at?.(0)?.m046 ?? undefined,
      menuyetki_591274_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg046 ?? undefined,
      menuyetki_212542_value: this.state.RoleMenuGoster?.at?.(0)?.m047 ?? undefined,
      menuyetki_716806_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg047 ?? undefined,
      menuyetki_640419_value: this.state.RoleMenuGoster?.at?.(0)?.m005 ?? undefined,
      menuyetki_644120_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg005 ?? undefined,
      menuyetki_629264_value: this.state.RoleMenuGoster?.at?.(0)?.m051 ?? undefined,
      menuyetki_837170_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg051 ?? undefined,
      menuyetki_230506_value: this.state.RoleMenuGoster?.at?.(0)?.m052 ?? undefined,
      menuyetki_37445_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg052 ?? undefined,
      menuyetki_434547_value: this.state.RoleMenuGoster?.at?.(0)?.m006 ?? undefined,
      menuyetki_958848_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg006 ?? undefined,
      menuyetki_149742_value: this.state.RoleMenuGoster?.at?.(0)?.m061 ?? undefined,
      menuyetki_678905_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg061 ?? undefined,
      menuyetki_404080_value: this.state.RoleMenuGoster?.at?.(0)?.m062 ?? undefined,
      menuyetki_357083_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg062 ?? undefined,
      menuyetki_203639_value: this.state.RoleMenuGoster?.at?.(0)?.m007 ?? undefined,
      menuyetki_514287_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg007 ?? undefined,
      menuyetki_198856_value: this.state.RoleMenuGoster?.at?.(0)?.m071 ?? undefined,
      menuyetki_289551_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg071 ?? undefined,
      menuyetki_225717_value: this.state.RoleMenuGoster?.at?.(0)?.m072 ?? undefined,
      menuyetki_452224_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg072 ?? undefined,
      menuyetki_501768_value: this.state.RoleMenuGoster?.at?.(0)?.m008 ?? undefined,
      menuyetki_569419_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg008 ?? undefined,
      menuyetki_774525_value: this.state.RoleMenuGoster?.at?.(0)?.m081 ?? undefined,
      menuyetki_997515_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg081 ?? undefined,
      menuyetki_283222_value: this.state.RoleMenuGoster?.at?.(0)?.m082 ?? undefined,
      menuyetki_725313_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg082 ?? undefined,
      menuyetki_652816_value: this.state.RoleMenuGoster?.at?.(0)?.m009 ?? undefined,
      menuyetki_789397_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg009 ?? undefined,
      menuyetki_837432_value: this.state.RoleMenuGoster?.at?.(0)?.m091 ?? undefined,
      menuyetki_440754_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg091 ?? undefined,
      menuyetki_319620_value: this.state.RoleMenuGoster?.at?.(0)?.m092 ?? undefined,
      menuyetki_438871_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg092 ?? undefined,
      menuyetki_164532_value: this.state.RoleMenuGoster?.at?.(0)?.m093 ?? undefined,
      menuyetki_440263_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg093 ?? undefined,
      menuyetki_734041_value: this.state.RoleMenuGoster?.at?.(0)?.m094 ?? undefined,
      menuyetki_54846_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg094 ?? undefined,
      menuyetki_800211_value: this.state.RoleMenuGoster?.at?.(0)?.m095 ?? undefined,
      menuyetki_552902_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg095 ?? undefined,
      menuyetki_565874_value: this.state.RoleMenuGoster?.at?.(0)?.m096 ?? undefined,
      menuyetki_173542_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg096 ?? undefined,
      menuyetki_543343_value: this.state.RoleMenuGoster?.at?.(0)?.m097 ?? undefined,
      menuyetki_887614_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg097 ?? undefined,
      menuyetki_7317_value: this.state.RoleMenuGoster?.at?.(0)?.m098 ?? undefined,
      menuyetki_485717_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg098 ?? undefined,
      menuyetki_565193_value: this.state.RoleMenuGoster?.at?.(0)?.m099 ?? undefined,
      menuyetki_493787_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg099 ?? undefined,
      menuyetki_585429_value: this.state.RoleMenuGoster?.at?.(0)?.m991 ?? undefined,
      menuyetki_799536_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg991 ?? undefined,
      menuyetki_537211_value: this.state.RoleMenuGoster?.at?.(0)?.m992 ?? undefined,
      menuyetki_690110_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg992 ?? undefined,
      menuyetki_831783_value: this.state.RoleMenuGoster?.at?.(0)?.m993 ?? undefined,
      menuyetki_227738_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg993 ?? undefined,
      menuyetki_172770_value: this.state.RoleMenuGoster?.at?.(0)?.m994 ?? undefined,
      menuyetki_215953_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg994 ?? undefined,
      menuyetki_996604_value: this.state.RoleMenuGoster?.at?.(0)?.m995 ?? undefined,
      menuyetki_849075_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg995 ?? undefined,
      menuyetki_623465_value: this.state.RoleMenuGoster?.at?.(0)?.m996 ?? undefined,
      menuyetki_276121_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg996 ?? undefined,
      menuyetki_959888_value: this.state.RoleMenuGoster?.at?.(0)?.m101 ?? undefined,
      menuyetki_549197_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg101 ?? undefined,
      menuyetki_758360_value: this.state.RoleMenuGoster?.at?.(0)?.m102 ?? undefined,
      menuyetki_165541_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg102 ?? undefined,
      menuyetki_649716_value: this.state.RoleMenuGoster?.at?.(0)?.m103 ?? undefined,
      menuyetki_103489_value: this.state.RoleSadeceGoruntule?.at?.(0)?.sg103 ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  MenuYetkiPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.AltRolAll = result?.data.altRolAll;

    formVars.menuyetki_722952_options = stateVars.AltRolAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiPageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp246193Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp985374Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp504493Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp765434Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp884453Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp997757Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp228966Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp713421Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp567043Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp481394Visible",
      "visible"
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
      this,
      "isComp402894Visible",
      "visible"
    );

    stateVars.dataSource_722952 = this.state.AltRolAll;
    stateVars.dataSource_722952 = this.state.AltRolAll;
    stateVars.isComp731595Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  MenuYetkiComponent_722952_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_722952_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_722952_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_722952_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.isComp731595Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        null
      ) === true
        ? "visible"
        : "hidden";
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_628909_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      this.MenuYetkiComponent_628909_onClick1_,
      "MenuYetki",
      "AltRol_List",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "914779",
      null,
      "right",
      null,
      "680px",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    return isErrorOccurred;
  };
  MenuYetkiComponent_628909_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UyeID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          stateVars.MyContext?.length > 0
            ? stateVars.MyContext[0].uyeId
            : this.state.MyContext?.length > 0
            ? this.state.MyContext[0].uyeId
            : null
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_628909_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.AltRolAll = result?.data.altRolAll;

    formVars.menuyetki_722952_options = stateVars.AltRolAll;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_628909_onClick2_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_628909_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.dataSource_722952 = this.state.AltRolAll;
    stateVars.dataSource_722952 = this.state.AltRolAll;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_620279_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_794846_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m001", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_794846_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdateGroup = result?.data.roleMenuGosterCheckUpdateGroup;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_794846_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_794846_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_783128_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg001", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_783128_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  MenuYetkiComponent_991681_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m011", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_991681_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_991681_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_991681_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_599288_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg011", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_599288_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_599288_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_599288_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_867369_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m012", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_867369_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_867369_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_867369_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_374583_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg012", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_374583_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_374583_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_374583_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_930852_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m002", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_930852_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdateGroup = result?.data.roleMenuGosterCheckUpdateGroup;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_930852_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_930852_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_344494_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg002", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_344494_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  MenuYetkiComponent_364118_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m021", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_364118_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_364118_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_364118_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_929129_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg021", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_929129_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_929129_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_929129_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_837451_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m022", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_837451_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_837451_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_837451_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_974301_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg022", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_974301_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_974301_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_974301_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_653815_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m003", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_653815_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  MenuYetkiComponent_544223_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg003", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_544223_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  MenuYetkiComponent_997_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m004", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_997_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdateGroup = result?.data.roleMenuGosterCheckUpdateGroup;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_997_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_997_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_976959_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg004", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_976959_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  MenuYetkiComponent_894376_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m041", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_894376_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_894376_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_894376_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_165606_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg041", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_165606_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_165606_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_165606_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_604653_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m042", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_604653_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_604653_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_604653_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_112048_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg042", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_112048_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_112048_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_112048_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_588733_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m043", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_588733_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_588733_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_588733_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_610225_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg043", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_610225_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_610225_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_610225_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_819978_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m044", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_819978_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_819978_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_819978_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_430529_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg044", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_430529_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_430529_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_430529_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_787081_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m045", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_787081_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_787081_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_787081_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_363900_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg045", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_363900_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_363900_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_363900_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_555420_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m046", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_555420_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_555420_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_555420_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_591274_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg046", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_591274_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_591274_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_591274_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_212542_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m047", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_212542_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_212542_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_212542_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_716806_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg047", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_716806_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_716806_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_716806_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_640419_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m005", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_640419_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdateGroup = result?.data.roleMenuGosterCheckUpdateGroup;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_640419_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_640419_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_644120_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg005", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_644120_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  MenuYetkiComponent_629264_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m051", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_629264_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_629264_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_629264_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_837170_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg051", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_837170_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_837170_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_837170_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_230506_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m052", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_230506_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_230506_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_230506_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_37445_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg052", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_37445_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_37445_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_37445_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_434547_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m006", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_434547_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdateGroup = result?.data.roleMenuGosterCheckUpdateGroup;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_434547_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_434547_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_958848_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg006", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_958848_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  MenuYetkiComponent_149742_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m061", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_149742_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_149742_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_149742_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_678905_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg061", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_678905_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_678905_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_678905_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_404080_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m062", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_404080_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_404080_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_404080_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_357083_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg062", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_357083_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_357083_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_357083_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_203639_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m007", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_203639_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdateGroup = result?.data.roleMenuGosterCheckUpdateGroup;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_203639_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_203639_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_514287_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg007", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_514287_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  MenuYetkiComponent_198856_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m071", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_198856_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_198856_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_198856_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_289551_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg071", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_289551_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_289551_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_289551_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_225717_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m072", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_225717_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_225717_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_225717_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_452224_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg072", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_452224_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_452224_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_452224_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_501768_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m008", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_501768_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdateGroup = result?.data.roleMenuGosterCheckUpdateGroup;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_501768_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_501768_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_569419_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg008", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_569419_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  MenuYetkiComponent_774525_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m081", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_774525_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_774525_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_774525_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_997515_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg081", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_997515_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_997515_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_997515_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_283222_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m082", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_283222_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_283222_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_283222_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_725313_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg082", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_725313_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_725313_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_725313_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_652816_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m009", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_652816_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdateGroup = result?.data.roleMenuGosterCheckUpdateGroup;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_652816_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_652816_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_789397_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg009", "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_789397_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  MenuYetkiComponent_837432_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m091", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_837432_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_837432_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_837432_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_440754_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg091", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_440754_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_440754_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_440754_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_319620_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m092", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_319620_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_319620_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_319620_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_438871_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg092", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_438871_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_438871_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_438871_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_164532_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m093", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_164532_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_164532_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_164532_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_440263_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg093", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_440263_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_440263_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_440263_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_734041_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m094", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_734041_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_734041_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_734041_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_54846_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg094", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_54846_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_54846_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_54846_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_800211_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m095", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_800211_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_800211_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_800211_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_552902_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg095", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_552902_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_552902_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_552902_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_565874_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m096", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_565874_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_565874_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_565874_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_173542_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg096", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_173542_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_173542_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_173542_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_543343_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m097", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_543343_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_543343_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_543343_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_887614_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg097", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_887614_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_887614_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_887614_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_7317_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m098", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_7317_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_7317_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_7317_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_485717_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg098", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_485717_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_485717_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_485717_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_565193_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m099", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_565193_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_565193_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_565193_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_493787_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg099", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_493787_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_493787_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_493787_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_585429_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m991", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_585429_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_585429_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_585429_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_799536_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg991", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_799536_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_799536_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_799536_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_537211_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m992", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_537211_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_537211_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_537211_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_690110_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg992", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_690110_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_690110_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_690110_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_831783_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m993", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_831783_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_831783_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_831783_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_227738_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg993", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_227738_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_227738_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_227738_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_172770_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m994", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_172770_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_172770_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_172770_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_215953_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg994", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_215953_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_215953_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_215953_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_996604_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m995", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_996604_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_996604_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_996604_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_849075_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg995", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_849075_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_849075_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_849075_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_623465_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m996", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_623465_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_623465_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_623465_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_276121_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg996", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_276121_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_276121_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_276121_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_959888_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m101", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_959888_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_959888_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_959888_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_549197_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg101", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_549197_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_549197_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_549197_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_758360_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m102", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_758360_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_758360_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_758360_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_165541_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg102", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_165541_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_165541_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_165541_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_649716_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      MenuKod_0: ReactSystemFunctions.convertToTypeByName("m103", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_649716_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleMenuGosterCheckUpdate = result?.data.roleMenuGosterCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_649716_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_649716_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_103489_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      SadeceGoruntuleKod_0: ReactSystemFunctions.convertToTypeByName("sg103", "string"),
      Id_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Id_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "menuyetki_722952_value", "value", "AltRolAll", "id", "id")
        ),
        "Guid"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "MenuYetki/MenuYetkiComponent_103489_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RoleSadeceGoruntuleCheckUpdate = result?.data.roleSadeceGoruntuleCheckUpdate;
    stateVars.RoleMenuGoster = result?.data.roleMenuGoster;
    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );
    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );
    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );
    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );
    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );
    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );
    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );
    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );
    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );
    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );
    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );
    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );
    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );
    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );
    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );
    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );
    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );
    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );
    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );
    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );
    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );
    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );
    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );
    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );
    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );
    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );
    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );
    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );
    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );
    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );
    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );
    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );
    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );
    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );
    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );
    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );
    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );
    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );
    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );
    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );
    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );
    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );
    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );
    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );
    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );
    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );
    stateVars.isComp599288Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m011
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374583Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m012
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp929129Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m021
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp974301Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m022
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165606Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m041
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp112048Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m042
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp610225Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m043
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp430529Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m044
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp363900Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m045
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp591274Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m046
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp716806Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m047
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m051
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp37445Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m052
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp678905Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m061
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp357083Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m062
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp289551Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m071
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp452224Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m072
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp997515Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m081
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp725313Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m082
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440754Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m091
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp438871Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m092
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp440263Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m093
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp54846Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m094
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552902Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m095
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp173542Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m096
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp887614Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m097
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485717Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m098
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp493787Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m099
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp799536Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m991
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp690110Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m992
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp227738Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m993
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp215953Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m994
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp849075Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m995
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp276121Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m996
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp549197Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m101
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp165541Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m102
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp103489Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m103
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp991681Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp867369Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m001
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m001
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp364118Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837451Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m002
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m002
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp894376Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp604653Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp588733Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp819978Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp787081Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp555420Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp212542Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m004
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m004
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp629264Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp230506Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m005
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m005
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp149742Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp404080Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m006
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m006
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp198856Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp225717Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m007
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m007
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp774525Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp283222Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m008
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m008
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp837432Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp319620Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp164532Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp734041Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp800211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565874Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp543343Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp7317Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp565193Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp585429Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp537211Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp831783Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp172770Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp996604Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.isComp623465Enabled =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleMenuGoster?.length > 0
            ? stateVars.RoleMenuGoster[0].m009
            : this.state.RoleMenuGoster?.length > 0
            ? this.state.RoleMenuGoster[0].m009
            : null
        ),
        "1"
      ) === true
        ? "enabled"
        : "disabled";
    stateVars.RoleSadeceGoruntule = result?.data.roleSadeceGoruntule;
    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );
    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );
    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );
    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );
    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );
    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );
    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );
    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );
    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );
    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );
    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );
    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );
    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );
    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );
    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );
    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );
    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );
    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );
    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );
    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );
    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );
    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );
    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );
    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );
    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );
    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );
    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );
    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );
    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );
    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );
    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );
    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );
    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );
    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );
    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );
    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );
    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );
    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );
    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );
    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );
    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );
    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );
    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );
    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );
    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );
    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );
    stateVars.isComp991681Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg011
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg011
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp867369Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg012
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg012
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp364118Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg021
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg021
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837451Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg022
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg022
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp894376Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg041
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg041
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp604653Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg042
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg042
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp588733Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg043
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg043
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp819978Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg044
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg044
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp787081Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg045
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg045
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp555420Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg046
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg046
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp212542Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg047
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg047
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp629264Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg051
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg051
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp230506Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg052
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg052
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149742Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg061
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg061
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404080Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg062
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg062
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp198856Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg071
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg071
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp225717Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg072
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg072
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp774525Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg081
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg081
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp283222Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg082
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg082
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp837432Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg091
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg091
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp319620Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg092
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg092
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp164532Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg093
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg093
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp734041Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg094
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg094
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp800211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg095
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg095
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565874Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg096
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg096
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp543343Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg097
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg097
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp7317Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg098
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg098
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp565193Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg099
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg099
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp585429Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg991
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg991
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp537211Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg992
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg992
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp831783Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg993
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg993
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp172770Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg994
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg994
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp996604Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg995
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg995
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp623465Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg996
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg996
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp959888Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg101
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg101
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp758360Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg102
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg102
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp649716Visible =
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.RoleSadeceGoruntule?.length > 0
            ? stateVars.RoleSadeceGoruntule[0].sg103
            : this.state.RoleSadeceGoruntule?.length > 0
            ? this.state.RoleSadeceGoruntule[0].sg103
            : null
        ),
        "1"
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.MenuYetkiComponent_103489_onChange1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  MenuYetkiComponent_103489_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    formVars.menuyetki_794846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m001
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m001
        : null
    );

    formVars.menuyetki_783128_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg001
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg001
        : null
    );

    formVars.menuyetki_991681_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m011
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m011
        : null
    );

    formVars.menuyetki_599288_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg011
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg011
        : null
    );

    formVars.menuyetki_867369_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m012
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m012
        : null
    );

    formVars.menuyetki_374583_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg012
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg012
        : null
    );

    formVars.menuyetki_930852_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m002
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m002
        : null
    );

    formVars.menuyetki_344494_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg002
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg002
        : null
    );

    formVars.menuyetki_364118_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m021
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m021
        : null
    );

    formVars.menuyetki_929129_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg021
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg021
        : null
    );

    formVars.menuyetki_837451_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m022
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m022
        : null
    );

    formVars.menuyetki_974301_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg022
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg022
        : null
    );

    formVars.menuyetki_653815_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m003
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m003
        : null
    );

    formVars.menuyetki_544223_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg003
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg003
        : null
    );

    formVars.menuyetki_997_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m004
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m004
        : null
    );

    formVars.menuyetki_976959_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg004
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg004
        : null
    );

    formVars.menuyetki_894376_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m041
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m041
        : null
    );

    formVars.menuyetki_165606_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg041
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg041
        : null
    );

    formVars.menuyetki_604653_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m042
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m042
        : null
    );

    formVars.menuyetki_112048_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg042
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg042
        : null
    );

    formVars.menuyetki_588733_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m043
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m043
        : null
    );

    formVars.menuyetki_610225_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg043
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg043
        : null
    );

    formVars.menuyetki_819978_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m044
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m044
        : null
    );

    formVars.menuyetki_430529_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg044
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg044
        : null
    );

    formVars.menuyetki_787081_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m045
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m045
        : null
    );

    formVars.menuyetki_363900_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg045
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg045
        : null
    );

    formVars.menuyetki_555420_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m046
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m046
        : null
    );

    formVars.menuyetki_591274_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg046
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg046
        : null
    );

    formVars.menuyetki_212542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m047
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m047
        : null
    );

    formVars.menuyetki_716806_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg047
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg047
        : null
    );

    formVars.menuyetki_640419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m005
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m005
        : null
    );

    formVars.menuyetki_644120_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg005
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg005
        : null
    );

    formVars.menuyetki_629264_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m051
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m051
        : null
    );

    formVars.menuyetki_837170_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg051
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg051
        : null
    );

    formVars.menuyetki_230506_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m052
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m052
        : null
    );

    formVars.menuyetki_37445_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg052
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg052
        : null
    );

    formVars.menuyetki_434547_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m006
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m006
        : null
    );

    formVars.menuyetki_958848_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg006
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg006
        : null
    );

    formVars.menuyetki_149742_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m061
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m061
        : null
    );

    formVars.menuyetki_678905_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg061
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg061
        : null
    );

    formVars.menuyetki_404080_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m062
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m062
        : null
    );

    formVars.menuyetki_357083_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg062
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg062
        : null
    );

    formVars.menuyetki_203639_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m007
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m007
        : null
    );

    formVars.menuyetki_514287_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg007
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg007
        : null
    );

    formVars.menuyetki_198856_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m071
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m071
        : null
    );

    formVars.menuyetki_289551_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg071
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg071
        : null
    );

    formVars.menuyetki_225717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m072
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m072
        : null
    );

    formVars.menuyetki_452224_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg072
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg072
        : null
    );

    formVars.menuyetki_501768_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m008
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m008
        : null
    );

    formVars.menuyetki_569419_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg008
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg008
        : null
    );

    formVars.menuyetki_774525_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m081
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m081
        : null
    );

    formVars.menuyetki_997515_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg081
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg081
        : null
    );

    formVars.menuyetki_283222_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m082
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m082
        : null
    );

    formVars.menuyetki_725313_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg082
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg082
        : null
    );

    formVars.menuyetki_652816_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m009
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m009
        : null
    );

    formVars.menuyetki_789397_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg009
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg009
        : null
    );

    formVars.menuyetki_837432_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m091
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m091
        : null
    );

    formVars.menuyetki_440754_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg091
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg091
        : null
    );

    formVars.menuyetki_319620_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m092
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m092
        : null
    );

    formVars.menuyetki_438871_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg092
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg092
        : null
    );

    formVars.menuyetki_164532_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m093
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m093
        : null
    );

    formVars.menuyetki_440263_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg093
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg093
        : null
    );

    formVars.menuyetki_734041_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m094
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m094
        : null
    );

    formVars.menuyetki_54846_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg094
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg094
        : null
    );

    formVars.menuyetki_800211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m095
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m095
        : null
    );

    formVars.menuyetki_552902_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg095
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg095
        : null
    );

    formVars.menuyetki_565874_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m096
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m096
        : null
    );

    formVars.menuyetki_173542_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg096
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg096
        : null
    );

    formVars.menuyetki_543343_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m097
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m097
        : null
    );

    formVars.menuyetki_887614_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg097
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg097
        : null
    );

    formVars.menuyetki_7317_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m098
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m098
        : null
    );

    formVars.menuyetki_485717_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg098
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg098
        : null
    );

    formVars.menuyetki_565193_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m099
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m099
        : null
    );

    formVars.menuyetki_493787_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg099
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg099
        : null
    );

    formVars.menuyetki_585429_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m991
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m991
        : null
    );

    formVars.menuyetki_799536_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg991
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg991
        : null
    );

    formVars.menuyetki_537211_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m992
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m992
        : null
    );

    formVars.menuyetki_690110_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg992
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg992
        : null
    );

    formVars.menuyetki_831783_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m993
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m993
        : null
    );

    formVars.menuyetki_227738_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg993
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg993
        : null
    );

    formVars.menuyetki_172770_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m994
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m994
        : null
    );

    formVars.menuyetki_215953_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg994
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg994
        : null
    );

    formVars.menuyetki_996604_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m995
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m995
        : null
    );

    formVars.menuyetki_849075_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg995
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg995
        : null
    );

    formVars.menuyetki_623465_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m996
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m996
        : null
    );

    formVars.menuyetki_276121_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg996
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg996
        : null
    );

    formVars.menuyetki_959888_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m101
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m101
        : null
    );

    formVars.menuyetki_549197_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg101
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg101
        : null
    );

    formVars.menuyetki_758360_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m102
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m102
        : null
    );

    formVars.menuyetki_165541_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg102
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg102
        : null
    );

    formVars.menuyetki_649716_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleMenuGoster?.length > 0
        ? stateVars.RoleMenuGoster[0].m103
        : this.state.RoleMenuGoster?.length > 0
        ? this.state.RoleMenuGoster[0].m103
        : null
    );

    formVars.menuyetki_103489_value = ReactSystemFunctions.toBoolean(
      this,
      stateVars.RoleSadeceGoruntule?.length > 0
        ? stateVars.RoleSadeceGoruntule[0].sg103
        : this.state.RoleSadeceGoruntule?.length > 0
        ? this.state.RoleSadeceGoruntule[0].sg103
        : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  MenuYetkiComponent_20424_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    stateVars.Logout = await ReactSystemFunctions.logout();
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToSignInActivity(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [914779] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.MenuYetkiPageInit();
    }
    if (diId == 914779) {
      isErrorOccurred = await this.MenuYetkiComponent_628909_onClick1_();
      if (isErrorOccurred) return true;
    }
  }
}
