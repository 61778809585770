import { FormInstance } from "antd/es/form";
import { Guid } from "guid-typescript";
import moment from "moment";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISignupAdminChange_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISignupAdminChange_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GenelParametreler: any[];
  GenelParametreler_dummy: any[];
  sbUyelikTipiList: any[];
  sbUyelikTipiList_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  VCodeCreate: any[];
  VCodeCreate_dummy: any[];
  SendMailSettings: any[];
  SendMailSettings_dummy: any[];
  SendMail: boolean;
  SendMail_dummy: boolean;
  VCodeControl: any[];
  VCodeControl_dummy: any[];
  Notify: boolean;
  Notify_dummy: boolean;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  UyelikValidasyonKontrol: any[];
  UyelikValidasyonKontrol_dummy: any[];
  Signup: any;
  Signup_dummy: any;
  SaveUser: any;
  SaveUser_dummy: any;
  AddUserToRole: boolean;
  AddUserToRole_dummy: boolean;
  KullaniciDetaySave: number;
  KullaniciDetaySave_dummy: number;
  getKUserIdbyUserName: any[];
  getKUserIdbyUserName_dummy: any[];
  KullaniciDetayUserIdUyeIdUpdate: number;
  KullaniciDetayUserIdUyeIdUpdate_dummy: number;
  WriteToSessionStorage: any;
  WriteToSessionStorage_dummy: any;
  goToNextScreenAfterSignup: any;
  goToNextScreenAfterSignup_dummy: any;
  isComp46066Visible: "visible" | "hidden";
  isComp824157Visible: "visible" | "hidden";
  isComp776110Visible: "visible" | "hidden";
  isComp412471Visible: "visible" | "hidden";
  isComp387716Enabled: "enabled" | "disabled";
  isComp120930Enabled: "enabled" | "disabled";
}

export class SignupAdminChange_ScreenBase extends React.PureComponent<ISignupAdminChange_ScreenProps, any> {
  ml = [
    {
      Id: "41cd36a4-5b07-cfca-4f7f-f48019ff4ff3",
      Name: "tr_TR",
      ShortName: "Türkçe",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "0069cee9-16ca-4a5a-aab9-dd58af96ca96", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "be557974-c25a-4d84-a29a-1c1f35e1dce1",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 717912, PropertyName: "value", Value: "Kayıt Bilgileri" },
        { Id: 997730, PropertyName: "value", Value: "Lütfen hesabı oluşturun." },
        { Id: 910653, PropertyName: "value", Value: "Ad" },
        { Id: 208841, PropertyName: "value", Value: "Soyad" },
        { Id: 833206, PropertyName: "value", Value: "Telefon Numarası" },
        { Id: 432007, PropertyName: "placeholder", Value: "05*******" },
        { Id: 34916, PropertyName: "value", Value: "E-posta" },
        { Id: 824157, PropertyName: "label", Value: "Doğrula" },
        { Id: 189273, PropertyName: "value", Value: "Doğrulama Kodu" },
        { Id: 957358, PropertyName: "label", Value: "Onayla" },
        { Id: 905126, PropertyName: "value", Value: "İşleme devam için mail adresini doğrulayınız!" },
        { Id: 185079, PropertyName: "value", Value: "Şifre" },
        { Id: 434410, PropertyName: "placeholder", Value: "***********" },
        { Id: 514230, PropertyName: "value", Value: "Üyelik Sözleşmesi Onayı" },
        { Id: 228598, PropertyName: "value", Value: "KVKK Metni Onayı" },
        { Id: 387716, PropertyName: "label", Value: "Kayıt Ol" }
      ]
    }
  ];
  defaultML = "tr_TR";

  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GenelParametreler: [],
      sbUyelikTipiList: [],
      ChangeVisibilityOf: "",
      ChangeEnabledOf: "",
      VCodeCreate: [],
      SendMailSettings: [],
      SendMail: false,
      VCodeControl: [],
      Notify: false,
      NAVIGATE: "",
      UyelikValidasyonKontrol: [],
      Signup: "",
      SaveUser: "",
      AddUserToRole: false,
      KullaniciDetaySave: 0,
      getKUserIdbyUserName: [],
      KullaniciDetayUserIdUyeIdUpdate: 0,
      WriteToSessionStorage: "",
      goToNextScreenAfterSignup: "",
      isComp46066Visible: "hidden",
      isComp824157Visible: "visible",
      isComp776110Visible: "hidden",
      isComp412471Visible: "visible",
      isComp387716Enabled: "enabled",
      isComp120930Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("signupadminchange", "");
      return;
    }

    if (true) {
      await this.SignupAdminChangePageInit();
    }

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("signupadminchange", "");
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("387716");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("signupadminchange", "");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.SignupAdminChangePageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      signupadminchange_300972_value: false,
      signupadminchange_799782_value: false
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  clearFieldValidation = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName) {
      this.props.form.setFields([
        {
          name: fieldName,
          errors: []
        }
      ]);
    }
  };

  SignupAdminChangePageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    let localVar = {};

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "SignupAdminChange/SignupAdminChangePageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GenelParametreler = result?.data.genelParametreler;
    stateVars.sbUyelikTipiList = result?.data.sbUyelikTipiList;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupAdminChangePageInit1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupAdminChangePageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp46066Visible", "hidden");
    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp387716Enabled", "disabled");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  SignupAdminChangeComponent_824157_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        "string"
      ),
      actionkey_1: ReactSystemFunctions.convertToTypeByName("maildogrulama", "string"),
      uyeliktipi_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmUyelikTipi ?? this.props.screenInputs.prmuyeliktipi,
        "string"
      ),
      numara_1: ReactSystemFunctions.convertToTypeByName(null, "string"),
      templateId_2: ReactSystemFunctions.convertToTypeByName("4854ef0f-7ec9-4d8b-b698-375546a3030c", "string"),
      receivers_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        "string"
      ),
      sender_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      cc_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      bcc_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentFileName_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      attachmentBase64_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      PublinkDestekTelNo_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          stateVars.GenelParametreler?.length > 0
            ? stateVars.GenelParametreler[0].destekTelNo
            : this.state.GenelParametreler?.length > 0
            ? this.state.GenelParametreler[0].destekTelNo
            : null
        ),
        "string"
      ),
      uyeliktipi_2: ReactSystemFunctions.convertToTypeByName(null, "string"),
      language_2: ReactSystemFunctions.convertToTypeByName("", "string"),
      EMail_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        "string"
      ),
      VCode_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_584359_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SignupAdminChange/SignupAdminChangeComponent_824157_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VCodeCreate = result?.data.vCodeCreate;
    stateVars.SendMailSettings = result?.data.sendMailSettings;
    stateVars.SendMail = result?.data.sendMail;
    stateVars.VCodeControl = result?.data.vCodeControl;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupAdminChangeComponent_824157_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupAdminChangeComponent_824157_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp824157Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp46066Visible", "visible");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SignupAdminChangeComponent_957358_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      EMail_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        "string"
      ),
      VCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_584359_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SignupAdminChange/SignupAdminChangeComponent_957358_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.VCodeControl = result?.data.vCodeControl;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupAdminChangeComponent_957358_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupAdminChangeComponent_957358_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.VCodeControl?.length > 0
            ? stateVars.VCodeControl[0].dogrulama
            : this.state.VCodeControl?.length > 0
            ? this.state.VCodeControl[0].dogrulama
            : null
        ),
        "1"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "7dba7e4d_405c_7a89_b0dd_d9d4f9593441_notify",
          this.defaultML,
          "Doğrulandı!"
        ),
        "success",
        "bottom-right",
        3,
        null,
        null,
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_957358_onClick2_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_957358_onClick2_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupAdminChangeComponent_957358_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.VCodeControl?.length > 0
            ? stateVars.VCodeControl[0].dogrulama
            : this.state.VCodeControl?.length > 0
            ? this.state.VCodeControl[0].dogrulama
            : null
        ),
        "0"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "ddf8d5db_edff_db6b_392e_6258271077a2_notify",
          this.defaultML,
          "Doğrulanmadı!"
        ),
        "danger",
        "bottom-right",
        3,
        null,
        null,
        null
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_957358_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_957358_onClick3_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupAdminChangeComponent_957358_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.VCodeControl?.length > 0
            ? stateVars.VCodeControl[0].dogrulama
            : this.state.VCodeControl?.length > 0
            ? this.state.VCodeControl[0].dogrulama
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp120930Enabled", "disabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_957358_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_957358_onClick4_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupAdminChangeComponent_957358_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp46066Visible", "hidden");

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupAdminChangeComponent_957358_onClick5_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  SignupAdminChangeComponent_957358_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.VCodeControl?.length > 0
            ? stateVars.VCodeControl[0].dogrulama
            : this.state.VCodeControl?.length > 0
            ? this.state.VCodeControl[0].dogrulama
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp387716Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_957358_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_957358_onClick6_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupAdminChangeComponent_957358_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.VCodeControl?.length > 0
            ? stateVars.VCodeControl[0].dogrulama
            : this.state.VCodeControl?.length > 0
            ? this.state.VCodeControl[0].dogrulama
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp824157Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_957358_onClick7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_957358_onClick7_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupAdminChangeComponent_957358_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.VCodeControl?.length > 0
            ? stateVars.VCodeControl[0].dogrulama
            : this.state.VCodeControl?.length > 0
            ? this.state.VCodeControl[0].dogrulama
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp776110Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_957358_onClick8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_957358_onClick8_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupAdminChangeComponent_957358_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.VCodeControl?.length > 0
            ? stateVars.VCodeControl[0].dogrulama
            : this.state.VCodeControl?.length > 0
            ? this.state.VCodeControl[0].dogrulama
            : null
        ),
        "0"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp824157Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_957358_onClick9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_957358_onClick9_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupAdminChangeComponent_957358_onClick9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.VCodeControl?.length > 0
            ? stateVars.VCodeControl[0].dogrulama
            : this.state.VCodeControl?.length > 0
            ? this.state.VCodeControl[0].dogrulama
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp412471Visible",
        "hidden"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  SignupAdminChangeComponent_514230_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("PDFViewer", "FormTipi", "Uyelik");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SignupAdminChange",
      "PDFViewer",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "979982",
      null,
      "left",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SignupAdminChangeComponent_228598_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("PDFViewer", "FormTipi", "KVKK");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "SignupAdminChange",
      "PDFViewer",
      "",
      "7d969ddf-5801-4eb6-b92b-12be35bdd565",
      "806891",
      null,
      "left",
      null,
      "50%",
      "100vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SignupAdminChangeComponent_387716_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_590646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_590646_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_488466_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_434410_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_434410_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_300972_checked", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_300972_checked",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_799782_checked", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_799782_checked",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      prm1_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmUyelikTipiKey ?? this.props.screenInputs.prmuyeliktipikey,
        "string"
      ),
      prm2_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_590646_value", "value", "", "", "")
        ),
        "string"
      ),
      prm3_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        "string"
      ),
      prm4_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "string"
      ),
      prm5_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        "string"
      ),
      prm6_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_434410_value", "value", "", "", "")
        ),
        "string"
      ),
      prm7_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_300972_checked", "value", "", "", "")
        ),
        "string"
      ),
      prm8_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_799782_checked", "value", "", "", "")
        ),
        "string"
      ),
      prm1_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmUyelikTipiKey ?? this.props.screenInputs.prmuyeliktipikey,
        "string"
      ),
      prm2_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_590646_value", "value", "", "", "")
        ),
        "string"
      ),
      prm3_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        "string"
      ),
      prm4_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "string"
      ),
      prm5_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        "string"
      ),
      prm6_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_434410_value", "value", "", "", "")
        ),
        "string"
      ),
      prm7_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_300972_checked", "value", "", "", "")
        ),
        "string"
      ),
      prm8_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_799782_checked", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SignupAdminChange/SignupAdminChangeComponent_387716_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.UyelikValidasyonKontrol = result?.data.uyelikValidasyonKontrol;
    stateVars.UyelikValidasyonKontrol = result?.data.uyelikValidasyonKontrol;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick1_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupAdminChangeComponent_387716_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_434410_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_434410_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_590646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_590646_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_488466_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp387716Enabled", "disabled");
    stateVars.Signup = (
      (await ReactSystemFunctions.signup(
        this,
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_434410_value", "value", "", "", "")
        ),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_590646_value", "value", "", "", "")
        ),
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        true,
        false,
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        null
      )) as any
    ).data;

    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick2_();
        resolve();
      });
    });

    return isErrorOccurred;
  };
  SignupAdminChangeComponent_387716_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_590646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_590646_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_488466_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_590646_value", "value", "", "", "")
        ),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("c66813e5-5c42-414f-8d2b-edc1673f33ae", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(true, "boolean")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmUyelikTipiKey ?? this.props.screenInputs.prmuyeliktipikey,
        "1"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "SignupAdminChange/SignupAdminChangeComponent_387716_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick3_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick3_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupAdminChangeComponent_387716_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_590646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_590646_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_488466_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_590646_value", "value", "", "", "")
        ),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("c967fbf7-96d2-49a1-9e96-26cb0719f825", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(true, "boolean")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmUyelikTipiKey ?? this.props.screenInputs.prmuyeliktipikey,
        "2"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "SignupAdminChange/SignupAdminChangeComponent_387716_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick4_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick4_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupAdminChangeComponent_387716_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_590646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_590646_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_488466_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_590646_value", "value", "", "", "")
        ),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("317b40ed-f5d4-4cdd-9771-1b80f2ebabc6", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(true, "boolean")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmUyelikTipiKey ?? this.props.screenInputs.prmuyeliktipikey,
        "3"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "SignupAdminChange/SignupAdminChangeComponent_387716_onClick4_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick5_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick5_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupAdminChangeComponent_387716_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_590646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_590646_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_488466_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_590646_value", "value", "", "", "")
        ),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("317b40ed-f5d4-4cdd-9771-1b80f2ebabc6", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(true, "boolean")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmUyelikTipiKey ?? this.props.screenInputs.prmuyeliktipikey,
        "7"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "SignupAdminChange/SignupAdminChangeComponent_387716_onClick5_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick6_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick6_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupAdminChangeComponent_387716_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_590646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_590646_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_488466_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_590646_value", "value", "", "", "")
        ),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("8ad09048-a5b6-44b8-91b9-d252fe7929e5", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(true, "boolean")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmUyelikTipiKey ?? this.props.screenInputs.prmuyeliktipikey,
        "4"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "SignupAdminChange/SignupAdminChangeComponent_387716_onClick6_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick7_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick7_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupAdminChangeComponent_387716_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_590646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_590646_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_488466_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_590646_value", "value", "", "", "")
        ),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("971aaf56-0abd-4ba8-9418-d8757e306467", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(true, "boolean")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmUyelikTipiKey ?? this.props.screenInputs.prmuyeliktipikey,
        "5"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "SignupAdminChange/SignupAdminChangeComponent_387716_onClick7_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick8_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick8_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupAdminChangeComponent_387716_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_590646_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_590646_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_488466_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      userName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        "string"
      ),
      firstName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_590646_value", "value", "", "", "")
        ),
        "string"
      ),
      lastName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        "string"
      ),
      roleNames_0: ReactSystemFunctions.convertToTypeByName(null, "string"),
      startingScreenId_0: ReactSystemFunctions.convertToTypeByName("971aaf56-0abd-4ba8-9418-d8757e306467", "Guid"),
      phoneNumber_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "string"
      ),
      dontSendEmail_0: ReactSystemFunctions.convertToTypeByName(true, "boolean")
    };

    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmUyelikTipiKey ?? this.props.screenInputs.prmuyeliktipikey,
        "6"
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "SignupAdminChange/SignupAdminChangeComponent_387716_onClick8_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveUser = result?.data.saveUser;
      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick9_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick9_();
          resolve();
        });
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupAdminChangeComponent_387716_onClick9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmUyelikTipiKey ?? this.props.screenInputs.prmuyeliktipikey,
        "1"
      )
    ) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        "YAYINCI",
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick10_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick10_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupAdminChangeComponent_387716_onClick10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmUyelikTipiKey ?? this.props.screenInputs.prmuyeliktipikey,
        "2"
      )
    ) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        "MATBAA",
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick11_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick11_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupAdminChangeComponent_387716_onClick11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmUyelikTipiKey ?? this.props.screenInputs.prmuyeliktipikey,
        "3"
      )
    ) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        "YAYINCITEDARIKCITICARI",
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick12_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick12_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupAdminChangeComponent_387716_onClick12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmUyelikTipiKey ?? this.props.screenInputs.prmuyeliktipikey,
        "7"
      )
    ) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        "YAYINCITEDARIKCISAHIS",
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick13_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick13_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupAdminChangeComponent_387716_onClick13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmUyelikTipiKey ?? this.props.screenInputs.prmuyeliktipikey,
        "4"
      )
    ) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        "YAZAR",
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick14_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick14_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupAdminChangeComponent_387716_onClick14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmUyelikTipiKey ?? this.props.screenInputs.prmuyeliktipikey,
        "5"
      )
    ) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        "MUSTERITICARI",
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick15_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick15_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupAdminChangeComponent_387716_onClick15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    if (
      ReactSystemFunctions.isEqualTo(
        this.props.screenInputs.prmUyelikTipiKey ?? this.props.screenInputs.prmuyeliktipikey,
        "6"
      )
    ) {
      stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
        "MUSTERISAHIS",
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        )
      );

      this.props.form.setFieldsValue(formVars);

      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick16_();
          resolve();
        });
      });
    } else {
      await new Promise((resolve, reject) => {
        this.setState(stateVars, async () => {
          isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick16_();
          resolve();
        });
      });
    }

    return isErrorOccurred;
  };
  SignupAdminChangeComponent_387716_onClick16_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_488466_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.startsWith(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "0"
      ),
      message: "Telefon numarası 0 ile başlamalı!",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isEqualLength(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "11"
      ),
      message: "Telefon numarası 11 rakam olmalı!",
      formName: "signupadminchange_432007_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      GSM_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_432007_value", "value", "", "", "")
        ),
        "string"
      ),
      KUserId_0: ReactSystemFunctions.convertToTypeByName(null, "Guid"),
      KVKKOnay_0: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      KVKKOnayTarihi_0: ReactSystemFunctions.convertToTypeByName(moment().format("YYYY-MM-DDTHH:mm:ss"), "Date"),
      UserName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        "string"
      ),
      UyelikTipiKey_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmUyelikTipiKey ?? this.props.screenInputs.prmuyeliktipikey,
        "number"
      ),
      Id_0: ReactSystemFunctions.convertToTypeByName(Guid.create().toString(), "Guid"),
      UserName_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        "string"
      ),
      UserName_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        "string"
      ),
      UyeId_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.prmUyeId ?? this.props.screenInputs.prmuyeid,
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "SignupAdminChange/SignupAdminChangeComponent_387716_onClick16_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.KullaniciDetaySave = result?.data.kullaniciDetaySave;
    stateVars.getKUserIdbyUserName = result?.data.getKUserIdbyUserName;
    stateVars.KullaniciDetayUserIdUyeIdUpdate = result?.data.kullaniciDetayUserIdUyeIdUpdate;
    this.props.form.setFieldsValue(formVars);

    await new Promise((resolve, reject) => {
      this.setState(stateVars, async () => {
        isErrorOccurred = await this.SignupAdminChangeComponent_387716_onClick17_();
        resolve();
      });
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  SignupAdminChangeComponent_387716_onClick17_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "*",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signupadminchange_120930_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) {
      const invalidValidation = validations.find((v) => !v.isValid && v.condition);
      if (invalidValidation) {
        const fieldIdMatch = invalidValidation.formName.match(/\d+/);
        if (fieldIdMatch) {
          const fieldId = fieldIdMatch[0];
          const fieldElement = document.getElementById(fieldId);
          if (fieldElement) {
            fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      return true;
    }

    stateVars.WriteToSessionStorage = await ReactSystemFunctions.writeToSessionStorage(
      "username",
      ReactSystemFunctions.value(
        this,
        ReactSystemFunctions.value(this, "signupadminchange_120930_value", "value", "", "", "")
      )
    );
    stateVars.WriteToSessionStorage = await ReactSystemFunctions.writeToSessionStorage(
      "UID",
      this.props.screenInputs.prmUyeId ?? this.props.screenInputs.prmuyeid
    );
    stateVars.WriteToSessionStorage = await ReactSystemFunctions.writeToSessionStorage(
      "KUID",
      ReactSystemFunctions.toString(
        this,
        stateVars.getKUserIdbyUserName?.length > 0
          ? stateVars.getKUserIdbyUserName[0].id
          : this.state.getKUserIdbyUserName?.length > 0
          ? this.state.getKUserIdbyUserName[0].id
          : null
      )
    );
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToNextScreenAfterSignup(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [979982, 806891] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.SignupAdminChangePageInit();
    }
  }
}
